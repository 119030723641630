import React from 'react';
import GloriousAppBar from './components/Misc/GloriousAppBar';
import OrderVerification from './components/Orders/OrderVerification';
import { Switch, Route } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Login from './components/Misc/Login';
import Users from "./components/Users/Users.jsx";
import SKUReport from './components/ETD/SKUReport';
import Inventory from "./components/Inventory/Inventory";
import Distributors from "./components/Distributors/Distributors";
import GloriousSnackBar from "./components/Misc/GloriousSnackBar";
import Home from "./components/Misc/Home";
import B2BAdminHome from "./components/B2B/B2BAdminHome";
import B2BCustomersView from "./components/B2B/B2BCustomersView";
import B2BOrdersView from "./components/B2B/B2BOrdersView";
import B2BProductsView from "./components/B2B/B2BProductsView";
import UserProfile from "./components/Users/UserProfile";
import SerialNumberPage from "./components/SerialNumber/SerialNumberPage";
import ProductLaunchHome from "./components/ProductLaunch/ProductLaunchHome";
import ProductDataHome from "./components/OrderManifest/ProductDataHome";
import ProductRegistrationsPage from "./components/ProductRegistrations/ProductRegistrationsPage";
import DeleteCustomerDataPage from "./components/DeleteCustomerData/DeleteCustomerDataPage";
import SurveysPage from "./components/Surveys/SurveysPage";
import SoftwareDownloadsPage from './components/SoftwareDownloads/SoftwareDownloadsPage';
import LoyaltyPages from './components/LoyaltyPages/LoyaltyPages';

const App = () => (
    <main>
        <GloriousAppBar />
        <Container maxWidth="xl" >
            <Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/orders' component={OrderVerification} />
                <Route exact path='/inventory' component={Inventory} />
                <Route exact path='/distributors' component={Distributors} />
                <Route exact path='/etd' component={SKUReport} />
                <Route exact path='/serialnumber' component={SerialNumberPage} />
                <Route exact path='/productlaunch' component={ProductLaunchHome} />
                <Route exact path='/productdata' component={ProductDataHome} />
                <Route exact path='/users' component={Users} />
                <Route exact path='/users/profile' component={UserProfile} />
                <Route exact path='/b2b' component={B2BAdminHome} />
                <Route exact path='/b2b/orders/:id' component={B2BOrdersView} />
                <Route exact path='/b2b/customers' component={B2BCustomersView} />
                <Route exact path='/b2b/products' component={B2BProductsView} />
                <Route exact path='/product-registrations' component={ProductRegistrationsPage} />
                <Route exact path='/delete-customer-data' component={DeleteCustomerDataPage} />
                <Route exact path='/surveys' component={SurveysPage} />
                <Route exact path='/software-downloads' component={SoftwareDownloadsPage} />
                <Route exact path='/loyalty' component={LoyaltyPages} />
            </Switch>
        </Container>
        <GloriousSnackBar />
    </main>
);

export default App;
