import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import ShippingIcon from "@material-ui/icons/LocalShipping";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import BusinessIcon from '@material-ui/icons/CardTravel';
import GlobeIcon from '@material-ui/icons/Public'
import SKUReportIcon from '@material-ui/icons/Assignment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import UploadIcon from '@material-ui/icons/CloudUpload';
import CakeIcon from '@material-ui/icons/Cake';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

class UserCard extends Component {

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array
    };

    render() {
        const {roles, userProfile} = this.props;
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        return (
            <div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/orders'>
                                    <IconButton color="secondary"  aria-label="Order Details Page">
                                        <ReceiptIcon />
                                    </IconButton>
                                    <Typography>Order Details Search</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/inventory'>
                                    <IconButton color="secondary"  aria-label="Inventory Status">
                                        <ShippingIcon />
                                    </IconButton>
                                    <Typography>Inventory Status View</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            { (roles.includes("admin")
                            || roles.includes("cs_admin")
                            || roles.includes("logistics_admin")) &&
                                <Paper className={'homeButtonPanel'}>
                                    <Link to='/users'>
                                        <IconButton color="secondary"  aria-label="user page">
                                            <PersonIcon />
                                        </IconButton>
                                        <Typography>User Accounts</Typography>
                                    </Link>
                                </Paper> }
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            {(roles.includes("admin")
                                || roles.includes("b2b_admin")
                                || roles.includes("logistics_admin")) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/etd'>
                                    <IconButton color="secondary"  aria-label="ETD & SKU Reports">
                                        <SKUReportIcon />
                                    </IconButton>
                                    <Typography>ETD & SKU Reports</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                        <Grid item xs={4}>
                            {(roles.includes("admin")
                            || roles.includes("cs_admin")
                            || roles.includes("logistics_admin")
                            || roles.includes("b2b_admin")
                            || roles.includes("tier1")) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/distributors'>
                                    <IconButton color="secondary"  aria-label="B2B Distributors">
                                        <GlobeIcon />
                                    </IconButton>
                                    <Typography>B2B Distributors</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                        <Grid item xs={4}>
                            {(roles.includes("admin")
                                || roles.includes("logistics_admin")
                                || roles.includes("b2b_admin")) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/b2b'>
                                    <IconButton color="secondary"  aria-label="B2B Admin">
                                        <BusinessIcon />
                                    </IconButton>
                                    <Typography>B2B Admin</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/serialnumber'>
                                    <IconButton color="secondary"  aria-label="serial number page">
                                        <LocalOfferIcon />
                                    </IconButton>
                                    <Typography>Serial Number Reference</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/productdata'>
                                    <IconButton color="secondary"  aria-label="product data tool">
                                        <FlightTakeoffIcon />
                                    </IconButton>
                                    <Typography>Product Data Tool</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/users/profile'>
                                    <IconButton color="secondary"  aria-label="user page">
                                        <PersonIcon />
                                    </IconButton>
                                    <Typography>Your User Profile</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        {(roles.includes("admin")
                            || roles.includes("cs_admin")
                            || roles.includes("tier1")
                            || roles.includes("tier3")
                            ) &&

                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/product-registrations'>
                                    <IconButton color="secondary"  aria-label="product registrations page">
                                        <PersonIcon />
                                    </IconButton>
                                    <Typography>Product Registrations</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        }
                        {(roles.includes("admin")
                            || roles.includes("cs_admin")
                            || roles.includes("tier1")
                            || roles.includes("tier3")
                            ) &&

                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/surveys'>
                                    <IconButton color="secondary"  aria-label="surveys page">
                                        <PersonIcon />
                                    </IconButton>
                                    <Typography>Surveys</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        }

                        {(roles.includes("admin")
                            || roles.includes("cs_admin")
                            || roles.includes("tier1")
                            || roles.includes("tier3")
                            ) &&

                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/delete-customer-data'>
                                    <IconButton color="secondary"  aria-label="delete-customer-data page">
                                        <PersonIcon />
                                    </IconButton>
                                    <Typography>Delete Customer Data</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        }

                        {(roles.includes("admin")) &&

                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/software-downloads'>
                                    <IconButton color="secondary"  aria-label="software-downloads page">
                                        <UploadIcon />
                                    </IconButton>
                                    <Typography>Software downloads</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        }


                        {(roles.includes("admin")) &&

                        <Grid item xs={4}>
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/loyalty'>
                                    <IconButton color="secondary"  aria-label="loyalty-points page">
                                        <CakeIcon />
                                    </IconButton>
                                    <Typography>Loyalty</Typography>
                                </Link>
                            </Paper>
                        </Grid>
                        }
                    </Grid>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
    })
)(UserCard);
