import {
    DELETE_CUSTOMER_LOYALTY_DATA_LOADING,
    DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS,
    DELETE_CUSTOMER_LOYALTY_DATA_COMPLETE,
    FAILED_DELETE_CUSTOMER_LOYALTY_DATA,
    DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING,
    DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS,
    DELETE_CUSTOMER_REGISTORIOUS_DATA_COMPLETE,
    FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA,
    DELETE_CUSTOMER_SHOPIFY_DATA_LOADING,
    DELETE_CUSTOMER_SHOPIFY_DATA_SUCCESS,
    DELETE_CUSTOMER_SHOPIFY_DATA_COMPLETE,
    FAILED_DELETE_CUSTOMER_SHOPIFY_DATA
} from "../actions/deleteCustomerDataActions";


const initialState = {
    
};

export default function deleteCustomerDataReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_CUSTOMER_LOYALTY_DATA_LOADING:
            return {
                ...state,
                deletingLoyaltyData: true,                
            };
        case DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS:
            return {
                ...state,
                deletingLoyaltyData: false,
                deletingLoyaltyDataSuccess: true
            };

        case FAILED_DELETE_CUSTOMER_LOYALTY_DATA:
            return {
                ...state,
                deletingLoyaltyData: false,
                deletingLoyaltyDataSuccess: false
            }


        case DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING:
            return {
                ...state,
                deletingRegistoriousData: true,
            };
        case DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS:
            return {
                ...state,
                deletingRegistoriousData: false,
                deletingRegistoriousDataSuccess: true
            };

        case FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA:
            return {
                ...state,
                deletingRegistoriousData: false,
                deletingRegistoriousDataSuccess: false
            }


        case DELETE_CUSTOMER_SHOPIFY_DATA_LOADING:
            return {
                ...state,
                deletingShopifyData: true,
            };
        case DELETE_CUSTOMER_SHOPIFY_DATA_SUCCESS:
            return {
                ...state,
                deletingShopifyData: false,
                deletingShopifyDataSuccess: true
            };

        case FAILED_DELETE_CUSTOMER_SHOPIFY_DATA:
            return {
                ...state,
                deletingShopifyData: false,
                deletingShopifyDataSuccess: false
            }


        default:
            return state
    }
}