import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRSLOrder,
    getShopifyOrder,
    //getCINOrder,
    getTrackingInformation,
    cancelOrderFromRSL,
    cancelOrderFromShopify,
    updateOrderNumberText,
    orderRefundFromShopify,
    getCustomerSearch,
    getCustomerOrders,
    cancelOrderFromAllSystems
} from '../../redux/actions/verificationActions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DataCard from "./DataCard";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import HeaderCard from "./HeaderCard";
import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import {CircularProgress} from "@material-ui/core";
import moment from "moment";

class OrderVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelDialog: false,
            refundDialog: false,
            customerDialog: false,
            showList: true,
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        cancelOrderFromRSL: PropTypes.func,
        cancelOrderFromShopify: PropTypes.func,
        //cinOrder: PropTypes.object,
        customersOrders: PropTypes.array,
        customerSearchResults: PropTypes.array,
        orderNumberTextField: PropTypes.string,
        rslOrder: PropTypes.object,
        roles: PropTypes.string,
        shopifyOrder: PropTypes.object,
        trackingData: PropTypes.array,
        //getCINOrder: PropTypes.func,
        getCustomerSearch: PropTypes.func,
        getRSLOrder: PropTypes.func,
        getShopifyOrder: PropTypes.func,
        getTrackingInformation: PropTypes.func,
        getCustomerOrders: PropTypes.func,
        updateOrderNumberText: PropTypes.func,
        orderRefundFromShopify: PropTypes.func,
        updateOrderDetails: PropTypes.bool
    };

    static defaultProps = {
        shopifyOrder: {},
        rslOrder:[],
        trackingData: {}
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateOrderDetails !== this.props.updateOrderDetails && this.props.updateOrderDetails) {
            this.grabOrderData();
        }
    };

    updateOrderNumberText = (event) => {
        this.props.updateOrderNumberText(event.target.value);
    };

    updateCustomerSearchQuery = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    grabOrderData = () => {
        if(this.props.orderNumberTextField !== '' && this.props.orderNumberTextField !== undefined) {
            this.props.getShopifyOrder(this.props.orderNumberTextField);
            this.props.getRSLOrder(this.props.orderNumberTextField);
            //this.props.getCINOrder(this.props.orderNumberTextField);
            this.props.getTrackingInformation(this.props.orderNumberTextField);
        }
    };

    grabOrderDataFromCustomer = (orderID) => {
        this.props.getShopifyOrder(orderID);
        this.props.getRSLOrder(orderID);
        //this.props.getCINOrder(orderID);
        this.props.getTrackingInformation(orderID);
    };

    getActiveStep = () => {
        let activeStep = 0;
        const {shopifyOrder,  rslOrder} = this.props;
        if(shopifyOrder.orderID !== null && shopifyOrder.orderID !== undefined && shopifyOrder.orderID !== '' && shopifyOrder.orderID !== 'Not Found') {
            activeStep = 1;
        }
        // if(cinOrder.orderID !== null && cinOrder.orderID !== undefined && cinOrder.orderID !== '' && cinOrder.orderID !== 'Not Found') {
        //     activeStep = 2;
        // }
        if(rslOrder[0] !== undefined && rslOrder[0].orderID !== null && rslOrder[0].orderID !== undefined && rslOrder[0].orderID !== '' && rslOrder[0].orderID !== 'Not Found') {
            activeStep = 2;
        }
        return activeStep;
    };

    renderTrackingInfo = (rslOrder) => {

        const { trackingData } = this.props;
        function getSkus(shippmentInfo){
            var rslShip = rslOrder.find(a=>a.orderID==shippmentInfo.orderID);
            if (rslShip){
                var lineItems = rslShip.line_items;
                return (
                    <ul>
                 {lineItems.map(o=>{
                    return (
                        <li>{o}</li>
                    )
                })}
                </ul>
                )
                ;
            }
            return null;
        }
        return (
            <div>
                <div className={'card-title'}>
                    <Typography variant='h6'>Shipment Details</Typography>
                </div>
                <div>
                    {trackingData.length !== 0 && trackingData.length !== undefined && trackingData.map(shipmentInfo => {
                        return(
                            <div>
                                <Typography><span className={'headerCardSubFont'}>RSL Order ID</span> {shipmentInfo.orderID}</Typography>
                                <Typography><span className={'headerCardSubFont'}>SKUs</span> {getSkus(shipmentInfo)}</Typography>
                                <Typography><span className={'headerCardSubFont'}>Tracking</span><a className={'trackingLink'} target='_blank' href={'https://t.17track.net/en#nums='+ shipmentInfo.trackingNum}> {shipmentInfo.trackingNum}</a></Typography>
                                <Typography><span className={'headerCardSubFont'}>Method</span> {shipmentInfo.company}</Typography>
                                <Typography><span className={'headerCardSubFont'}>Shipping Date</span> {moment(shipmentInfo.date).format('MMMM Do YYYY, h:mm a')}</Typography>
                                <br />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };

    // cancelOrder = () => {
    //     const {shopifyOrder, rslOrder} = this.props;
    //     if(rslOrder.orderID !== null && rslOrder.orderID !== undefined && rslOrder.orderID !== 'Not Found') {
    //         this.props.cancelOrderFromRSL(rslOrder.orderID);
    //     }
    //     if(shopifyOrder.orderID !== null && shopifyOrder.orderID !== undefined && shopifyOrder.orderID !== 'Not Found') {
    //         this.props.cancelOrderFromShopify(shopifyOrder.orderID);
    //     }
    //     this.handleDialogAction();
    // };

    cancelOrderFromAllSystems = () => {
        const {shopifyOrder, rslOrder} = this.props;
        let rslOrderID = undefined;
        if(rslOrder[0] !== undefined && rslOrder[0].orderID !== undefined) {
            rslOrderID = rslOrder[0].orderID;
        }
        this.props.cancelOrderFromAllSystems(rslOrderID, shopifyOrder.orderID);
        this.handleDialogAction();
    };

    renderCancelButton = (rslOrder) => {
        let disabled = false;
        let anyItems = rslOrder && rslOrder.length>0 && rslOrder.find(s=>s.line_items && s.line_items.length>0);
        if (anyItems){
            disabled = true;
        }
        return (
            <div className={'actionStyle'}>
                <Button variant="contained" disabled={disabled} color="secondary" onClick={this.handleDialogAction}>Cancel</Button>
                {disabled &&
                <span> Already in RSL</span>
                }
            </div>
        );
    };

    handleDialogAction = () => {
        this.setState({
            cancelDialog: !this.state.cancelDialog
        })
    };

    handleRefundDialogAction = () => {
        this.setState({
            refundDialog: !this.state.refundDialog
        })
    };

    renderCancelDialog = (rslOrder) => {
        return (
            <div>
                <Dialog
                    open={this.state.cancelDialog}
                    onClose={this.handleDialogAction}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this order?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This order will cancel in Shopify, CIN7, Rakuten if Applicable. This will also refund the order to its full amount.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogAction} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.cancelOrderFromAllSystems} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    handleRefundAmountChange = (event) => {
        this.setState({
            refundAmount: event.target.value
        })
    };

    refundOrder = () => {
        this.props.orderRefundFromShopify(this.props.shopifyOrder.orderID, this.state.refundAmount);
        this.handleRefundDialogAction();
    };

    getTotalRefundable = (order) => {
        let totalAmountRefunded = 0;
        if(order !== undefined && order.refunds !== undefined && order.refunds.length !== 0) {
            order.refunds.forEach(refund => {
                refund.order_adjustments.forEach(orderAdj => {
                    totalAmountRefunded += parseFloat(orderAdj.amount);
                })
            })
        }
        let amountRefundable = (parseFloat(order.total_price) + totalAmountRefunded).toFixed(2);
        return amountRefundable;
    };

    renderRefundDialog = () => {
        const {shopifyOrder} = this.props;

        return (
            <div>
                <Dialog
                    open={this.state.refundDialog}
                    onClose={this.handleRefundDialogAction}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Refund Order"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className={'refundAmount'}>Total Amount Refundable: {this.getTotalRefundable(shopifyOrder)} </div>
                            <div>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={60}
                                        onChange={this.handleRefundAmountChange}
                                    />
                                </FormControl>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRefundDialogAction} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.refundOrder} color="primary" autoFocus>
                            Refund
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    grabCustomerSearchQuery = () => {
        // call customer search query
        this.props.getCustomerSearch(this.state.query);
        this.handleCustomerSearchDialog();
    };

    getCustomerOrders = (customerID) => {
        this.props.getCustomerOrders(customerID);
        this.handleShowList();
    };

    grabSelectedOrder = (orderNumber) => {
        this.grabOrderDataFromCustomer(orderNumber);
        this.handleCustomerSearchDialog();
    };

    handleCustomerSearchDialog = () => {
      this.setState({
          customerDialog: !this.state.customerDialog,
          showList: true
      })
    };

    handleShowList = () => {
        this.setState({
            showList: false,
        });
    };

    renderCustomerList = () => {
        const {loadingCustomerSearch, customerSearchResults, customersOrders } = this.props;
        if(this.state.showList) {
            return (
                <div data-tag="1">
                {loadingCustomerSearch &&  <CircularProgress color="secondary"  />}
                    <List>
                    {customerSearchResults.length !== 0 && customerSearchResults.length !== undefined && customerSearchResults.map(customerData => {
                            let customerName = customerData.first_name + ' ' + customerData.last_name;
                            return(
                                <ListItem button onClick={() => this.getCustomerOrders(customerData.id)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={customerName} secondary={customerData.email} />
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            );
        } else {
            return (
                <div data-tag="2">
                    {loadingCustomerSearch && <CircularProgress color="secondary" />}
                    <List>
                        {customersOrders && customersOrders.length !== 0 && customersOrders.length !== undefined && customersOrders.map(order => {
                            return(
                                <ListItem button onClick={() => this.grabSelectedOrder(order.order_number)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ReceiptIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={'Order Number: #'+order.order_number} secondary={order.created_at} />
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            )
        }
    };

    renderCustomerSearchDialog = () => {
        return (
            <div>
                <Dialog
                    open={this.state.customerDialog}
                    onClose={this.handleCustomerSearchDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title">{this.state.showList ? 'Customers' : 'Orders'}</DialogTitle>
                    <DialogContent className={'customerDialog'}>
                        {this.renderCustomerList()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCustomerSearchDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    render() {
        const {shopifyOrder, rslOrder} = this.props;
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }

        const steps = ['Shopify', 'RSL'];
        let labelProps = {};
        labelProps.optional = <Typography variant="caption">Optional</Typography>;
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Order Details</Typography>
                </div>
                <div className={'searchDetailsCard'}>
                    <div className={'searchButtonBlock'}>
                        <Typography className={'searchTitle'}></Typography><TextField autoFocus id="outlined-basic" label="Order Number" onChange={this.updateOrderNumberText} />
                        <Button className={'searchOrderButton'} variant="contained" onClick={this.grabOrderData} color="primary">Search</Button>
                    </div>
                    <div className={'searchButtonBlock'}>
                        <Typography className={'searchTitle'}></Typography><TextField id="outlined-basic" label="Customer Search" onChange={this.updateCustomerSearchQuery} />
                        <Button className={'searchOrderButton'} variant="contained" onClick={this.grabCustomerSearchQuery} color="primary">Search</Button>
                    </div>
                </div>
                <Stepper className={'stepperStyle'} color='primary' activeStep={this.getActiveStep()} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper className={'orderPanel'}>
                            <div className={'card-title'}>
                                <Typography variant='h6'>Order Status & Portal Links</Typography>
                            </div>
                            <HeaderCard title='SHOPIFY' data={shopifyOrder} />
                            {/* <HeaderCard title='CIN7' data={cinOrder} /> */}
                            {rslOrder.map(rslOrderEntry => {
                                return <HeaderCard title='RSL' data={rslOrderEntry} />;
                            })}
                            {this.renderTrackingInfo(rslOrder)}
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={'orderPanel'}>
                            {/*<div className={'actionStyle'}><Button disabled={true} variant="contained" color="primary">Edit</Button> </div>*/}
                            <div className={'actionStyle'}><Button disabled={false} onClick={this.handleRefundDialogAction} variant="contained" color="primary" >Refund</Button> </div>
                            {this.renderCancelButton(rslOrder)}
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderCancelDialog()}
                {this.renderRefundDialog()}
                {this.renderCustomerSearchDialog()}
                <DataCard title='Order Details' data={shopifyOrder} />
            </div>
        );
    }
}

export default connect(
    state => ({
        shopifyOrder: state.verificationReducer.shopifyOrder,
        //cinOrder: state.verificationReducer.cinOrder,
        rslOrder: state.verificationReducer.rslOrder,
        authorized: state.userReducer.authorized,
        trackingData: state.verificationReducer.trackingData,
        orderNumberTextField: state.verificationReducer.orderNumberTextField,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        updateOrderDetails: state.verificationReducer.updateOrderDetails,
        customerSearchResults: state.verificationReducer.customerSearchResults,
        customersOrders: state.verificationReducer.customersOrders,
        loadingCustomerSearch: state.verificationReducer.loadingCustomerSearch
    }),
    dispatch => ({
        getShopifyOrder: (orderNumber) => dispatch(getShopifyOrder(orderNumber)),
        getRSLOrder: (orderNumber) => dispatch(getRSLOrder(orderNumber)),
        //getCINOrder: (orderNumber) => dispatch(getCINOrder(orderNumber)),
        getTrackingInformation: (orderNumber) => dispatch(getTrackingInformation(orderNumber)),
        cancelOrderFromRSL: (orderID) => dispatch(cancelOrderFromRSL(orderID)),
        cancelOrderFromShopify: (orderID) => dispatch(cancelOrderFromShopify(orderID)),
        updateOrderNumberText: (orderNumber) => dispatch(updateOrderNumberText(orderNumber)),
        orderRefundFromShopify: (orderID, refundAmount) => dispatch(orderRefundFromShopify(orderID, refundAmount)),
        getCustomerSearch: (query) => dispatch(getCustomerSearch(query)),
        getCustomerOrders: (customerID) => dispatch(getCustomerOrders(customerID)),
        cancelOrderFromAllSystems: (rslOrderID, shopifyOrderID) => dispatch(cancelOrderFromAllSystems(rslOrderID, shopifyOrderID))
    })
)(OrderVerification);
