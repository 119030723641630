import React, {Component} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import moment from 'moment';
import moment_tz from 'moment-timezone';

class UpcomingProductLaunchesView extends Component {


    renderUpcomingReleases = () => {
        return this.props.productLaunchData.map(entry => {
            let now = moment();
            let globalReleaseDate = moment(entry.globalReleaseDate);
            if(14 >= globalReleaseDate.diff(now, 'days')) {
                return <Typography>{moment_tz.tz(entry.globalReleaseDate, 'America/Chicago').format('MMMM Do, YYYY')} --- {entry.sku}</Typography>
            }
        })
    };


    render() {
        return(
            <div>
                <Card className={'upcomingReleaseCard'}>
                    <div className={'title-releases'}>
                        <Typography variant='h6'>Upcoming Releases</Typography>
                    </div>
                    {this.renderUpcomingReleases()}
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        productLaunchData: state.productLaunchReducer.productLaunchData
    }),
    dispatch => ({

    })
)(UpcomingProductLaunchesView);