import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 10,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: 500,
        marginBottom:10,
    },
}));

export default function layout(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={10}>
                    <Paper className={classes.paper}>{props.children}</Paper>
                </Grid>
            </Grid>
        </div>
    );
}