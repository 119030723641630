
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './redux/store';
import App from './App';
import './styles/app.css';

ReactDOM.render(
    <Provider store={configureStore()}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>,
    document.querySelector("#root")
);