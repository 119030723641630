import React, {Component} from "react";
import PropTypes from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";



import {deleteLoyaltyCustomerData,deleteRegistoriousCustomerData,deleteShopifyCustomerData, deleteRegistoriousCustomerDataBulk,deleteLoyaltyCustomerDataBulk} from '../../redux/actions/deleteCustomerDataActions';

const host = 'https://registorious.gloriousgamingservices.com';
//const host = 'https://68c039285619.ngrok.io';

class DeleteCustomerDataPage extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
            customerEmail:null,
            workStarted:false
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array,        
        userProfile: PropTypes.object,        
        deletingLoyaltyData: PropTypes.bool,
        deletingRegistoriousData: PropTypes.bool,
        deletingShopifyData: PropTypes.bool,
        deletingLoyaltyDataSuccess: PropTypes.bool,
        deletingRegistoriousDataSuccess: PropTypes.bool,
        deletingShopifyDataSuccess: PropTypes.bool,
    };
    
    updateCustomerEmail = (event) => {
        var lines = event.target.value.replace(/\r\n/g,"\n").split("\n");
        var cleanedUp = lines.filter((el)=>{
            return el!=undefined && el!=null && el!='' && el.trim()!='';
        });
        this.setState({
            customerEmail: cleanedUp
        })
    };

    deleteCustomerData = (event) => {     
        let text;
        
        if (this.state.customerEmail.length>1000){
            alert('You cannot delete more than 1000 customers at once. Delete canceled.');
            return;
        }
        if (this.state.customerEmail && this.state.customerEmail!=null && this.state.customerEmail!=''){
            if (confirm("Are you sure you want to delete all the Registorious and Loyalty data associated with the provided email address(es)?") == true) {
                console.log('start to delete customer data');
                this.state.workStarted = true;

                this.props.deleteRegistoriousCustomerDataBulk(this.state.customerEmail,this.props.userProfile);
                this.props.deleteLoyaltyCustomerDataBulk(this.state.customerEmail);
                //this.props.deleteShopifyCustomerData(this.state.customerEmail);

            } else {
                console.log('delete customer data canceled');
            }
        }
    };

    componentDidMount() {
        
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');            
        }
        
        if (this.props.roles==undefined || 
            (!this.props.roles.includes('admin') &&
            !this.props.roles.includes('cs_admin') &&
            !this.props.roles.includes('tier3') &&
            !this.props.roles.includes('tier1'))
        ) {
            this.props.history.push('/');
        }

        var workInProgress = this.props.deletingLoyaltyData || this.props.deletingRegistoriousData; //|| this.props.deletingShopifyData
        var workDone = this.state.workStarted && !workInProgress;        

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Delete Customer Data</Typography>
                </div>
                {!workInProgress &&
                <Card className="deleteCustomerCard">
                    <p>
                        Please type the email addresses for the customers you want to delete (one per line).   <br></br>                                             
                        Eg: <br></br>                                             
                        customer1@customerdomain.com <br></br>                                             
                        customer2@customerdomain.com <br></br>                                                                     
                        ... <br></br>                                             
                        customer1000@customerdomain.com <br></br>
                    </p>
                    <Typography className={'searchTitle'}></Typography>
                        <TextareaAutosize id="outlined-basic" label="customerEmail" onChange={this.updateCustomerEmail}
                        maxRows={1000}                        
                        style={{width:400,height:400}}
                        />
                        <br></br>
                    <Button className={'searchOrderButton'} variant="contained" onClick={this.deleteCustomerData} color="secondary">Delete all customer data</Button>
                </Card>                
                }
                
                {workInProgress &&
                    <Card className="deleteCustomerCard">
                        <Typography className={'searchTitle'}>deleting in progress</Typography>

                        {/* {this.props.deletingShopifyData &&
                            <Typography className={'searchTitle'}>deleting shopify data in progress</Typography>
                        }
                        {!this.props.deletingShopifyData &&
                            <Typography className={'searchTitle'}>deleting shopify data - done</Typography>
                        } */}
                        {this.props.deletingLoyaltyData &&
                            <Typography className={'searchTitle'}>deleting loyalty data in progress</Typography>
                        }
                        {!this.props.deletingLoyaltyData &&
                            <Typography className={'searchTitle'}>deleting loyalty data - done</Typography>
                        }
                        {this.props.deletingRegistoriousData &&
                            <Typography className={'searchTitle'}>deleting registorious data in progress</Typography>
                        }
                        {!this.props.deletingRegistoriousData &&
                            <Typography className={'searchTitle'}>deleting registorious data - done</Typography>
                        }
                </Card>                
                }
                 {!workInProgress && this.state.workStarted &&
                    <Card className="deleteCustomerCard">
                        <Typography className={'searchTitle'}>deleting - done</Typography>
                        {/* {!this.props.deletingShopifyData &&
                            <Typography className={'searchTitle'}>deleting shopify data - done</Typography>
                        }                         */}
                        {!this.props.deletingLoyaltyData &&
                            <Typography className={'searchTitle'}>deleting loyalty data - done</Typography>
                        }                        
                        {!this.props.deletingRegistoriousData &&
                            <Typography className={'searchTitle'}>deleting registorious data - done</Typography>
                        }
                </Card>                
                }
             
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,        
        userProfile: state.userReducer.userProfile,            
        deletingLoyaltyData: state.deleteCustomerDataReducer.deletingLoyaltyData,                                   
        deletingRegistoriousData: state.deleteCustomerDataReducer.deletingRegistoriousData,                         
        deletingShopifyData: state.deleteCustomerDataReducer.deletingShopifyData,                                   
        deletingLoyaltyDataSuccess: state.deleteCustomerDataReducer.deletingLoyaltyDataSuccess,                     
        deletingRegistoriousDataSuccess: state.deleteCustomerDataReducer.deletingRegistoriousDataSuccess,           
        deletingShopifyDataSuccess: state.deleteCustomerDataReducer.deletingShopifyDataSuccess
        }),        
    dispatch => ({
         deleteLoyaltyCustomerData: (email) => dispatch(deleteLoyaltyCustomerData(email)),
         deleteRegistoriousCustomerData: (email,userProfile) => dispatch(deleteRegistoriousCustomerData(email,userProfile)),
         deleteShopifyCustomerData: (email) => dispatch(deleteShopifyCustomerData(email)),
         deleteLoyaltyCustomerDataBulk: (emails) => dispatch(deleteLoyaltyCustomerDataBulk(emails)),
         deleteRegistoriousCustomerDataBulk: (emails,userProfile) => dispatch(deleteRegistoriousCustomerDataBulk(emails,userProfile)),
    })
)(DeleteCustomerDataPage);