import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {addNewEventToSN} from '../../redux/actions/serialNumberActions';
import 'date-fns';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class SNEventDialog extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         eventDate: '',
    //         eventInfo: '',
    //         eventType: 'RMA',
    //         freshDeskTicketNum: ''
    //     };
    //     this.handleChange = this.handleChange.bind(this);
    // }

    static propTypes = {
        create: PropTypes.bool,
        open: PropTypes.bool,
        serialNumberData: PropTypes.string,
        toggleEventDialog: PropTypes.func,
        eventDate: PropTypes.string,
        eventInfo: PropTypes.string,
        eventType: PropTypes.string,
        freshDeskTicketNum: PropTypes.string,
    };

    static defaultProps = {
        create: true,
        open: false,
    };

    // handleChange(event) {
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    // submitNewEventForSN = () => {
    //     const {eventDate, eventInfo, eventType, freshDeskTicketNum} = this.state;
    //     const {serialNumberData} = this.props;
    //     this.props.addNewEventToSN(serialNumberData._id, eventInfo, eventDate, eventType, freshDeskTicketNum);
    //     this.props.toggleEventDialog();
    // };

    renderUserCreateDialog = () => {
        // const {eventInfo, eventDate, eventType, freshDeskTicketNum} = this.state;
        const {create, open, serialNumberData, eventInfo, eventDate, eventType, freshDeskTicketNum} = this.props;

        return(
            <form>
                <Dialog open={open} onClose={this.props.toggleEventDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{create ? 'Create' : 'Update'} a new event for {serialNumberData.serialNumber}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill in the date and event information and click submit to add it the S/N events list.
                        </DialogContentText>
                        <TextField
                            id="eventDate"
                            label="Event Date"
                            name="eventDate"
                            type="date"
                            value={eventDate}
                            onChange={this.props.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl className={'formControlSelectSNType'}>
                            <InputLabel>Event Type</InputLabel>
                            <Select
                                id="eventType"
                                name="eventType"
                                value={eventType}
                                onChange={this.props.handleChange}
                            >
                                <MenuItem value={'RMA'}>RMA</MenuItem>
                                <MenuItem value={'Refund'}>Refund</MenuItem>
                                <MenuItem value={'Partial Refund'}>Partial Refund</MenuItem>
                                <MenuItem value={'Store Credit'}>Store Credit</MenuItem>
                                <MenuItem value={'Partial Store Credit'}>Partial Store Credit</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="freshDeskTicketNum"
                            name="freshDeskTicketNum"
                            label="FreshDesk Ticker Number"
                            type="freshDeskTicketNum"
                            fullWidth
                            value={freshDeskTicketNum}
                            onChange={this.props.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="eventInfo"
                            name="eventInfo"
                            label="Event Information"
                            type="eventInfo"
                            fullWidth
                            value={eventInfo}
                            onChange={this.props.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.toggleEventDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.props.handleSubmit} color="primary">
                            {create ? 'Create' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        )
    };

    render() {
        return(
            <div>
                {this.renderUserCreateDialog()}
            </div>
        )
    }
}

export default connect(
    state => ({
        serialNumberData: state.serialNumberReducer.serialNumberData,
    }),
    dispatch => ({
        addNewEventToSN: (serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum) => dispatch(addNewEventToSN(serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum))
    })
)(SNEventDialog);