import {
    GET_SURVEYS_COMPLETE,
    GET_SURVEYS_LOADING,
    GET_SURVEYS_SUCCESS,    
    FAILED_SURVEYS_SERVICE,        


} from "../actions/surveyActions";


const initialState = {
    suveys:[],
    surveyAnswers: {
        Data: []
    },
    pageSize:5,
    page:0,  
    surveysLoading:true,  
    surveysLoaded:false, 
};

export default function surveysReducer(state = initialState, action) {
    
    switch (action.type) {
        case GET_SURVEYS_LOADING:
            return {
                ...state, 
                surveysLoading:true,                       
                
            };
        case GET_SURVEYS_SUCCESS:            
            
            return {
                ...state,                
                surveys:action.data,
                surveysLoading:false,
                surveysLoaded:true, 
            };        
       
        case FAILED_SURVEYS_SERVICE:
            return {
                ...state,           
                surveysLoading:false,     
            }

        default:
            return state
    }
}