import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import B2BCustomerSummaryCard from "./B2BCustomerSummaryCard";
import B2BCustomerDetailsPanel from "./B2BCustomerDetailsPanel";
import {getAllB2BCustomers, createB2BUser, updateB2BUser, getPricingOptions} from '../../redux/actions/businessAdminActions';
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";



class B2BCustomersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: {},
            id: 'all',
            open: false,
            email: '',
            branch: '',
            address: '',
            address2: '',
            city: '',
            postalCode: '',
            stateProvince: '',
            country: '',
            pricingTier: '',
            name: '',
            password: '',
            paymentTerms: '',
            create: true,
            largeCustomer: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeBool = this.handleChangeBool.bind(this);
    }

    static propTypes = {
        authorized: PropTypes.bool,
        customers: PropTypes.array,
        createB2BUser: PropTypes.func,
        getAllB2BCustomers: PropTypes.func,
        getPricingOptions: PropTypes.func,
        pricingOptions: PropTypes.array,
        updateB2BUser: PropTypes.func,
        roles: PropTypes.array
    };

    componentDidMount() {
        this.props.getAllB2BCustomers();
        this.props.getPricingOptions();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleChangeBool(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit(event) {
        const {email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, create, paymentTerms, largeCustomer} = this.state;
        event.preventDefault();
        if(create) {
            this.props.createB2BUser(email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer);
        } else {
            this.props.updateB2BUser(email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer);
        }
        this.closeCreateUserDialog();
    };

    openCreateUserDialog = () => {
        this.setState({
            open: true
        })
    };

    closeCreateUserDialog = () => {
        this.setState({
            open: false,
            create: true,
            email: '',
            branch: '',
            address: '',
            address2: '',
            city: '',
            postalCode: '',
            stateProvince: '',
            country: '',
            pricingTier: '',
            name: '',
            password: '',
            paymentTerms: '',
            largeCustomer: false
        })
    };

    renderCustomers = () => {
        return this.props.customers.map(customer => {
            return (<B2BCustomerSummaryCard customerData={customer} expandCustomerDetails={this.expandCustomerDetails} />);
        })
    };

    renderCustomerDetails = () => {
        const {customer} = this.state;
        if(customer !== undefined) {
            return (<B2BCustomerDetailsPanel customerData={customer} editB2BUserDetails={this.editB2BUserDetails}/>);
        }
    };

    expandCustomerDetails = (customer) => {
        this.setState({
            customer: customer
        });
    };

    editB2BUserDetails = (customer) => {
      this.setState({
          email: customer.email,
          address: customer.address,
          address2: customer.address2,
          city: customer.city,
          postalCode: customer.postalCode,
          stateProvince: customer.stateProvince,
          country: customer.country,
          name: customer.name,
          branch: customer.branch,
          pricingTier: customer.pricingTier,
          paymentTerms: customer.paymentTerms,
          largeCustomer: customer.largeCustomer,
          create: false,
      });
      this.openCreateUserDialog();
    };

    renderPricingOptions = () => {
        const { pricingOptions } = this.props;
        return (
            <Select
                id="role-select"
                name="pricingTier"
                value={this.state.pricingTier}
                onChange={this.handleChange}
            >
                {pricingOptions.map(priceTier => {
                    return (<MenuItem value={priceTier}>{priceTier}</MenuItem>);
                })}
            </Select>
        );
    };

    renderUserCreateDialog = () => {
        const {create} = this.state;
        return(
            <form onSubmit={this.handleSubmit}>
                <Dialog open={this.state.open} onClose={this.closeCreateUserDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{create ? 'Create' : 'Edit'} a Glorious B2B User Account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter in all the user information, it can be updated at a later time. Once you create the user they will be able to log in to the B2B Portal.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            fullWidth
                            disabled={!create}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="name"
                            fullWidth
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="address"
                            name="address"
                            label="Address"
                            type="address"
                            fullWidth
                            value={this.state.address}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="address2"
                            name="address2"
                            label="Address2"
                            type="address"
                            fullWidth
                            value={this.state.address2}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="city"
                            name="city"
                            label="City"
                            type="address"
                            value={this.state.city}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="stateProvince"
                            name="stateProvince"
                            label="State/Province"
                            type="address"
                            value={this.state.stateProvince}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="postalCode"
                            name="postalCode"
                            label="Postal Code"
                            type="postalCode"
                            value={this.state.postalCode}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="country"
                            name="country"
                            label="country"
                            type="country"
                            fullWidth
                            value={this.state.country}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="paymentTerms"
                            name="paymentTerms"
                            label="Payment Terms"
                            type="paymentTerms"
                            fullWidth
                            value={this.state.paymentTerms}
                            onChange={this.handleChange}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.largeCustomer}
                                    onChange={this.handleChangeBool}
                                    name="largeCustomer"
                                    color="primary"
                                />
                            }
                            label="Large Customer"
                        />
                        <FormControl className={'formControlSelect'}>
                            <InputLabel>Branch</InputLabel>
                            <Select
                                id="role-select"
                                name="branch"
                                value={this.state.branch}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={'Hong Kong'}>Hong Kong Warehouse</MenuItem>
                                <MenuItem value={'Rakuten'}>Rakuten U.S.A</MenuItem>
                                <MenuItem value={'China'}>China Factory</MenuItem>
                            </Select>
                        </FormControl>
                        {'   '}
                        <FormControl className={'formControlSelect'}>
                            <InputLabel>Pricing Tier</InputLabel>
                            {this.renderPricingOptions()}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeCreateUserDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            {create ? 'Create' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        )
    };


    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h3'>Glorious B2B Customers
                        <IconButton color="secondary" aria-label="add user" onClick={this.openCreateUserDialog}>
                            <PersonAddIcon />
                        </IconButton>
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderCustomers()}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderCustomerDetails()}
                    </Grid>
                </Grid>
                {this.renderUserCreateDialog()}
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        customers: state.businessAdminReducer.customers,
        pricingOptions: state.businessAdminReducer.pricingOptions,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getAllB2BCustomers: () => dispatch(getAllB2BCustomers()),
        createB2BUser: (email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer) => dispatch(createB2BUser(email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer)),
        updateB2BUser: (email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer) => dispatch(updateB2BUser(email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer)),
        getPricingOptions: () => dispatch(getPricingOptions())
    })
)(B2BCustomersView);