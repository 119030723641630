import {
    GET_PRODUCT_REGISTRATION_COMPLETE,
    GET_PRODUCT_REGISTRATION_LOADING,
    GET_PRODUCT_REGISTRATION_SUCCESS,    
    FAILED_PRODUCT_REGISTRATION_SERVICE,        
} from "../actions/productRegistrationsActions";


const initialState = {
    productRegistrations: {
        Data: []
    },
    pageSize:5,
    page:0,
    searchingForRegistrations: false,
};

export default function productRegistrationsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_REGISTRATION_LOADING:
            return {
                ...state,
                searchingForRegistrations: true,
            };
        case GET_PRODUCT_REGISTRATION_SUCCESS:            
            return {
                ...state,
                searchingForRegistrations: false,
                productRegistrations:action.data
            };        
       
        case FAILED_PRODUCT_REGISTRATION_SERVICE:
            return {
                ...state,
                searchingForRegistrations: false,
            }

        default:
            return state
    }
}