import {
    GET_LOYALTY_POINTS_LOADING,
    GET_LOYALTY_POINTS_SUCCESS,
    GET_LOYALTY_POINTS_COMPLETE,
    GET_LOYALTY_POINTS_FAILED,

    CREATE_LOYALTY_POINTS_LOADING,
    CREATE_LOYALTY_POINTS_SUCCESS,
    CREATE_LOYALTY_POINTS_COMPLETE,
    CREATE_LOYALTY_POINTS_FAILED,

    UPDATE_LOYALTY_POINTS_LOADING,
    UPDATE_LOYALTY_POINTS_SUCCESS,
    UPDATE_LOYALTY_POINTS_COMPLETE,
    UPDATE_LOYALTY_POINTS_FAILED,

    LOAD_LOYALTY_CUSTOMER_POINTS_LOADING,
    LOAD_LOYALTY_CUSTOMER_POINTS_SUCCESS,
    LOAD_LOYALTY_CUSTOMER_POINTS_COMPLETE,
    LOAD_LOYALTY_CUSTOMER_POINTS_FAILED,

    UPDATE_LOYALTY_CUSTOMER_POINTS_LOADING,
    UPDATE_LOYALTY_CUSTOMER_POINTS_SUCCESS,
    UPDATE_LOYALTY_CUSTOMER_POINTS_COMPLETE,
    UPDATE_LOYALTY_CUSTOMER_POINTS_FAILED,

    DELETE_LOYALTY_POINTS_LOADING,
    DELETE_LOYALTY_POINTS_SUCCESS,
    DELETE_LOYALTY_POINTS_COMPLETE,
    DELETE_LOYALTY_POINTS_FAILED,
} from "../actions/loyaltyPointsActions";


const initialState = {

};

export default function softwareDownloadsReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_LOYALTY_CUSTOMER_POINTS_LOADING:
            return {
                ...state,
                loading:true,
                error: false,
            };
        case UPDATE_LOYALTY_CUSTOMER_POINTS_SUCCESS:
            return {
                ...state,
                customerPointData: action.data,
                error: false,
                errorMessage: ''
            };

        case UPDATE_LOYALTY_CUSTOMER_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }
            
            case UPDATE_LOYALTY_CUSTOMER_POINTS_FAILED:
                return {
                    ...state,
                    loading:false,
                    error: true,
                    errorMessage: 'Could not get data'
                };


        case GET_LOYALTY_POINTS_LOADING:
            return {
                ...state,
                loading:true,
                error: false,
            };
        case GET_LOYALTY_POINTS_SUCCESS:
            var total = 0;

            if (action.data!=null){
                total = action.data.length;
            }
            return {
                ...state,
                data: action.data,
                total: total,
                error: false,
                errorMessage: ''
            };

        case GET_LOYALTY_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }

        case GET_LOYALTY_POINTS_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: 'Could not get data'
            };


        case LOAD_LOYALTY_CUSTOMER_POINTS_LOADING:
            return {
                ...state,
                loading:true,
                error: false,
            };
        case LOAD_LOYALTY_CUSTOMER_POINTS_SUCCESS:
            return {
                ...state,
                customerPointData: action.data,
                error: false,
                errorMessage: ''
            };

        case LOAD_LOYALTY_CUSTOMER_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }

        case LOAD_LOYALTY_CUSTOMER_POINTS_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: 'Could not get data'
            };



        case DELETE_LOYALTY_POINTS_LOADING:
            return {
                ...state,
                loading:true,
                error: false,
            };
        case DELETE_LOYALTY_POINTS_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: ''
            };

        case DELETE_LOYALTY_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }

        case DELETE_LOYALTY_POINTS_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: 'Could not delete data'
            };



        case UPDATE_LOYALTY_POINTS_LOADING:
            return {
                ...state,
                loading:true,
                error: false,
            };
        case UPDATE_LOYALTY_POINTS_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: ''
            };

        case UPDATE_LOYALTY_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }

        case UPDATE_LOYALTY_POINTS_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: 'Could not update data'
            };



        case CREATE_LOYALTY_POINTS_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            };

        case CREATE_LOYALTY_POINTS_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: false,
                errorMessage: ''
            };

        case CREATE_LOYALTY_POINTS_COMPLETE:
            return {
                ...state,
                loading:false,
            }

        case CREATE_LOYALTY_POINTS_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: 'Could not create data'
            };

        default:
            return state
    }
}
