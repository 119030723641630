import {getServiceCallOptions} from "../../services/registoriousApi";


import {getServiceCallOptions as getNexusApiServiceCallOptions} from "../../services/api";

import {postServiceCallOptions} from "../../services/registoriousApi";
import {postServiceCallOptionsAsJson as postNexusApiServiceCallOptions} from "../../services/api";

export const DELETE_CUSTOMER_LOYALTY_DATA_LOADING        = 'DELETE_CUSTOMER_LOYALTY_DATA_LOADING';
export const DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS       = 'DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS';
export const DELETE_CUSTOMER_LOYALTY_DATA_COMPLETE       = 'DELETE_CUSTOMER_LOYALTY_DATA_COMPLETE';
export const FAILED_DELETE_CUSTOMER_LOYALTY_DATA         = 'FAILED_DELETE_CUSTOMER_LOYALTY_DATA';
export const DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING       = 'DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING';
export const DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS      = 'DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS';
export const DELETE_CUSTOMER_REGISTORIOUS_DATA_COMPLETE      = 'DELETE_CUSTOMER_REGISTORIOUS_DATA_COMPLETE';
export const FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA        = 'FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA';
export const DELETE_CUSTOMER_SHOPIFY_DATA_LOADING        = 'DELETE_CUSTOMER_SHOPIFY_DATA_LOADING';
export const DELETE_CUSTOMER_SHOPIFY_DATA_SUCCESS       = 'DELETE_CUSTOMER_SHOPIFY_DATA_SUCCESS';
export const DELETE_CUSTOMER_SHOPIFY_DATA_COMPLETE       = 'DELETE_CUSTOMER_SHOPIFY_DATA_COMPLETE';
export const FAILED_DELETE_CUSTOMER_SHOPIFY_DATA         = 'FAILED_DELETE_CUSTOMER_SHOPIFY_DATA';

const host = 'https://api.gpcgr.com';

//const host = 'https://fff4d48479b6.ngrok.io';

export const deleteRegistoriousCustomerData = (email,userProfile) => {
    return (dispatch) => {
        dispatch({type: DELETE_CUSTOMER_LOYALTY_DATA_LOADING});
        let url = `/admin/customers/${email}`;        
        return getServiceCallOptions(url, DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS, FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA, dispatch, DELETE_CUSTOMER_REGISTORIOUS_DATA_COMPLETE, 'DELETE',userProfile);
    }
};


export const deleteLoyaltyCustomerData  = (email) => {
    return (dispatch) => {
        dispatch({type: DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING});
        let url = host+ `/loyalty/customer/${email}`;
        return getNexusApiServiceCallOptions(url, DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS, FAILED_DELETE_CUSTOMER_LOYALTY_DATA, dispatch, DELETE_CUSTOMER_LOYALTY_DATA_COMPLETE, 'DELETE');        
    }
};


export const deleteRegistoriousCustomerDataBulk = (emails,userProfile) => {
    return (dispatch) => {
        dispatch({type: DELETE_CUSTOMER_LOYALTY_DATA_LOADING});
        let url = '/admin/customers/bulk-delete';        
        return postServiceCallOptions(url, DELETE_CUSTOMER_REGISTORIOUS_DATA_SUCCESS, FAILED_DELETE_CUSTOMER_REGISTORIOUS_DATA, dispatch, DELETE_CUSTOMER_REGISTORIOUS_DATA_COMPLETE,JSON.stringify(emails), 'POST',userProfile);
    }
};


export const deleteLoyaltyCustomerDataBulk  = (emails) => {
    return (dispatch) => {
        dispatch({type: DELETE_CUSTOMER_REGISTORIOUS_DATA_LOADING});
        let url = host+ '/loyalty/customer/bulk-delete';
        return postNexusApiServiceCallOptions(url, DELETE_CUSTOMER_LOYALTY_DATA_SUCCESS, FAILED_DELETE_CUSTOMER_LOYALTY_DATA, dispatch, DELETE_CUSTOMER_LOYALTY_DATA_COMPLETE,JSON.stringify(emails), 'POST');        
    }
};



export const deleteShopifyCustomerData =(email) => {
    return (dispatch) => {
        dispatch({type: DELETE_CUSTOMER_SHOPIFY_DATA_LOADING});
        let url = host+ `/loyalty/customer-shopify/${email}`;
        return getNexusApiServiceCallOptions(url, DELETE_CUSTOMER_SHOPIFY_DATA_SUCCESS, FAILED_DELETE_CUSTOMER_SHOPIFY_DATA, dispatch, DELETE_CUSTOMER_SHOPIFY_DATA_COMPLETE, 'DELETE');
    }
};
