import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import { getRecentInventory } from '../../redux/actions/inventoryActions';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
const _ = require('lodash/function');
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {updateB2BProduct} from "../../redux/actions/businessAdminActions";

class B2BProductsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: false
        }
    }

    static propTypes = {
        getRecentInventory: PropTypes.func,
        loadingInventory: PropTypes.bool,
        stockData: PropTypes.array,
        authorized: PropTypes.bool,
        roles: PropTypes.array
    };

    componentDidMount() {
        this.props.getRecentInventory();
    }

    buildColumnDataForRole = () => {
        let columns = [];
            columns.push(
                { title: 'SKU', field: 'sku', editable: 'never' },
                { title: 'Description', field: 'productName', editable: 'never'},
                { title: 'Type', field: 'productType', editable: 'never' },
                { title: 'MOQ', field: 'moq'},
                { title: 'Image URL', field: 'imageURL'},
                { title: 'Active', field: 'active', type: 'boolean'},
            );
            if(this.props.stockData.length > 0) {
                for (const [key, value] of Object.entries(this.props.stockData[0])) {
                    if (!key.includes("(OH)") &&
                        !key.includes("(PROD)") &&
                        !key.includes("sku") &&
                        !key.includes("priceOptions") &&
                        !key.includes("productName") &&
                        !key.includes("productType") &&
                        !key.includes("Notes") &&
                        !key.includes("moq") &&
                        !key.includes("imageURL") &&
                        !key.includes("active") &&
                        !key.includes("tableData")
                    ) {
                        columns.push({title: key, field: key, editable: 'never'});
                    }
                }
            }
        return columns;
    };

    handleDBUpdate = (productData) => {
        this.props.updateB2BProduct(productData.sku, productData.moq, productData.imageURL, productData.active);
    };

    renderMaterialTable = () => {
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <MaterialTable
                title={'Inventory Report Table'}
                icons={tableIcons}
                columns={this.buildColumnDataForRole()}
                data={this.props.stockData}
                options={{
                    exportButton: true,
                    pageSizeOptions: [5,10,15,20,50,185],
                    columnsButton:true
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.props.stockData;
                                    const index = data.indexOf(oldData);
                                    this.handleDBUpdate(newData);
                                    data[index] = newData;
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        })
                }}
            />
        )

    };

    renderLoadingBar = () => {
        if(this.props.loadingInventory) {
            return(
                <LinearProgress className={'progress'} color="secondary" />
            );
        }
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6' >Glorious B2B Product Info</Typography>
                </div>
                {this.renderLoadingBar()}
                {this.renderMaterialTable()}
            </div>
        );
    }

}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        users: state.userReducer.users,
        stockData: state.inventoryReducer.stockData,
        loadingInventory: state.inventoryReducer.loadingInventory,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getRecentInventory: () => dispatch(getRecentInventory()),
        updateB2BProduct: (sku, moq, imageURL, active) => dispatch(updateB2BProduct(sku, moq, imageURL, active))
    })
)(B2BProductsView);