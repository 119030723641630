import {
    GET_SOFTWARE_DOWNLOADS_LOADING,
    GET_SOFTWARE_DOWNLOADS_SUCCESS,
    GET_SOFTWARE_DOWNLOADS_COMPLETE,
    GET_SOFTWARE_DOWNLOADS_FAILED,

    UPLOAD_SOFTWARE_DOWNLOADS_LOADING,
    UPLOAD_SOFTWARE_DOWNLOADS_SUCCESS,
    UPLOAD_SOFTWARE_DOWNLOADS_COMPLETE,
    UPLOAD_SOFTWARE_DOWNLOADS_FAILED,

    DELETE_SOFTWARE_DOWNLOADS_LOADING,
    DELETE_SOFTWARE_DOWNLOADS_SUCCESS,
    DELETE_SOFTWARE_DOWNLOADS_COMPLETE,
    DELETE_SOFTWARE_DOWNLOADS_FAILED
} from "../actions/softwareDownloadsActions";


const initialState = {

};

export default function softwareDownloadsReducer(state = initialState, action) {
    switch (action.type) {

        case GET_SOFTWARE_DOWNLOADS_LOADING:
            return {
                ...state,
                softwareDownloadsLoading: true,
                    softwareDownloadsError: false
            };
        case GET_SOFTWARE_DOWNLOADS_SUCCESS:
            var totalSize = 0;

            if (action.data!=null){
                totalSize = action.data.reduce((a, {fileSize}) => a + fileSize, 0);
            }
            return {
                ...state,
                data: action.data,
                softwareDownloadsError: false,
                totalSize:totalSize
            };

        case GET_SOFTWARE_DOWNLOADS_COMPLETE:
            return {
                ...state,
                softwareDownloadsLoading: false,
            }

        case GET_SOFTWARE_DOWNLOADS_FAILED:
            return {
                ...state,
                softwareDownloadsError: true,
            };



        case DELETE_SOFTWARE_DOWNLOADS_LOADING:
            return {
                ...state,
                softwareDownloadsLoading: true,
                    softwareDownloadsError: false
            };
        case DELETE_SOFTWARE_DOWNLOADS_SUCCESS:
            return {
                ...state,
                softwareDownloadsError: false,
            };

        case DELETE_SOFTWARE_DOWNLOADS_COMPLETE:
            return {
                ...state,
                softwareDownloadsLoading: false,
            }

        case DELETE_SOFTWARE_DOWNLOADS_FAILED:
            return {
                ...state,
                softwareDownloadsError: true,
            };



        case UPLOAD_SOFTWARE_DOWNLOADS_LOADING:
            return {
                ...state,
                softwareDownloadsLoading: true,
                uploadStatus:1,
                    softwareDownloadsError: false
            };
        case UPLOAD_SOFTWARE_DOWNLOADS_SUCCESS:
            return {
                ...state,
                uploadStatus:100,
                softwareDownloadsError: false,
            };

        case UPLOAD_SOFTWARE_DOWNLOADS_COMPLETE:
            return {
                ...state,
                uploadCompleted:true,
                uploadStatus:300,
                softwareDownloadsLoading: false,
            }

        case UPLOAD_SOFTWARE_DOWNLOADS_FAILED:
            return {
                ...state,
                uploadCompleted:true,
                uploadStatus:200,
                softwareDownloadsError: true,
            };



        default:
            return state
    }
}
