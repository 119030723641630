import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {Card} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DistributorGroupBuyEditor from "./DistributorGroupBuyEditor";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {updateDistributorGroupBuyEntry} from '../../redux/actions/distributorActions';

class DistributorGroupBuyViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            url: '',
            sku: '',
            id: '',
            create: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        groupBuyData: PropTypes.array,
        distributorID: PropTypes.string,
        updateDistributorGroupBuyEntry: PropTypes.func,
    };

    static defaultProps = {
        groupBuyData: []
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        const {url, sku, id} = this.state;
        console.log(this.props.distributorID + ' ' +  id + ' ' + sku + ' ' + url);
        this.props.updateDistributorGroupBuyEntry(this.props.distributorID, id, sku, url);
        this.toggleGroupBuyDialog();
    };

    renderGroupBuyData = () => {
        return this.props.groupBuyData.map(groupBuyEntry => {
            return <DistributorGroupBuyEditor groupBuyData={groupBuyEntry} editGroupBuyDetails={this.editGroupBuyDetails}/>
        })
    };

    editGroupBuyDetails = (groupBuyData) => {
        this.setState({
            url: groupBuyData.url,
            sku: groupBuyData.sku,
            id: groupBuyData._id
        });
        this.toggleGroupBuyDialog();
    };


    toggleGroupBuyDialog = () => {
        if(this.state.open) {
            this.setState({
                url: '',
                id: '',
                sku: ''
            });
        }
        this.setState({
            open: !this.state.open
        })
    };

    renderGroupBuyDialog = () => {
        const {create} = this.state;
        return(
            <form onSubmit={this.handleSubmit}>
                <Dialog open={this.state.open} onClose={this.toggleGroupBuyDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{create ? 'Add a' : 'Edit the'} Group Buy/Product for this Distributor</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add the SKU and URL and click update, once updated the data will be present live on Glorious Distributor List.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="sku"
                            label="Product SKU"
                            name="sku"
                            type="name"
                            fullWidth
                            value={this.state.sku}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="url"
                            name="url"
                            label="Distributor URL"
                            type="url"
                            fullWidth
                            value={this.state.url}
                            onChange={this.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleGroupBuyDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            {create ? 'Add ' : 'Edit'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        )
    };



    render() {
        return (
            <div>
                Add a Group Buy Product
                <IconButton color="primary" aria-label="add group buy product" onClick={this.toggleGroupBuyDialog}>
                    <PersonAddIcon />
                </IconButton>
                {this.renderGroupBuyData()}
                {this.renderGroupBuyDialog()}
            </div>
        );
    }
}

export default connect(
    state => ({
    }),
    dispatch => ({
        updateDistributorGroupBuyEntry: (distributorID, groupBuyID, sku, url) => dispatch(updateDistributorGroupBuyEntry(distributorID, groupBuyID, sku, url))
    })
)(DistributorGroupBuyViewer);