import React, {Component} from "react";
import PropTypes from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//import {getProductRegistrations} from '../../redux/actions/productRegistrationsActions';
import {CircularProgress,TableHead} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import MaterialTable from 'material-table';
import ExportIcon from '@material-ui/icons/CloudDownload';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const host = 'https://registorious.gloriousgamingservices.com';

class ProductRegistrationsPage extends Component {



    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            pageSize:5,
            page:0,
            productRegistrations:{
                Data:[],
                TotalCount:0
            }
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array,
        userProfile: PropTypes.object,
        productRegistrations: PropTypes.object,
        searchingForRegistrations: PropTypes.bool,
    };

    onPageChange = (pageNumber,pageSize)=>{
        // this.setState({
        //     pageSize:pageSize,
        //     page:pageNumber
        // })
        // this.props.getProductRegistrations(this.state.searchText,pageNumber,pageSize,this.props.userProfile);
    }
    getData=(query)=>{

        // error: undefined
        // filters: []
        // orderBy: undefined
        // orderDirection: ""
        // page: 0
        // pageSize: 5
        // search: ""
        // totalCount: 0

        return this.props.productRegistrations.Data;
    }

    onRowsPerPageChange= (size)=>{
        // this.setState({
        //     pageSize:size
        // })
        // this.props.getProductRegistrations(this.state.searchText,this.state.page,size,this.props.userProfile);
    }
    editRow = (event)=>{

    }

    // updateSearchText = (event) => {
    //     this.setState({
    //         searchText: event.target.value
    //     })

    // };
    // searchRegistrations = () => {
    //     this.setState({
    //         page:0
    //     })
    //     this.props.getProductRegistrations(this.state.searchText,0,this.state.pageSize,this.props.userProfile);
    // };
    renderLineItems = (items) =>{
        if(items !== undefined && items !== null) {
            return items.map((item, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell >{item.Email}</TableCell>
                        <TableCell>{item.FirstName}</TableCell>
                        <TableCell>{item.LastName}</TableCell>
                        <TableCell>{item.SKU}</TableCell>
                        <TableCell>{item.ProductTitle}</TableCell>
                        <TableCell>{item.SerialNumber}</TableCell>
                        <TableCell>{item.PlaceOfPurchase}</TableCell>
                        <TableCell>{new Date(item.DateOfPurchaseUtc).toLocaleDateString()}</TableCell>
                        <TableCell>{new Date(item.CreatedDate).toLocaleString()}</TableCell>
                    </TableRow>
                )
            })
        }
    };

    buildColumnData = ()=>{
        let columns = [];
        const getLocalDate = date =>{
            return (new Date(date)).toLocaleDateString();
        };
        const getLocalDateTime = date =>{
            return (new Date(date)).toLocaleString();
        };
        columns.push(
            { title: 'Email', field: 'Email' },
            { title: 'First Name', field: 'FirstName' },
            { title: 'Last Name', field: 'LastName' },
            { title: 'SKU', field: 'SKU' },
            { title: 'Product Title', field: 'ProductTitle' },
            { title: 'Serial Number', field: 'SerialNumber' },
            { title: 'Place Of Purchase', field: 'PlaceOfPurchase'},
            { title: 'Date Of Purchase', field: 'DateOfPurchaseUtc',type:"date", render: rowData => getLocalDate(rowData.DateOfPurchaseUtc)},
            { title: 'Created Date', field: 'CreatedDate', type:"datetime", render: rowData => getLocalDateTime(rowData.CreatedDate)},
        );

        return columns;
    }

    componentDidMount() {
        //this.props.getProductRegistrations(this.state.searchText,0,this.state.pageSize,this.props.userProfile);
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }

        if (this.props.roles==undefined ||
            (!this.props.roles.includes('admin') &&
            !this.props.roles.includes('cs_admin') &&
            !this.props.roles.includes('tier3') &&
            !this.props.roles.includes('tier1'))
        ) {
            this.props.history.push('/');
        }

        const data={
            data:this.props.productRegistrations.Data,
            page:this.props.productRegistrations.CurrentPage,
            totalCount:this.props.productRegistrations.TotalCount
        };
        const totalCount = this.props.productRegistrations!=undefined && this.props.productRegistrations.TotalCount !=undefined?this.props.productRegistrations.TotalCount:null
        const currentPage = this.props.productRegistrations!=undefined && this.props.productRegistrations.TotalCount !=undefined?this.props.productRegistrations.CurrentPage:null
        const pageSize = this.state.pageSize;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Product registrations</Typography>
                </div>
                {/* <div className={'searchDetailsCard'}>
                    <div className={'searchButtonBlock'}>
                        <Typography className={'searchTitle'}></Typography>
                        <TextField autoFocus id="outlined-basic" label="Search"
                        onChange={this.updateSearchText}/>
                        <Button className={'searchOrderButton'} variant="contained" onClick={this.searchRegistrations} color="primary">Search</Button>
                        {this.state.searchingForRegistrations && <CircularProgress color="secondary" />}
                    </div>
                </div> */}
                <div className={"results"}>
                <MaterialTable
                title={'Product registrations'}
                icons={tableIcons}
                columns={this.buildColumnData()}
                actions={[
                    {
                      icon: ExportIcon,
                      tooltip: 'Export to CSV',
                      isFreeAction: true,
                      onClick: (event) => this.editRow(event)
                    }
                  ]}
                data={query=>
                    new Promise((resolve,reject)=>{

                        let sortBy = query.orderBy;
                        let sortDirection = query.orderDirection;
                        sortBy = sortBy == undefined?"Id":sortBy.field;
                        sortDirection = sortDirection == undefined||sortDirection==""?"asc":sortDirection;
                        let url = host+`/admin/product-registrations/list/?page=${query.page}&take=${query.pageSize}&sortBy=${sortBy}&SortDirection=${sortDirection}&search=${query.search}`;
                        fetch(url,
                            {
                                method: "GET",
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                                }
                            }
                            ).then(resp=>resp.json()).then(resp=>{
                            resolve({
                                data:resp.Data,
                                page:resp.CurrentPage,
                                totalCount: resp.TotalCount
                            });
                        })
                    })

                }
                onChangeRowsPerPage={this.onRowsPerPageChange}
                onChangePage= { this.onPageChange}
                options={{
                    exportButton: true,
                    exportAllData: true,
                    pageSizeOptions: [5,10,25,50,100,1000,10000],
                    columnsButton:true,
                    grouping:true
                }}
                editable={{

                    onRowUpdate: (newData, oldData) => {
                      return new Promise((resolve, reject) => {
                        let url = host+'/admin/product-registrations';
                        fetch(url,
                            {
                                method: "PUT",
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type':'application/json'
                                },
                                body:JSON.stringify(newData)
                            }
                            ).then(resp=>resolve()).
                            catch(e=>reject(e));
                        })
                    },
                    onRowDelete: (oldData) => {
                      return new Promise((resolve, reject) => {
                        let url = host+'/admin/product-registrations/'+oldData.Id;
                        fetch(url,
                            {
                                method: "DELETE",
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                                },
                            }
                            ).then(resp=>resolve()).
                            catch(e=>reject(e));
                        })
                    },
                  }}
                />

                    {/* <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>Serial Number</TableCell>
                                <TableCell>Place of Purchase</TableCell>
                                <TableCell>Order Date</TableCell>
                                <TableCell>Registered At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.renderLineItems(data)}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={pageSize}
                            page={currentPage}
                            onPageChange={this.onPageChange}
                            onRowsPerPageChange={this.onRowsPerPageChange}
                        />
                    </Table> */}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        productRegistrations: state.productRegistrationsReducer.productRegistrations,
        searchingForRegistrations: state.productRegistrationsReducer.searchingForRegistrations,
        pageSize: state.productRegistrationsReducer.pageSize,
        page: state.productRegistrationsReducer.page,
        }),
    dispatch => ({
         getProductRegistrations: (search,page,take,userProfile) => dispatch(getProductRegistrations(search,page,take,userProfile)),
    })
)(ProductRegistrationsPage);
