import {getServiceCallOptions} from "../../services/registoriousApi";

export const GET_PRODUCT_REGISTRATION_LOADING = 'GET_PRODUCT_REGISTRATION_LOADING';
export const GET_PRODUCT_REGISTRATION_SUCCESS= 'GET_PRODUCT_REGISTRATION_SUCCESS';
export const GET_PRODUCT_REGISTRATION_COMPLETE = 'GET_PRODUCT_REGISTRATION_COMPLETE';

export const FAILED_PRODUCT_REGISTRATION_SERVICE = 'FAILED_PRODUCT_REGISTRATION_SERVICE';

//const host = 'https://api.gpcgr.com/serial';


export const getProductRegistrations = (search,page,take,userProfile) => {
    return (dispatch) => {
        dispatch({type: GET_PRODUCT_REGISTRATION_LOADING});
        let url = `/admin/product-registrations/list/?page=${page}&take=${10000000}&sortBy=Id&SortDirection=asc&search=${search}`;
        return getServiceCallOptions(url, GET_PRODUCT_REGISTRATION_SUCCESS, FAILED_PRODUCT_REGISTRATION_SERVICE, dispatch, GET_PRODUCT_REGISTRATION_COMPLETE, 'GET',userProfile);
    }
};
