import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import {
    getAllDistributors,
    updateDistributorInformation,
    createDistributorEntry,
    deleteDistributorEntry
} from '../../redux/actions/distributorActions';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
const _ = require('lodash/function');
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DistributorGroupBuyViewer from "./DistributorGroupBuyViewer";

class Distributors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: false
        }
    }

    static propTypes = {
        getAllDistributors: PropTypes.func,
        createDistributorEntry:PropTypes.func,
        loadingDistributors: PropTypes.bool,
        distributors: PropTypes.array,
        deleteDistributorEntry: PropTypes.func,
        updateDistributorInformation: PropTypes.func,
        authorized: PropTypes.bool,
        roles: PropTypes.string
    };

    componentDidMount() {
        this.props.getAllDistributors();
    }

    buildColumnDataForRole = () => {
        let columns = [];
        if(!this.props.roles.includes('tier1') && !this.props.roles.includes('tier2')) {
            columns.push(
                { title: 'Distributor Name', field: 'name', editable: 'always' },
                { title: 'Website URL', field: 'website', editable: 'always'},
                { title: 'Country', field: 'country', editable: 'always' },
            );
        }
        if(!this.props.roles.includes('admin')) {
            columns.push(
                { title: 'Distributor Name', field: 'name', editable: 'never' },
                { title: 'Website URL', field: 'website', editable: 'never'},
                { title: 'Country', field: 'country', editable: 'never' },
            );
        }
        return columns;
    };


    handleDBUpdate = (updatedDistributorData) => {
        this.props.updateDistributorInformation(updatedDistributorData.country, updatedDistributorData.name, updatedDistributorData.website, updatedDistributorData._id);
    };

    handleDBDelete = (oldDistributorData) => {
        this.props.deleteDistributorEntry(oldDistributorData._id);
    };

    handleDBAdd = (updatedDistributorData) => {
        this.props.createDistributorEntry(updatedDistributorData.country, updatedDistributorData.name, updatedDistributorData.website);
    };

    renderMaterialTable = () => {
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <MaterialTable
                title={'Distributors'}
                icons={tableIcons}
                columns={this.buildColumnDataForRole()}
                data={this.props.distributors}
                options={{
                    exportButton: true,
                    grouping: true,
                    pageSizeOptions: [5,10,15,20,50],
                    columnsButton:true
                }}
                detailPanel={[
                    {
                        tooltip: 'Show Group Buys',
                        render: rowData => {
                            return (
                                <DistributorGroupBuyViewer groupBuyData={rowData.groupBuy} distributorID={rowData._id} />
                            )
                        },
                    }]
                }
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.props.distributors;
                                    data.push(newData);
                                    this.handleDBAdd(newData);
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    let data = this.props.distributors;
                                    const index = data.indexOf(oldData);
                                    data.splice(index, 1);
                                    this.handleDBDelete(oldData);
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.props.distributors;
                                    const index = data.indexOf(oldData);
                                    this.handleDBUpdate(newData);
                                    data[index] = newData;
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        })
                }}
            />
        )

    };

    renderLoadingBar = () => {
        if(this.props.loadingDistributors) {
            return(
                <LinearProgress className={'progress'} color="secondary" />
            );
        }
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6' >Glorious Distributors</Typography>
                </div>
                {this.renderLoadingBar()}
                {this.renderMaterialTable()}
            </div>
        );
    }

}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        users: state.userReducer.users,
        distributors: state.distributorReducer.distributors,
        loadingDistributors: state.distributorReducer.loadingDistributors,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getAllDistributors: () => dispatch(getAllDistributors()),
        updateDistributorInformation: (country, name, website, _id) => dispatch(updateDistributorInformation(country, name, website, _id)),
        createDistributorEntry: (country, name, website) => dispatch(createDistributorEntry(country, name, website)),
        deleteDistributorEntry: (_id) => dispatch(deleteDistributorEntry(_id))
    })
)(Distributors);