import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {Card} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class B2BCustomerSummaryCard extends Component {

    static propTypes = {
        customerData: PropTypes.object,
        expandCustomerDetails: PropTypes.func
    };

    static defaultProps = {
        customerData: {}
    };

    render() {
        const {customerData} = this.props;

        return (
            <div className={'userContainer'}>
                <Card className={'userCard'} onClick={() => this.props.expandCustomerDetails(customerData)}>
                    <Typography className={'textSpacing'} variant='h6'>Customer: {customerData.name}</Typography>
                    <Typography className={'textSpacing'} variant='h6'>Email: {customerData.email}</Typography>
                    <Typography>Address: {customerData.address}</Typography>
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
    }),
    dispatch => ({
    })
)(B2BCustomerSummaryCard);