import {
    CREATE_USER_SUCCESS,
    DELETE_SUCCESS,
    GET_ALL_USERS_SUCCESS,
    LOGIN_SUCCESS,
    GET_USER_IP,
    UPDATE_USER_SUCCESS,
    SETUP_2FA_QRCODE, VERIFY_2FA_QRCODE_LOADING, VERIFY_2FA_QRCODE, VERIFY_2FA_LOGIN, LOGIN_LOCKOUT
} from "../actions/userActions";


const initialState = {
    authorized: false,
    roles: [],
    users: [],
    ip: '',
    userProfile: {},
    qrCodeURL: '',
    faVerifyLoading: false,
};

export default function verificationReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.data.user.token);
            return {
                ...state,
                roles: action.data.user.roles,
                userProfile: action.data.user,
                authorized: !action.data.user.factorAuthEnabled
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.data.users
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: action.data.users
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: action.data.users
            };
        case DELETE_SUCCESS:
            return {
                ...state,
                users: action.data.users
            };
        case GET_USER_IP:
            return {
                ...state,
                ip: action.data
            };
        case SETUP_2FA_QRCODE:
            return {
                ...state,
                qrCodeURL: action.data.qrCodeURL
            };
        case VERIFY_2FA_QRCODE_LOADING:
            return {
                ...state,
                faVerifyLoading: true
            };
        case VERIFY_2FA_QRCODE:
            return {
                ...state,
                faVerifyLoading: false,
                userProfile: action.data.user
            };
        case VERIFY_2FA_LOGIN:
            return {
                ...state,
                authorized: true
            };
        default:
            return state
    }
}