import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DrawerMenu from './DrawerMenu';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function ButtonAppBar() {
    const classes = useStyles();

    return (
            <AppBar position={"static"}>
                <Toolbar>
                    <DrawerMenu />
                    <Typography variant="h6" className={classes.title}>
                        Glorious Nexus
                    </Typography>
                </Toolbar>
            </AppBar>
    );
}
