import {getServiceCallOptions, postServiceCallOptions} from "../../services/api";
import {DISPLAY_SNACKBAR_MESSAGE} from "./verificationActions";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FAILED_SERVICE = 'FAILED_SERVICE';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_LOADING_COMPLETE = 'LOGIN_LOADING_COMPLETE';
export const GET_ALL_USERS_LOADING = 'GET_ALL_USERS_LOADING';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const GET_ALL_USERS_LOADING_COMPLETE = 'GET_ALL_USERS_LOADING_COMPLETE';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_USER_LOADING_COMPLETE = 'DELETE_USER_LOADING_COMPLETE';
export const GET_USER_IP = 'GET_USER_IP';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const RESET_PASS_ADMIN = 'RESET_PASS_ADMIN';
export const RESET_PASS_ADMIN_COMPLETE = 'RESET_PASS_ADMIN_COMPLETE';
export const SETUP_2FA_QRCODE = 'SETUP_2FA_QRCODE';
export const SETUP_2FA_QRCODE_COMPLETE = 'SETUP_2FA_QRCODE_COMPLETE';
export const VERIFY_2FA_QRCODE = 'VERIFY_2FA_QRCODE';
export const VERIFY_2FA_QRCODE_COMPLETE = 'VERIFY_2FA_QRCODE_COMPLETE';
export const VERIFY_2FA_QRCODE_LOADING = 'VERIFY_2FA_QRCODE_LOADING';
export const VERIFY_2FA_LOGIN = 'VERIFY_2FA_LOGIN';
export const VERIFY_2FA_LOGIN_COMPLETE = 'VERIFY_2FA_LOGIN_COMPLETE';
export const LOGIN_LOCKOUT = 'LOGIN_LOCKOUT';
export const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS';

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getUserIP = () => {
    return (dispatch) => {
        fetch('https://api.ipify.org?format=json', {
            method: 'GET'
        }).then(response => {
            return response.json();
        }).then(data => {
            dispatch({
                type: GET_USER_IP,
                data: data.ip
            });
        });
    }
};
export const displayLoginMessage = (message) => {
    return (dispatch) => {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: message
        })
    }
};

export const loginUser = (email,password, ip) => {
    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        let url = host + '/users/login/';
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('password', password);
        data.append('ip', ip);
        return postServiceCallOptions(url, LOGIN_SUCCESS, FAILED_SERVICE, dispatch, LOGIN_LOADING_COMPLETE, data, 'POST');
    }
};

export const lockoutUser = (email) => {
    return (dispatch) => {
        let url = host + '/users/lockout/';
        let data = new URLSearchParams();
        data.append('email', email);
        return postServiceCallOptions(url, LOGIN_LOCKOUT, FAILED_SERVICE, dispatch, LOGIN_LOADING_COMPLETE, data, 'POST');
    }
};

export const resetPasswordForUser = (email) => {
    return (dispatch) => {
        let url = host + '/reset/password/' + email;
        return getServiceCallOptions(url, UNLOCK_USER_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, 'GET');
    }
};

export const createUser = (email, name, roles) => {
    return (dispatch) => {
        let url = host + '/users/create/';
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('name', name);
        data.append('roles', roles);
        return postServiceCallOptions(url, CREATE_USER_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, data, 'POST');
    }
};

export const updateUser = (email, name, roles, admin) => {
    return (dispatch) => {
        let url = host + '/users/update/';
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('name', name);
        data.append('roles', roles);
        return postServiceCallOptions(url, UPDATE_USER_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, data, 'POST');
    }
};

export const updateProfile = (email, name, password, roles) => {
    return (dispatch) => {
        let url = host + '/profile/update/';
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('name', name);
        data.append('password', password);
        data.append('roles', roles);
        return postServiceCallOptions(url, UPDATE_USER_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, data, 'POST');
    }
};

export const deleteUser = (email) => {
    return (dispatch) => {
        dispatch({type: LOGIN_LOADING});
        let url = host + '/users/delete';
        let data = new URLSearchParams();
        data.append('email', email);
        return postServiceCallOptions(url, DELETE_SUCCESS, FAILED_SERVICE, dispatch, DELETE_USER_LOADING_COMPLETE, data, 'POST');
    }
};

export const getAllUsers = () => {
    return (dispatch) => {
        dispatch({type: GET_ALL_USERS_LOADING});
        let url = host + '/users/all/';
        return getServiceCallOptions(url, GET_ALL_USERS_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, 'GET');
    }
};

export const resetAllPasswords = () => {
    return (dispatch) => {
        let url = host + '/reset/passwords/';
        return getServiceCallOptions(url, RESET_PASS_ADMIN, FAILED_SERVICE, dispatch, RESET_PASS_ADMIN_COMPLETE, 'GET');
    }
};

export const setup2FA = () => {
    return (dispatch) => {
        let url = host + '/users/2fa/setup';
        return getServiceCallOptions(url, SETUP_2FA_QRCODE, FAILED_SERVICE, dispatch, SETUP_2FA_QRCODE_COMPLETE, 'GET');
    }
};

export const complete2FASetup = (token) => {
    return (dispatch) => {
        dispatch({type: VERIFY_2FA_QRCODE_LOADING});
        let url = host + '/users/2fa/verify';
        let data = new URLSearchParams();
        data.append('twoFactorToken', token);
        return postServiceCallOptions(url, VERIFY_2FA_QRCODE, FAILED_SERVICE, dispatch, VERIFY_2FA_QRCODE_COMPLETE, data, 'POST');
    }
};

export const verify2FALogin = (token) => {
    return (dispatch) => {
        let url = host + '/users/2fa/login';
        let data = new URLSearchParams();
        data.append('twoFactorToken', token);
        return postServiceCallOptions(url, VERIFY_2FA_LOGIN, FAILED_SERVICE, dispatch, VERIFY_2FA_LOGIN_COMPLETE, data, 'POST');
    }
};