import React, {Component} from "react";
import PropTypes from "prop-types";
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from "@material-ui/core/Grid";
import {getSerialNumberData, sendSNToDB, updateSNWithOrderID} from '../../redux/actions/serialNumberActions';
import {CircularProgress} from "@material-ui/core";
import SerialNumberEvents from "./SerialNumberEvents";

class SerialNumberUploadModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.object,
        serialNumberData: PropTypes.object,
        loadingSerialNumberData: PropTypes.bool,
        userProfile: PropTypes.object,
        updateSNWithOrderID: PropTypes.func
    };


    onChangeHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };

    submitSerialNumbers = () => {
        this.props.sendSNToDB(this.state.selectedFile);
    }

    renderUploadConfirmation = () => {
        const {selectedFile} = this.state;

        if(selectedFile !== null) {
            return (
                <div className={'csvOrderConfirmationCard'}>
                    <Typography>
                        File Name: {selectedFile.name} <br />
                        File Size: {selectedFile.size} Bytes
                    </Typography>
                    <br />
                    <Button className={'placeOrderButton'} variant="contained" color="primary" component="span" onClick={this.submitSerialNumbers}>Send to Nexus Database</Button>
                </div>
            )
        }
    };


    render() {
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Upload Serial Numbers</Typography>
                </div>
                <Card className={'uploadCard'}>
                    <Typography>
                        Upload your .XLSX File with the serial numbers. Please make sure to follow the exact format, otherwise failure will be inevitable.
                    </Typography>
                    <div>
                        <input
                            id="contained-button-file"
                            type="file"
                            accept=".xlsx"
                            onChange={this.onChangeHandler}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                                Upload
                            </Button>
                        </label>

                    </div>
                    {this.renderUploadConfirmation()}
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        sendSNToDB: (file) => dispatch(sendSNToDB(file))
    })
)(SerialNumberUploadModule);