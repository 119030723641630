import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LinearProgress from "@material-ui/core/LinearProgress";
import {TableHead} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import moment from 'moment-timezone';
class DataCard extends Component {

    static propTypes = {
        loadingCIN: PropTypes.bool,
        loadingShopify: PropTypes.bool,
        loadingRSL: PropTypes.bool,
        data: PropTypes.object,
        title: PropTypes.string,
    };

    static defaultProps = {
        data: {
            line_items: [],
            address: {},
            shipping_lines: []
        }
    };

    renderOrderNumber = (orderID) => {
        if(orderID !== ''
            && orderID !== undefined) {
            return (
                <TableRow>
                    <TableCell>
                        Shopify Order Number: {orderID}
                    </TableCell>
                </TableRow>
            )
        }
    };

    renderCustomerData = (name, email)  => {
        if(name !== ''
            && email !== ''
            && name !== undefined
            && email !== undefined) {
            return (
                <span>
                    <TableRow>
                        <TableCell>
                            Customer Name: {name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Customer Email: {email}
                        </TableCell>
                    </TableRow>
                </span>
            )
        }
    };

    isRefunded = (lineItemID) => {
        const {data} = this.props;
        let isRefunded = false;
        if(data.refunds !== undefined && data.refunds.length > 0) {
            data.refunds.map(refundEntry => {
                if(refundEntry.refund_line_items !== undefined && refundEntry.refund_line_items.length > 0) {
                    refundEntry.refund_line_items.map(refundLineItem => {
                        if(refundLineItem.line_item_id === lineItemID) {
                            isRefunded = true;
                        }
                    })
                }
            })
        }
        return isRefunded;
    }

    renderLineItems = (line_items) => {
        if(line_items !== undefined && line_items !== null) {
            return line_items.map((line_item, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell>{line_item.variant_id}</TableCell>
                        <TableCell>{(this.isRefunded(line_item.id) && <strike>{line_item.sku}</strike>) || (!this.isRefunded(line_item.id) && line_item.sku)}</TableCell>
                        <TableCell>{(this.isRefunded(line_item.id) && <strike>{line_item.title}</strike>) || (!this.isRefunded(line_item.id) && line_item.title)}</TableCell>
                        <TableCell>{line_item.quantity}</TableCell>
                        <TableCell>{line_item.price}</TableCell>
                        <TableCell>{line_item.total_discount}</TableCell>
                    </TableRow>
                )
            })
        }

    };

    renderAddressInformation = (address) => {
        if(address !== null && address !== '' && address !== undefined){
            return (
                <div>
                    <Typography>SHIPPING ADDRESS</Typography>
                    <Typography>{address.address}</Typography>
                    <Typography>{address.address2}</Typography>
                    <Typography>{address.city}{' '}{address.state}{' '}{address.postalCode}</Typography>
                    <Typography>{address.country}</Typography>
                </div>
            );
        }
    };

    renderShippingLines = (shipping_lines) => {
        if(shipping_lines !== undefined && shipping_lines !== null) {
            return shipping_lines.map(shipping_line => {
                return (
                    <Typography>{shipping_line.title}{' '}${shipping_line.price}</Typography>
                );
            })
        }
    };

    renderLoadingBar = (type) => {
        if((this.props.loadingShopify) ||
            (this.props.loadingCIN) ||
            (this.props.loadingRSL)) {
            return(
                <LinearProgress className={'progress'} color="secondary" />
            );
        }
    };

    render() {
        const {data} = this.props;

        return (
            <div className={'panelContainer'}>
                {this.renderLoadingBar(data.type)}
                <Card className={'orderDetailsCard'}>
                        <Typography className={'textSpacing'} variant='h6'>ORDER DETAILS</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {data.orderNumber==undefined?
                                <Typography>None</Typography>
                                :
                                null
                                }
                                <Typography>{data.orderNumber}</Typography>
                                <Typography>{data.name}</Typography>
                                <Typography>{data.email}</Typography>
                                {data.orderNumber!=undefined?
                                <Typography>{moment.tz(data.dateCreated, 'America/Chicago').format('MMMM Do YYYY, h:mm a')}</Typography>:
                                null
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {this.renderAddressInformation(data.address)}
                            </Grid>
                        </Grid>

                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Variant ID</TableCell>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Discounts</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.renderLineItems(data.line_items)}
                            </TableBody>
                        </Table>
                        <div className={'shippingLines'}>
                            {this.renderShippingLines(data.shipping_lines)}
                        </div>
                        <Typography className={'priceStyle'}>Total Price: ${data.total_price}</Typography>
                </Card>
            </div>
        );
    }
};

export default connect(
    state => ({
        loadingShopify: state.verificationReducer.loadingShopify,
        loadingRSL: state.verificationReducer.loadingRSL,
        loadingCIN: state.verificationReducer.loadingCIN
    }),
    dispatch => ({
    })
)(DataCard);
