
import {getServiceCallOptions as getServiceCallOptions} from "../../services/softwareDownloadsApi";
import {postFileServiceCallOptions as postFileServiceCallOptions} from "../../services/softwareDownloadsApi";
import {deleteFileServiceCallOptions as deleteFileServiceCallOptions} from "../../services/softwareDownloadsApi";


export const GET_SOFTWARE_DOWNLOADS_LOADING        = 'GET_SOFTWARE_DOWNLOADS_LOADING';
export const GET_SOFTWARE_DOWNLOADS_SUCCESS        = 'GET_SOFTWARE_DOWNLOADS_SUCCESS';
export const GET_SOFTWARE_DOWNLOADS_COMPLETE       = 'GET_SOFTWARE_DOWNLOADS_COMPLETE';
export const GET_SOFTWARE_DOWNLOADS_FAILED         = 'GET_SOFTWARE_DOWNLOADS_FAILED';

export const UPLOAD_SOFTWARE_DOWNLOADS_LOADING        = 'UPLOAD_SOFTWARE_DOWNLOADS_LOADING';
export const UPLOAD_SOFTWARE_DOWNLOADS_SUCCESS        = 'UPLOAD_SOFTWARE_DOWNLOADS_SUCCESS';
export const UPLOAD_SOFTWARE_DOWNLOADS_COMPLETE       = 'UPLOAD_SOFTWARE_DOWNLOADS_COMPLETE';
export const UPLOAD_SOFTWARE_DOWNLOADS_FAILED         = 'UPLOAD_SOFTWARE_DOWNLOADS_FAILED';

export const DELETE_SOFTWARE_DOWNLOADS_LOADING        = 'DELETE_SOFTWARE_DOWNLOADS_LOADING';
export const DELETE_SOFTWARE_DOWNLOADS_SUCCESS        = 'DELETE_SOFTWARE_DOWNLOADS_SUCCESS';
export const DELETE_SOFTWARE_DOWNLOADS_COMPLETE       = 'DELETE_SOFTWARE_DOWNLOADS_COMPLETE';
export const DELETE_SOFTWARE_DOWNLOADS_FAILED         = 'DELETE_SOFTWARE_DOWNLOADS_FAILED';

//const host = 'https://api.gpcgr.com';

const host = 'https://downloads.gloriousgamingservices.com';

export const getAllSoftwareDownloads = () => {
    return (dispatch) => {
        dispatch({type: GET_SOFTWARE_DOWNLOADS_LOADING});
        let url = host+`/software-downloads`;
        return getServiceCallOptions(url, GET_SOFTWARE_DOWNLOADS_SUCCESS, GET_SOFTWARE_DOWNLOADS_FAILED, dispatch, GET_SOFTWARE_DOWNLOADS_COMPLETE, 'GET');
    }
};

export const createSoftwareDownload = (file) => {
    return (dispatch) => {
        dispatch({type: UPLOAD_SOFTWARE_DOWNLOADS_LOADING});

        let url = host+`/software-downloads/upload`;
        return postFileServiceCallOptions(url, UPLOAD_SOFTWARE_DOWNLOADS_SUCCESS, UPLOAD_SOFTWARE_DOWNLOADS_FAILED, dispatch, UPLOAD_SOFTWARE_DOWNLOADS_COMPLETE,file);
    }
};

export const deleteSoftwareDownload = (fileName) => {
    return (dispatch) => {
        dispatch({type: DELETE_SOFTWARE_DOWNLOADS_LOADING});
        let url = host+`/software-downloads/`+fileName;
        return deleteFileServiceCallOptions(url, DELETE_SOFTWARE_DOWNLOADS_SUCCESS, DELETE_SOFTWARE_DOWNLOADS_FAILED, dispatch, DELETE_SOFTWARE_DOWNLOADS_COMPLETE);
    }
};

