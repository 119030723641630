import {getServiceCallOptions} from "../../services/registoriousApi";

export const GET_SURVEYS_LOADING = 'GET_SURVEYS_LOADING';
export const GET_SURVEYS_SUCCESS= 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_COMPLETE = 'GET_SURVEYS_COMPLETE';

export const FAILED_SURVEYS_SERVICE = 'FAILED_SURVEYS_SERVICE';

//const host = 'https://api.gpcgr.com/serial';


export const getSurveys = (userProfile) => {
    return (dispatch) => {
        dispatch({type: GET_SURVEYS_LOADING});
        let url = `/admin/surveys`;
        return getServiceCallOptions(url, GET_SURVEYS_SUCCESS, FAILED_SURVEYS_SERVICE, dispatch, GET_SURVEYS_COMPLETE, 'GET',userProfile);
    }
};
