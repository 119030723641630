import React, {Component} from "react";
import PropTypes from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {getSurveys} from '../../redux/actions/surveyActions';
import {CircularProgress,TableHead} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import MaterialTable from 'material-table';
import ExportIcon from '@material-ui/icons/CloudDownload';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { sample } from "lodash";


const host = 'https://registorious.gloriousgamingservices.com';

class SurveysPage extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            pageSize:5,    
            page:0,
            survey:"",
            surveysLoaded:false,
            surveyAnswers:{
                Data:[],
                TotalCount:0
            }                       
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array,        
        userProfile: PropTypes.object,        
        productRegistrations: PropTypes.object,
        searchingForRegistrations: PropTypes.bool,
    };
    
    onPageChange = (pageNumber,pageSize)=>{
     
    }
    getData=(query)=>{        
       
        return this.props.productRegistrations.Data;
    }

    onRowsPerPageChange= (size)=>{
        // this.setState({
        //     pageSize:size
        // })
        // this.props.getProductRegistrations(this.state.searchText,this.state.page,size,this.props.userProfile);
    }    

    getSurveysMenuitems = ()=>{
        
        if (this.props.surveysLoaded && this.props.surveys!=undefined && this.props.surveys!=null){
            if (this.state.survey==undefined || this.state.survey==""){
                this.setState({
                    survey: this.props.surveys[0].Id
                });
            }
            return this.props.surveys.map((item)=>{
                var creationData = new Date(item.CreatedDateUtc).toLocaleDateString();
                return (
                    <MenuItem key={item.id} value={item.Id}>
                        {item.Name + ' ' + creationData}
                    </MenuItem>
                );
            });
        } else{
            return null;
        }
    }

    buildObjectFromArray = (data) =>{        
        var result = data.map((a)=>{
            var newItem ={};
            newItem.Email = a.Email;
            newItem.Id = a.Id;
            newItem.SurveyId = a.SurveyId;
            newItem.CreatedAtUtc = a.CreatedAtUtc;
            a.Answers.forEach(answer=>{
                newItem[answer.Question] = answer.Answer;
            });
            return newItem;            
        });
        
        return result;
    }

    buildColumnData = ()=>{                       
        const getLocalDate = date =>{
            return (new Date(date)).toLocaleString();
        };
        const getLocalDateTime = date =>{
            return (new Date(date)).toLocaleString();
        };
        let columns = [];
        if (this.state.survey!=undefined && this.state.survey!=""){
            
            let surveyData = this.props.surveys.find(s=>s.Id == this.state.survey);
            
            columns.push(
                { title: 'Created Date', field: 'CreatedAtUtc',render: rowData => getLocalDate(rowData.CreatedAtUtc)},                
            )

            var questions = surveyData.Questions.map((q)=>{
                return {
                    title:q.Question,
                    field:q.Question,                    
                }
            });
            columns.push(...questions);
        }

        return columns;
    }

    componentDidMount() {
        if (!this.state.surveysLoaded){
            this.props.getSurveys(this.props.userProfile);        
        }
    }

    handleSurveyChange = (event)=>{
        this.setState({
            survey: event.target.value
        });
    }
    exportToCsv = (filename, data)=> {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var rows=[];
        var arrayData = this.buildObjectFromArray(data);
        var firstRow = true;
        for (var i = 0; i < arrayData.length; i++) {
            let props = Object.getOwnPropertyNames(arrayData[i]);
            if (firstRow){
                rows.push(props);
            }
            let columns=[];
            for(var j=0;j<props.length;j++){
                 columns.push(arrayData[i][props[j]]);
            }
            rows.push(columns);
            firstRow = false;
        }        
        
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    exportCurrentSurvey = ()=>{
        
        let sortBy = "CreatedAtUtc";                                
        let sortDirection = "desc";
        let search = "";
        if (this.state.query!=undefined){
            search = this.state.query.search;
        }
        let url = host+`/admin/survey-answers/list/${this.state.survey}?page=${0}&take=${1000000000}&sortBy=${sortBy}&SortDirection=${sortDirection}&search=${search}`;
        this.setState({
            exportingToCsv:true
        });
        
        fetch(url,
            {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
            ).then(resp=>resp.json()).then(resp=>{

                this.exportToCsv("survey_export.csv", resp.Data);
                this.setState({
                    exportingToCsv:false
                });
        })
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');            
        }
        
        
        
        if (this.props.roles==undefined || 
            (!this.props.roles.includes('admin') &&
            !this.props.roles.includes('cs_admin') &&
            !this.props.roles.includes('tier3') &&
            !this.props.roles.includes('tier1'))
            ) {
            this.props.history.push('/');
        }

        const data={
            data:this.props.surveyAnswers.Data,
            page:this.props.surveyAnswers.CurrentPage,
            totalCount:this.props.surveyAnswers.TotalCount
        };
        const totalCount = this.props.surveyAnswers!=undefined && this.props.surveyAnswers.TotalCount !=undefined?this.props.surveyAnswers.TotalCount:null
        const currentPage = this.props.surveyAnswers!=undefined && this.props.surveyAnswers.TotalCount !=undefined?this.props.surveyAnswers.CurrentPage:null
        const pageSize = this.state.pageSize;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        var surveysMenuItems = null;

        if (this.props.surveysLoaded){
            surveysMenuItems = this.getSurveysMenuitems();
        }

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Surveys</Typography>
                </div>           

                
                {this.props.surveysLoaded  &&
                <div className={"results"}>      
                    <FormControl variant="outlined">
                        <InputLabel>Survey</InputLabel>
                        <Select
                            className={'branchSelect'}
                            label="Survey"
                            value={this.state.survey}
                            onChange={this.handleSurveyChange}
                        >
                        {surveysMenuItems}
                        </Select>
                    </FormControl>
                    {this.state.exportingToCsv &&        
                        <CircularProgress color="secondary" />
                    }

                    <MaterialTable
                        title={'Surveys'}     
                        icons={tableIcons}           
                        columns={this.buildColumnData()}
                        actions={[
                            {
                              icon: ExportIcon,
                              tooltip: 'Export to CSV',
                              isFreeAction: true,
                              onClick: (event) => this.exportCurrentSurvey()
                            }
                          ]}
                        data={query=>
                            new Promise((resolve,reject)=>{
                                
                                let sortBy = query.orderBy;
                                let sortDirection = query.orderDirection;
                                sortBy = sortBy == undefined?"Id":sortBy.field;
                                sortDirection = sortDirection == undefined||sortDirection==""?"asc":sortDirection;
                                
                                if (this.state.survey!=undefined && this.state.survey!=""){
                                    let url = host+`/admin/survey-answers/list/${this.state.survey}?page=${query.page}&take=${query.pageSize}&sortBy=${sortBy}&SortDirection=${sortDirection}&search=${query.search}`;
                                    
                                    fetch(url,
                                        {
                                            method: "GET",
                                            headers: {
                                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                                            }
                                        }
                                        ).then(resp=>resp.json()).then(resp=>{
                                            
                                            this.setState({
                                                query:query,
                                                pageSize:resp.PageSize
                                            });
                                            
                                        resolve({
                                            data:this.buildObjectFromArray(resp.Data),
                                            page:resp.CurrentPage,
                                            totalCount: resp.TotalCount,
                                            pageSize:resp.PageSize
                                        });
                                    })
                                }else{
                                    resolve({
                                        data:[],
                                        page:0,
                                        totalCount: 0
                                    });
                                }
                            
                            })
                        
                        }
                        onChangeRowsPerPage={this.onRowsPerPageChange}
                        onChangePage= { this.onPageChange}
                        options={{
                            pageSize:this.state.pageSize,
                            pageSizeOptions: [5,10,25,50,100,1000,10000],
                            columnsButton:true,
                            grouping:true
                        }}
                    />
                </div>
                }                  
                
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,        
        userProfile: state.userReducer.userProfile,    
        surveyAnswers: state.surveysReducer.surveyAnswers,
        searchingForSurveyAnswers: state.surveysReducer.searchingForSurveyAnswers,
        pageSize: state.surveysReducer.pageSize,
        page: state.surveysReducer.page,
        surveysLoaded:state.surveysReducer.surveysLoaded,
        surveys:state.surveysReducer.surveys,
        surveysLoading:state.surveysReducer.surveysLoading
        }),        
    dispatch => ({
        getSurveys: (userProfile) => dispatch(getSurveys(userProfile)),
    })
)(SurveysPage);