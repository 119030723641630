import {
    ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS,
    FAILED_SERIAL_SERVICE,
    GET_SERIAL_NUMBER_LOADING,
    GET_SERIAL_NUMBER_SUCCESS, UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS,
} from "../actions/serialNumberActions";


const initialState = {
    serialNumberData: {
        events: []
    },
    loadingSerialNumberData: false,
};

export default function serialNumberReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SERIAL_NUMBER_LOADING:
            return {
                ...state,
                loadingSerialNumberData: true,
            };
        case GET_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                serialNumberData: action.data,
                loadingSerialNumberData: false,
            };
        case ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                serialNumberData: action.data.serialNumberData
            };
        case UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                serialNumberData: action.data.serialNumberData
            };
        case FAILED_SERIAL_SERVICE:
            return {
                ...state,
                loadingSerialNumberData: false
            }

        default:
            return state
    }
}