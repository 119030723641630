import {
    GET_B2B_ORDERS_SUCCESS,
    GET_B2B_ORDERS_LOADING,
    GET_B2B_CUSTOMERS_LOADING,
    GET_B2B_CUSTOMERS_SUCCESS,
    CREATE_B2B_CUSTOMER_SUCCESS,
    UPDATE_B2B_CUSTOMER_SUCCESS,
    GET_B2B_PRICING_OPTIONS_SUCCESS,
    SINGLE_ORDER_DETAILS_LOADING,
    GET_SINGLE_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER_STATUS_SUCCESS
} from "../actions/businessAdminActions";

const initialState = {
    orders: [],
    orderDetails: {},
    customers: [],
    pricingOptions: [],
    loadingPricingOptions: false,
    loadingOrders: false,
    loadingCustomers: false,
    orderDetailsLoading: false
};

export default function businessAdminReducer(state = initialState, action) {
    switch (action.type) {
        case GET_B2B_ORDERS_LOADING:
            return {
                ...state,
                loadingOrders: true,
            };
        case GET_B2B_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.data,
                loadingOrders: false,
            };
        case GET_B2B_CUSTOMERS_LOADING:
            return {
                ...state,
                loadingCustomers: true
            };
        case GET_B2B_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.data,
                loadingCustomers: false
            };
        case CREATE_B2B_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: action.data,
                loadingCustomers: false
            };
        case UPDATE_B2B_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: action.data,
                loadingCustomers: false,
            };
        case GET_B2B_PRICING_OPTIONS_SUCCESS:
            return {
                ...state,
                pricingOptions: action.data,
                loadingPricingOptions: false
            };
        case SINGLE_ORDER_DETAILS_LOADING:
            return {
                ...state,
                orderDetailsLoading: true
            };
        case GET_SINGLE_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                orderDetails: action.data,
                orderDetailsLoading: false
            };
        case UPDATE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                orderDetailsLoading: false,
                orderDetails: action.data
            };
        default:
            return state
    }
}