import React, { Component } from "react";
import PropTypes, { number } from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";


import { updateLoyaltyCustomerPoints, loadLoyaltyCustomerPoints } from '../../../../redux/actions/loyaltyPointsActions';
import './CustomerPoints.css';
import { CircularProgress } from "@material-ui/core";

//const host = 'http://localhost:3000';
//const host = 'https://68c039285619.ngrok.io';

class CustomerPoints extends Component {

  constructor(props) {
    super(props);
    this.state = {
      workInProgress: false,
      workStarted: false,
      customer: '',
      customerPoints: 0,
    }
  }

  static propTypes = {
    authorized: PropTypes.bool,
    roles: PropTypes.array,
    userProfile: PropTypes.object,
    data: PropTypes.object,
    loading: PropTypes.bool
  };

  setCustomerPoints = (e) => {
    this.setState({ customerPoints: e.target.value })
  }

  setCustomer = (e) => {
    this.setState({ customer: e.target.value })
  }

  search = () => {
    this.setState({ workStarted: true })
    this.props.loadLoyaltyCustomerPoints(`${this.state.customer}`).then(() => {
      this.setState({ customerPoints: this.props.data.net_points_earned });
    })
  }

  updatePoints = () => {
    this.props.updateLoyaltyCustomerPoints(JSON.stringify({ id: this.props.data._id, newPoint: this.state.customerPoints, oldBody: this.props.data }))
  }

  renderCustomer = (customer) => {
    const { first_name, last_name, email } = customer
    return <div className="customer-row">
      <div className="name">{`${first_name} ${last_name}`}</div>
      <div className="email">{email}</div>
      <input type="number" id="tentacles" name="tentacles" value={this.state.customerPoints} onChange={this.setCustomerPoints} />
      <Button className={'searchOrderButton'} variant="contained" onClick={this.updatePoints} color="secondary">Update Point</Button>
    </div>
  }


  render() {
    if (!this.props.authorized) {
      this.props.history.push('/');
    }

    if (this.props.roles == undefined ||
      !this.props.roles.includes('admin')
    ) {
      this.props.history.push('/');
    }

    return (
      <div className="page-container">
        <div className={'title'}>
          <Typography variant='h6'>Update Customer's points</Typography>
        </div>
        {!this.state.workInProgress &&
          <Card className="card-container">
            <Typography className={'searchTitle'}></Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <TextField id="outlined-basic" label='Search' onChange={this.setCustomer} style={{ width: 400 }} />
            </div>
            <br />
            <Button className={'searchOrderButton'} variant="contained" onClick={this.search} color="secondary">Search Customer</Button>
          </Card>
        }

        {!this.props.loading && this.state.workStarted &&
          <Card className="card-container">
            {!this.props.error &&
              <Typography className={'searchTitle'}>{this.renderCustomer(this.props.data)}</Typography>
            }
            {this.props.error &&
              <Typography className={'searchTitle'}>{this.props.errorMessage}</Typography>
            }
          </Card>
        }

        {
          this.props.loading &&
          <Card className="card-container">
            <CircularProgress />
          </Card>
        }

      </div>
    );
  }

}

export default connect(
  state => ({
    authorized: state.userReducer.authorized,
    roles: state.userReducer.roles,
    userProfile: state.userReducer.userProfile,
    loading: state.loyaltyPointsReducer.loading,
    error: state.loyaltyPointsReducer.error,
    errorMessage: state.loyaltyPointsReducer.errorMessage,
    data: state.loyaltyPointsReducer.customerPointData,
  }),
  dispatch => ({
    loadLoyaltyCustomerPoints: (customer) => dispatch(loadLoyaltyCustomerPoints(customer)),
    updateLoyaltyCustomerPoints: (customer) => dispatch(updateLoyaltyCustomerPoints(customer)),
  })
)(CustomerPoints);
