import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import { getEtdSkuReport } from '../../redux/actions/inventoryActions';
import {connect} from "react-redux";
import PropTypes from "prop-types";
const _ = require('lodash/function');
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class Inventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sku: '',
            qty: 0,
            branch: 0
        }
    }

    static propTypes = {
        etdReport: PropTypes.object
    };

    renderLoadingBar = () => {
        if(this.props.loadingEtdReport) {
            return(
                <CircularProgress color="secondary" />
            );
        }
    };

    updateSKUText = (event) => {
        this.setState({
            sku: event.target.value
        });
    };

    updateQty = (event) => {
        this.setState({
            qty: event.target.value
        });
    };

    handleBranchChange = (event) => {
        this.setState({
            branch: event.target.value
        });
    };

    grabETDSKUReportData = () => {
        let {sku, qty, branch} = this.state;
        if(branch === '') {
            branch = 0;
        }
        if(sku === ''){
            sku = 'none';
        }
        console.log(`${sku} ${qty} ${branch}`);
        this.props.getEtdSkuReport(sku, qty, branch);
    };

    renderSKUForm = () => {
        return (
            <div>
                <TextField
                    id="outlined-basic"
                    label="SKU"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.updateSKUText}
                />
                {' '}
                <TextField
                    id="outlined-number"
                    label="Number"
                    type="Number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.updateQty}
                />
                {' '}
                <FormControl variant="outlined">
                    <InputLabel>Branch</InputLabel>
                    <Select
                        className={'branchSelect'}
                        label="Branch"
                        value={this.state.branch}
                        onChange={this.handleBranchChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={3}>Rakuten USA</MenuItem>
                        <MenuItem value={30}>Factory CN</MenuItem>
                        <MenuItem value={134}>Norman HKG</MenuItem>
                    </Select>
                </FormControl>
                {' '}
                <Button className={'searchOrderButton'} variant="contained" color="primary" onClick={this.grabETDSKUReportData}>Search</Button>
            </div>
        )
    };

    renderCurrentAvailability = () => {
        const stockData = this.props.etdReport.skuStockData;
        return (
            <div className={'reportDiv'}>
                <Typography variant='h6' >Availability by Branch</Typography>
                <Card className={'reportTable'}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Branch</TableCell>
                                <TableCell align="right">Available</TableCell>
                                <TableCell align="right">Stock on Hand</TableCell>
                                <TableCell align="right">Incoming</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stockData.map(row => (
                                <TableRow key={row.branchName}>
                                    <TableCell component="th" scope="row">
                                        {row.branchName}
                                    </TableCell>
                                    <TableCell align="right">{row.available}</TableCell>
                                    <TableCell align="right">{row.stockOnHand}</TableCell>
                                    <TableCell align="right">{row.incoming}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Card>
            </div>
        )
    };

    grabQty = (lineItems) => {
        let qty = 0;
        lineItems !== undefined && lineItems.map(lineItem => {
            if(lineItem.code.toUpperCase() === this.state.sku.toUpperCase()) {
                qty = lineItem.qty;
            }
        });
        return qty;
    };

    grabQtyRec = (lineItems) => {
        let qty = 0;
        lineItems !== undefined && lineItems.map(lineItem => {
            if(lineItem.code.toUpperCase() === this.state.sku.toUpperCase()) {
                qty = lineItem.qtyShipped;
            }
        });
        return qty;
    };

    grabTotalQty = (orders) => {
        let totalQty = 0;
        orders !== undefined && orders.map(order => {
            totalQty += this.grabQty(order.lineItems);
        });
        return totalQty;
    };

    grabTotalRecQty = (orders) => {
        let totalQty = 0;
        orders !== undefined && orders.map(order => {
            totalQty += this.grabQtyRec(order.lineItems);
        });
        return totalQty;
    };

    grabBranchQty = (rowId, branchId, lineItems) => {
        if(rowId === branchId) {
            return this.grabQty(lineItems);
        }
    };


    renderPOTable = () => {
        const purchaseOrders = this.props.etdReport.purchaseOrders;
        return (
            <div className={'reportDiv'}>
                <Typography variant='h6' >Purchase Order Report</Typography>
                <Card className={'reportTable'}>
                    <Table aria-label="Purchase Order table">
                        <TableHead>
                            <TableRow>
                                <TableCell>PO Reference #</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell align="right">Stage</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">ETD</TableCell>
                                <TableCell align="right">Qty Ordered</TableCell>
                                <TableCell align="right">Qty Received</TableCell>
                                <TableCell align="right">Qty Incoming</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseOrders.map(row => (
                                <TableRow key={row.reference}>
                                    <TableCell component="th" scope="row">
                                        {row.reference}
                                    </TableCell>
                                    <TableCell>{row.company}</TableCell>
                                    <TableCell align="right">{row.stage}</TableCell>
                                    <TableCell align="right">{row.createdDate}</TableCell>
                                    <TableCell align="right">{row.estimatedDeliveryDate}</TableCell>
                                    <TableCell align="right">{this.grabQty(row.lineItems)}</TableCell>
                                    <TableCell align="right">{this.grabQtyRec(row.lineItems)}</TableCell>
                                    <TableCell align="right">{this.grabQty(row.lineItems)-this.grabQtyRec(row.lineItems)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell align="right" colSpan={5}>Total</TableCell>
                                <TableCell align="right"> {this.grabTotalQty(purchaseOrders)}</TableCell>
                                <TableCell align="right"> {this.grabTotalRecQty(purchaseOrders)}</TableCell>
                                <TableCell align="right"> {this.grabTotalQty(purchaseOrders)-this.grabTotalRecQty(purchaseOrders)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </div>
        )
    };

    renderSOTable = () => {
        const salesOrders = this.props.etdReport.salesOrders;
        return (
            <div className={'reportDiv'}>
                <Typography variant='h6' >Sales Order Report</Typography>
                <Card className={'reportTable'}>
                    <Table aria-label="Sales Order Table">
                        <TableHead>
                            <TableRow>
                                <TableCell>SO Reference #</TableCell>
                                <TableCell>Cust Order #</TableCell>
                                <TableCell align="right">Stage</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">ETD</TableCell>
                                <TableCell align="right">Qty Ordered</TableCell>
                                <TableCell align="right">Rakuten</TableCell>
                                <TableCell align="right">Norman</TableCell>
                                <TableCell align="right">China</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {salesOrders.map(row => (
                                <TableRow key={row.reference}>
                                    <TableCell component="th" scope="row">
                                        {row.reference}
                                    </TableCell>
                                    <TableCell>{row.customerOrderNo}</TableCell>
                                    <TableCell align="right">{row.stage}</TableCell>
                                    <TableCell align="right">{row.createdDate}</TableCell>
                                    <TableCell align="right">{row.estimatedDeliveryDate}</TableCell>
                                    <TableCell align="right">{this.grabQty(row.lineItems)}</TableCell>
                                    <TableCell align="right">{this.grabBranchQty(3, row.branchId, row.lineItems)}</TableCell>
                                    <TableCell align="right">{this.grabBranchQty(134, row.branchId, row.lineItems)}</TableCell>
                                    <TableCell align="right">{this.grabBranchQty(30, row.branchId, row.lineItems)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Card>
                <div className={'totalCounter'}>Total: {this.grabTotalQty(salesOrders)}</div>
            </div>
        )
    };


    renderETDResults = () => {
        if(this.props.etdReport.etd !== '') {
            return (
                <div>
                </div>
            )
        }
    };


    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6' >ETD and SKU Report</Typography>
                </div>
                {this.renderSKUForm()}
                {this.renderETDResults()}
                {this.renderLoadingBar()}
                {this.renderCurrentAvailability()}
                {this.renderPOTable()}
                {this.renderSOTable()}
            </div>
        );
    }

}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        users: state.userReducer.users,
        stockData: state.inventoryReducer.stockData,
        etdReport: state.inventoryReducer.etdReport,
        loadingEtdReport: state.inventoryReducer.loadingEtdReport,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getEtdSkuReport: (sku, qty, branchId) => dispatch(getEtdSkuReport(sku, qty, branchId))
    })
)(Inventory);