import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { getAllUsers, updateProfile, setup2FA, complete2FASetup } from "../../redux/actions/userActions";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import LinearProgress from "@material-ui/core/LinearProgress";

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            role: '',
            password: '',
            twoFactorToken: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {userProfile} = this.props;
        this.setState({
            email: userProfile.email,
            name: userProfile.name,
            role: userProfile.roles !== undefined ? userProfile.roles[0] : '',
        })
    }

    static propTypes = {
        authorized: PropTypes.bool,
        getAllUsers: PropTypes.func,
        userProfile: PropTypes.object,
        faVerifyLoading: PropTypes.bool,
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        const {email, name, password, role} = this.state;
        let user_roles = [role];
        event.preventDefault();
        this.props.updateProfile(email, name, password, user_roles, this.props.roles.includes("admin"));
    }

    renderUserProfileCard = () => {
        const { roles } = this.props;
        const {create, email, password, name, role} = this.state;
        return (
            <Card className={'userProfileCard'}>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        fullWidth
                        value={email}
                        disabled={!roles.includes("admin")}
                        onChange={this.handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="name"
                        fullWidth
                        disabled={!roles.includes("admin")}
                        value={name}
                        onChange={this.handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        name="password"
                        label={create ? 'Password' : 'New Password'}
                        type="password"
                        fullWidth
                        value={password}
                        onChange={this.handleChange}
                    />
                    <FormControl className={'formControlSelect'}>
                        <InputLabel>Role</InputLabel>
                    <Select
                        id="role-select"
                        name="role"
                        value={role}
                        disabled={!roles.includes("admin")}
                        onChange={this.handleChange}
                    >
                        { roles.includes("admin") && <MenuItem value={'admin'}>Admin</MenuItem> }
                        { (roles.includes("cs_admin") || roles.includes("admin"))  && <MenuItem value={'cs_admin'}>CS Admin</MenuItem> }
                        { (roles.includes("logistics_admin") || roles.includes("admin"))  && <MenuItem value={'logistics_admin'}>Logistics Admin</MenuItem> }
                        { (roles.includes("b2b_admin") || roles.includes("admin"))  && <MenuItem value={'b2b_admin'}>B2B Admin</MenuItem> }
                        <MenuItem value={'tier1'}>Tier 1</MenuItem>
                        <MenuItem value={'tier2'}>Tier 2</MenuItem>
                        <MenuItem value={'tier3'}>Tier 3</MenuItem>
                    </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" type='submit' className={'updateUserProfButton'} onClick={this.handleSubmit}>Update</Button>
                </form>
            </Card>
        )
    };

    setup2FA = () => {
        this.props.setup2FA();
    };

    complete2FASetup = () => {
        this.props.complete2FASetup(this.state.twoFactorToken);
    };

    render2FACard = () => {
        const {qrCodeURL, faVerifyLoading, userProfile} = this.props;
        return (
            <div>
                <Typography className={'FATitle'} variant='h6'>Setup Two Factor Authentication</Typography>
                {faVerifyLoading && <LinearProgress className={'progress'} color="secondary" />}
                <Card className={'FACard'}>
                    { qrCodeURL === '' && userProfile !== undefined && !userProfile.factorAuthEnabled && <Button variant="contained" color="primary" onClick={this.setup2FA}>Setup 2FA</Button> }
                    { userProfile !== undefined && !userProfile.factorAuthEnabled && <img src={`${qrCodeURL}`} />}
                    { qrCodeURL !== '' && userProfile !== undefined && !userProfile.factorAuthEnabled &&
                        <div>
                            <TextField
                                margin="dense"
                                id="twoFactorToken"
                                name="twoFactorToken"
                                label='2FA Key Code'
                                type='number'
                                value={this.state.twoFactorToken}
                                onChange={this.handleChange}
                            />
                            <Button variant="contained" color="primary" className={'verifyButton'}  onClick={this.complete2FASetup}>Complete Verification</Button>
                        </div>
                    }
                    { userProfile !== undefined && userProfile.factorAuthEnabled && <div><Typography>You are enabled for 2FA!</Typography></div>}
                </Card>
            </div>
        )
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        return (
            <div className={'userProfileContainer'}>
                <div className={'title'}>
                    <Typography variant='h6' >Nexus Profile</Typography>
                </div>
                {this.renderUserProfileCard()}
                {this.render2FACard()}
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        users: state.userReducer.users,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        qrCodeURL: state.userReducer.qrCodeURL,
        faVerifyLoading: state.userReducer.faVerifyLoading
    }),
    dispatch => ({
        getAllUsers: () => dispatch(getAllUsers()),
        updateProfile: (email, name, password, role, admin) => dispatch(updateProfile(email, name, password, role, admin)),
        setup2FA: () => dispatch(setup2FA()),
        complete2FASetup: (twoFactorToken) => dispatch(complete2FASetup(twoFactorToken))
    })
)(UserProfile);