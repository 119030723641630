import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import UploadIcon from '@material-ui/icons/CloudUpload';
import CakeIcon from '@material-ui/icons/Cake';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import ShippingIcon from "@material-ui/icons/LocalShipping";
import BusinessIcon from '@material-ui/icons/CardTravel';
import SKUReportIcon from '@material-ui/icons/Assignment';
import GlobeIcon from '@material-ui/icons/Public'
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem button key={'homePage'}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                    <Link to='/home'>
                        <ListItemText primary='Home Page' />
                    </Link>
                </ListItem>
                <ListItem button key={'orderPage'}>
                        <ListItemIcon><ReceiptIcon /></ListItemIcon>
                    <Link to='/orders'>
                        <ListItemText primary='Order Page' />
                    </Link>
                </ListItem>
                <ListItem button key={'inventoryPage'}>
                    <ListItemIcon><ShippingIcon /></ListItemIcon>
                    <Link to='/inventory'>
                        <ListItemText primary='Inventory Status' />
                    </Link>
                </ListItem>
                <ListItem button key={'serialNumberPage'}>
                    <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                    <Link to='/serialnumber'>
                        <ListItemText primary='Serial Number Search' />
                    </Link>
                </ListItem>
                <ListItem button key={'etdPage'}>
                    <ListItemIcon><SKUReportIcon /></ListItemIcon>
                    <Link to='/etd'>
                        <ListItemText primary='ETD & SKU Report' />
                    </Link>
                </ListItem>
                <ListItem button key={'distributorsPage'}>
                    <ListItemIcon><GlobeIcon /></ListItemIcon>
                    <Link to='/distributors'>
                        <ListItemText primary='Distributor Database' />
                    </Link>
                </ListItem>
                <ListItem button key={'b2bAdminPage'}>
                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                    <Link to='/b2b'>
                        <ListItemText primary='B2B Admin' />
                    </Link>
                </ListItem>
                <ListItem button key={'userPage'}>
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                    <Link to='/users'>
                        <ListItemText primary='Users Page' />
                    </Link>
                </ListItem>

                <ListItem button key={'product-registrations'}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <Link to='/product-registrations'>
                        <ListItemText primary='Product Registrations' />
                    </Link>
                </ListItem>

                <ListItem button key={'surveys'}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <Link to='/surveys'>
                        <ListItemText primary='Surveys' />
                    </Link>
                </ListItem>

                <ListItem button key={'delete-customer-data'}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <Link to='/delete-customer-data'>
                        <ListItemText primary='Delete Customer Data' />
                    </Link>
                </ListItem>

                <ListItem button key={'software-downloads'}>
                    <ListItemIcon><UploadIcon /></ListItemIcon>
                    <Link to='/software-downloads'>
                        <ListItemText primary='Software Downloads' />
                    </Link>
                </ListItem>

                <ListItem button key={'software-downloads'}>
                    <ListItemIcon><CakeIcon /></ListItemIcon>
                    <Link to='/loyalty'>
                        <ListItemText primary='Loyalty' />
                    </Link>
                </ListItem>

                <ListItem button key={'userProfilePage'}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <Link to='/users/profile'>
                        <ListItemText primary='Your User Profile' />
                    </Link>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
                <MenuIcon />
            </IconButton>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                {sideList('left')}
            </Drawer>
        </div>
    );
}
