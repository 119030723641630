import { combineReducers } from 'redux';
import verificationReducer from './verificationReducer';
import inventoryReducer from "./inventoryReducer";
import userReducer from "./userReducer";
import distributorReducer from './distributorReducer';
import businessAdminReducer from "./businessAdminReducer";
import snackbarReducer from "./snackbarReducer";
import serialNumberReducer from "./serialNumberReducer";
import productLaunchReducer from "./productLaunchReducer";
import productRegistrationsReducer from "./productRegistrationsReducer";
import deleteCustomerDataReducer from "./deleteCustomerDataReducer";
import surveysReducer from "./surveysReducer";
import orderManifestReducer from "./orderManifestReducer";
import softwareDownloadsReducer from "./softwareDownloadsReducer";
import loyaltyPointsReducer from "./loyaltyPointsReducer";

export default combineReducers({
    verificationReducer,
    userReducer,
    inventoryReducer,
    distributorReducer,
    businessAdminReducer,
    snackbarReducer,
    serialNumberReducer,
    productLaunchReducer,
    productRegistrationsReducer,
    deleteCustomerDataReducer,
    surveysReducer,
    orderManifestReducer    ,
    softwareDownloadsReducer,
    loyaltyPointsReducer
});
