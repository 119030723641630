import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Card, TableHead} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "react-router-dom";
import ReceiptIcon from '@material-ui/icons/Receipt';

class B2BCustomerDetailsPanel extends Component {

    static propTypes = {
        customerData: PropTypes.object,
        editB2BUserDetails: PropTypes.func
    };

    static defaultProps = {
        customerData: {}
    };

    render() {
        const {customerData} = this.props;
        const orderLink = '/b2b/orders/' + customerData.email;
        return (
            <div className={'userContainer'}>
                <Card className={'userCard'}>
                    <IconButton color="secondary" className={'editIcon'} aria-label="edit order" onClick={() => this.props.editB2BUserDetails(customerData)}>
                        <EditIcon />
                    </IconButton>
                    <Typography className={'textSpacing'} variant='h3'>{customerData.name}</Typography>
                    <Typography className={'textSpacing'} variant='h6'>{customerData.email}</Typography>
                    <Typography><b>Address:</b> {customerData.address}</Typography>
                    <Typography><b>Branch:</b> {customerData.branch}</Typography>
                    <Typography><b>Pricing Tier:</b> {customerData.pricingTier}</Typography>
                    <br />
                    <Link to={orderLink}>
                        <IconButton color="secondary"  aria-label="Order Details Page">
                            <ReceiptIcon />
                        </IconButton>
                    </Link>
                </Card>
            </div>
        );
    }
}

export default withRouter(connect(
    state => ({
    }),
    dispatch => ({
    })
)(B2BCustomerDetailsPanel));