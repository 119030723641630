import React, { useState } from 'react'

import LoyaltyPointsPage from './LoyaltyPoints/LoyaltyPointsPage';

import "./LoyaltyPages.css"
import CustomerPoints from './LoyaltyPoints/CustomerPoints/CustomerPoints';

export default function LoyaltyPages(props) {
  const [pageType, setPageType] = useState('productPoints')

  const pages = {
    productPoints: {component: <LoyaltyPointsPage {...props}/>, title: 'Product Points'},
    updateCustomerPoints: {component: <CustomerPoints {...props}/>, title: 'Customer Points'},
  }
  return (
    <div>
      <div className='tabs-container'>
        {Object.keys(pages).map(page => <div className={`button ${pageType === page ? 'active': ''}`} onClick={() => {setPageType(page)}}>{pages[page].title}</div>)}
      </div>
      {pages[pageType ?? 'productPoints'].component}
    </div>
  )
}
