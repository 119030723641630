import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {Card} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class B2BOrderSummaryCard extends Component {

    static propTypes = {
        orderData: PropTypes.object,
        expandOrderDetails: PropTypes.func
    };

    static defaultProps = {
        orderData: {}
    };

    render() {
        const {orderData} = this.props;

        return (
            <div className={'userContainer'}>
                <Card className={'userCard'}  onClick={() => this.props.expandOrderDetails(orderData.orderReference)}>
                    <Typography className={'textSpacing'} variant='h6'>Order# {orderData.orderReference}</Typography>
                    <Typography className={'textSpacing'} variant='h6'>{orderData.email}</Typography>
                    <Typography>{orderData.date}</Typography>
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
    }),
    dispatch => ({
    })
)(B2BOrderSummaryCard);