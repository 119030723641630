import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { getAllB2BOrders, getB2BOrdersForAccount, getSingleOrderData, searchB2BOrders } from '../../redux/actions/businessAdminActions';
import Grid from "@material-ui/core/Grid";
import B2BOrderSummaryCard from "./B2BOrderSummaryCard";
import B2BOrderDetailsPanel from "./B2BOrderDetailsPanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

class B2BOrdersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 'all',
            email: '',
            searchText: '',
            stage: 'All'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        authorized: PropTypes.bool,
        getAllB2BOrders: PropTypes.func,
        getB2BOrdersForAccount: PropTypes.func,
        orders: PropTypes.array,
        orderDetails: PropTypes.object,
        orderDetailsLoading: PropTypes.bool,
        roles: PropTypes.array,
        searchB2BOrders: PropTypes.func
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        if(id === 'all') {
            this.props.getAllB2BOrders();
        } else {
            this.setState({email: id });
            this.props.getB2BOrdersForAccount(id);
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    expandOrderDetails = (referenceNumber) => {
        this.props.getSingleOrderData(referenceNumber);
    };

    viewAccountOrders = (email) => {
        this.setState({email: email});
        this.props.getB2BOrdersForAccount(email);
    };

    renderOrders = () => {
        return this.props.orders.map(order => {
            return (<B2BOrderSummaryCard orderData={order} expandOrderDetails={this.expandOrderDetails} />);
        })
    };

    renderOrderDetails = () => {
        const {orderDetails} = this.props;
        if(orderDetails !== undefined) {
            return (<B2BOrderDetailsPanel orderData={orderDetails} viewAccountOrders={this.viewAccountOrders}/>);
        }
    };

    searchOrders = () => {
        const {searchText, stage} = this.state;
        let searchParam = searchText !== '' ? searchText : 'any';
        this.props.searchB2BOrders(searchParam, stage);
    };

    renderFilterSection = () => {
        return (
          <div>
              <TextField
                  margin="dense"
                  id="search"
                  label="Search Orders"
                  name="searchText"
                  type="search"
                  value={this.state.searchText}
                  onChange={this.handleChange}
              />
              <FormControl className={'formControlSelect'}>
                  <InputLabel>Stage</InputLabel>
                  <Select
                      id="role-select"
                      name="stage"
                      onChange={this.handleChange}
                      value={this.state.stage}
                  >
                      <MenuItem value={'All'}>All</MenuItem>
                      <MenuItem value={'New'}>New</MenuItem>
                      <MenuItem value={'Processing'}>Processing</MenuItem>
                      <MenuItem value={'Dispatched'}>Dispatched</MenuItem>
                      <MenuItem value={'Fully Paid'}>Fully Paid</MenuItem>
                  </Select>
              </FormControl>
              <Button className={'searchOrderButton'} variant="contained" color="primary" onClick={this.searchOrders}>Search</Button>
          </div>
        )
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        const { orderDetailsLoading } = this.props;
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h3' >Glorious B2B Orders</Typography>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderFilterSection()}
                        {this.renderOrders()}
                    </Grid>
                    <Grid item xs={6}>
                        {orderDetailsLoading &&  <CircularProgress className={'loadingCircle'} color="secondary" />}
                        {this.renderOrderDetails()}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        orders: state.businessAdminReducer.orders,
        orderDetails: state.businessAdminReducer.orderDetails,
        orderDetailsLoading: state.businessAdminReducer.orderDetailsLoading,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getAllB2BOrders: () => dispatch(getAllB2BOrders()),
        getB2BOrdersForAccount: (id) => dispatch(getB2BOrdersForAccount(id)),
        getSingleOrderData: (referenceNumber) => dispatch(getSingleOrderData(referenceNumber)),
        searchB2BOrders: (searchText, stage) => dispatch(searchB2BOrders(searchText, stage))
    })
)(B2BOrdersView));