import React, {Component} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import ProductLaunchTable from "./ProductLaunchTable";
import UpcomingProductLaunchesView from "./UpcomingProductLaunchesView";


class ProductLaunchHome extends Component {

    render() {
        return(
            <div>
                <UpcomingProductLaunchesView />
                <ProductLaunchTable />
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({

    })
)(ProductLaunchHome);