import React, {Component} from "react";
import PropTypes, { number } from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";

import MaterialTable from 'material-table';
import ExportIcon from '@material-ui/icons/CloudDownload';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {CircularProgress} from "@material-ui/core";
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LinearProgress from "@material-ui/core/LinearProgress";

import {  getAllPoints,    createLoyaltyProduct,    deleteLoyaltyProduct, updateLoyaltyProduct} from '../../../redux/actions/loyaltyPointsActions';
import { $CombinedState } from "redux";
import './LoyaltyPoints.css';

//const host = 'http://localhost:3000';
const host = 'https://api.gpcgr.com';
//const host = 'https://68c039285619.ngrok.io';

class LoyaltyPointsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerEmail:null,
            workStarted:false,
            reload:0,
            allData:[],
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array,
        userProfile: PropTypes.object,
        data:PropTypes.array,
        loading:PropTypes.bool,
        uploadStatus:number
    };

    componentWillReceiveProps = (nextProps) => {
        var reload = this.state.reload;
        if (this.props.uploadStatus<300 && nextProps.uploadStatus==300){
            this.props.getAllSoftwareDownloads();
        }
        if (nextProps.data){
            this.setState(prevState => ({
                allData: [...nextProps.data],
                reload:reload+1
              }));
        }else{
            this.setState(prevState => ({
                reload:reload+1
              }));
        }

    }
    componentDidMount() {
        this.props.getAllPoints();
    }
    buildColumnData = ()=>{
        let columns = [];
        const getLocalDate = date =>{
            return (new Date(date)).toLocaleDateString();
        };
        const getLocalDateTime = date =>{
            return (new Date(date)).toLocaleString();
        };
        columns.push(
            { title: 'Name', width:"25%", field: 'name' },
            { title: 'Skus', width:"65%", field: 'skus' },
            { title: 'Points',  width:"15%",field: 'point_value'},
        );

        return columns;
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }

        if (this.props.roles==undefined ||
            !this.props.roles.includes('admin')
        ) {
            this.props.history.push('/');
        }

        //console.log(this.state.reload);

        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Loyalty Points</Typography>
                </div>


                     <div className={"results loyalty-products-page"}>


                 <MaterialTable
                 title={'Loyalty points'}
                 icons={tableIcons}
                 columns={this.buildColumnData()}
                 data={this.state.allData}
                 options={{
                     exportButton: true,
                     exportAllData: true,
                     pageSizeOptions: [5,20,50,100],
                     columnsButton:true,
                     grouping:true
                 }}
                 editable={{
                    onRowAdd: (newData) => {
                        return new Promise((resolve, reject) => {
                        var payload = {
                            name:newData.name,
                            point_value:newData.point_value,
                        };
                        if (newData.skus){
                            if (Array.isArray(newData.skus)){
                                payload.skus =  newData.skus;
                            }else{
                                payload.skus =  newData.skus.split(',').map(s=>s.trim());
                            }
                        }else{
                            payload.skus = [];
                        }

                        let url = host+'/loyalty/products/create';
                        fetch(url,
                            {
                                method: "POST",
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type':'application/json'
                                },
                                body:JSON.stringify(payload)
                            }
                            ).then(resp=>
                                {
                                    this.props.getAllPoints();
                                    resolve();
                                }
                                ).
                            catch(e=>reject(e));
                        })
                    },

                    onRowUpdate: (newData, oldData) => {

                            // this.props.updateLoyaltyProduct(payload);
                          return new Promise((resolve, reject) => {

                            var payload = {
                                name:newData.name,
                                point_value:newData.point_value,

                                id:newData._id
                            };
                            if (newData.skus){
                                if (Array.isArray(newData.skus)){
                                    payload.skus =  newData.skus;
                                }else{
                                    payload.skus =  newData.skus.split(',').map(s=>s.trim());
                                }
                            }


                            let url = host+'/loyalty/products/update';
                            fetch(url,
                                {
                                    method: "PUT",
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                        'Content-Type':'application/json'
                                    },
                                    body:JSON.stringify(payload)
                                }
                                ).then(resp=>
                                    {
                                        this.props.getAllPoints();
                                        resolve();
                                    }
                                    ).
                                catch(e=>reject(e));
                            })
                        },
                    onRowDelete: (oldData) => {
                          return new Promise((resolve, reject) => {
                            let url = host+'/loyalty/products/delete/'+oldData._id;
                            fetch(url,
                                {
                                    method: "DELETE",
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                                    },
                                }
                                ).then(resp=>
                                    {
                                        this.props.getAllPoints();
                                        resolve();
                                    }
                                    ).
                                catch(e=>reject(e));
                            })
                        },
                      }}
                 />

                 </div>


            </div>
        );
    }

}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        data: state.loyaltyPointsReducer.data,
        total:state.loyaltyPointsReducer.total,
        loading:state.loyaltyPointsReducer.loading,
    }),
    dispatch => ({
         getAllPoints: () => dispatch(getAllPoints()),
         createLoyaltyProduct: (product) => dispatch(createLoyaltyProduct(product)),
         deleteLoyaltyProduct: (product) => dispatch(deleteLoyaltyProduct(product)),
         updateLoyaltyProduct: (product) => dispatch(updateLoyaltyProduct(product)),
    })
)(LoyaltyPointsPage);
