import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {Card} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {deleteUser, resetPasswordForUser} from "../../redux/actions/userActions";

class UserCard extends Component {

    static propTypes = {
        editUserDetails: PropTypes.func,
        deleteUser: PropTypes.func,
        userData: PropTypes.object,
    };

    static defaultProps = {
        userData: {}
    };

    deleteUser = () => {
        this.props.deleteUser(this.props.userData.email);
    };

    unlockUser = () => {
        this.props.resetPasswordForUser(this.props.userData.email);
    };

    render() {
        const {userData, editUserDetails, roles} = this.props;

        return (
            <div className={'userContainer'}>
                <Card className={'userCard'}>
                    <IconButton color="secondary" className={'editIcon'} aria-label="delete user" onClick={this.deleteUser}>
                        <DeleteIcon />
                    </IconButton>
                    { (roles.includes("admin") || roles.includes("cs_admin")) &&
                        <IconButton color="secondary" className={'editIcon'} aria-label="unlock user" onClick={this.unlockUser}>
                            <LockOpenIcon />
                        </IconButton> }
                    { (roles.includes("admin")
                        || roles.includes("cs_admin")) && <IconButton color="secondary" className={'editIcon'} aria-label="edit user" onClick={() => editUserDetails(userData)}>
                        <EditIcon />
                    </IconButton> }
                    <Typography className={'textSpacing'} variant='h6'>{userData.name}</Typography>
                    <Typography>{userData.email}
                    {userData.lockAccount &&
                    <Chip className={'chipStyle'} label={'locked'} color="error" />
                    }
                    {userData.passwordReset &&
                    <Chip className={'chipStyle'} label={'password reset'} color="warning" />
                    }
                    </Typography>
                    {userData.roles.map(role => {
                        return <Chip className={'chipStyle'} label={role} color="secondary" />
                    })}
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
        loadingShopify: state.verificationReducer.loadingShopify,
        loadingRSL: state.verificationReducer.loadingRSL,
        loadingCIN: state.verificationReducer.loadingCIN,
        roles: state.userReducer.roles
    }),
    dispatch => ({
        deleteUser: (email) => dispatch(deleteUser(email)),
        resetPasswordForUser: (email) => dispatch(resetPasswordForUser(email))
    })
)(UserCard);
