import {
    GET_INVENTORY_REPORT_SUCCESS,
    GET_INVENTORY_REPORT_LOADING,
    GET_INVENTORY_REPORT_COMPLETE,
    GET_ETD_SKU_REPORT_SUCCESS,
    GET_ETD_SKU_REPORT_COMPLETE,
    GET_ETD_SKU_REPORT_LOADING,
    UPDATE_NOTES_FOR_BRANCH
} from "../actions/inventoryActions";

const initialState = {
    stockData: [],
    loadingInventory: false,
    etdReport: {
        skuStockData: [],
        purchaseOrders: [],
        salesOrders: [],
        etd: ''
    },
};

export default function inventoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_INVENTORY_REPORT_SUCCESS:
            return {
                ...state,
                stockData: action.data
            };
        case UPDATE_NOTES_FOR_BRANCH:
            return {
                ...state,
                stockData: action.data.stock
            };
        case GET_INVENTORY_REPORT_LOADING:
            return {
                ...state,
                loadingInventory: true
            };
        case GET_INVENTORY_REPORT_COMPLETE:
            return {
                ...state,
                loadingInventory: false
            };
        case GET_ETD_SKU_REPORT_LOADING:
            return {
                ...state,
                loadingEtdReport: true,
            };
        case GET_ETD_SKU_REPORT_SUCCESS:
            return {
                ...state,
                etdReport: action.data
            };
        case GET_ETD_SKU_REPORT_COMPLETE:
            return {
                ...state,
                loadingEtdReport: false,
            };
        default:
            return state
    }
}