import React, {Component} from "react";
import PropTypes, { number } from "prop-types";
import { forwardRef } from 'react';
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";

import MaterialTable from 'material-table';
import ExportIcon from '@material-ui/icons/CloudDownload';
import UploadIcon from '@material-ui/icons/CloudUpload';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {CircularProgress} from "@material-ui/core";
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LinearProgress from "@material-ui/core/LinearProgress";

import {  getAllSoftwareDownloads,    createSoftwareDownload,    deleteSoftwareDownload} from '../../redux/actions/softwareDownloadsActions';
import { $CombinedState } from "redux";


const host = 'https://downloads.gloriousgamingservices.com';
//const host = 'https://68c039285619.ngrok.io';

class SoftwareDownloadsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerEmail:null,
            workStarted:false,
            reload:0,
            allData:[],
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array,
        userProfile: PropTypes.object,
        data:PropTypes.array,
        loading:PropTypes.bool,
        uploadStatus:number
    };
    uploadFile(e) {

        var files = e.target.files;
        if (files.length == 1) {
            this.props.createSoftwareDownload(files[0]);
        }else if (files.length>1){
            alert("you cannot upload multiple files at once.");
        }
    }
    uploadFileClicked(){
        document.getElementById("uploadFile").click();
    }
    componentWillReceiveProps = (nextProps) => {

        var reload = this.state.reload;
        if (this.props.uploadStatus<300 && nextProps.uploadStatus==300){
            this.props.getAllSoftwareDownloads();
        }

        if (nextProps.data){
            this.setState(prevState => ({
                allData: [...nextProps.data],
                reload:reload+1
              }));
        }else{
            this.setState(prevState => ({
                reload:reload+1
              }));
        }
    }
    componentDidMount() {
        this.props.getAllSoftwareDownloads();
    }
    buildColumnData = ()=>{
        let columns = [];
        const getLocalDate = date =>{
            return (new Date(date)).toLocaleDateString();
        };
        const getLocalDateTime = date =>{
            return (new Date(date)).toLocaleString();
        };
        columns.push(
            { title: 'File Name', width:"25%", field: 'name',render: rowData => (<a className="link" href={host+"/download/"+rowData.name}>{rowData.name}</a>) },
            { title: 'Url', width:"50%", field: 'name',render: rowData => host+"/download/"+rowData.name },
            { title: 'Last Updated',  width:"15%",field: 'lastModified', type:"datetime", render: rowData => getLocalDateTime(rowData.lastModified)},
            { title: 'Size (MB)', width:"10%", field: 'fileSize',render: rowData => rowData.fileSize.toFixed(2) },
        );

        return columns;
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }

        if (this.props.roles==undefined ||
            !this.props.roles.includes('admin')
        ) {
            this.props.history.push('/');
        }

        //console.log(this.state.reload);

        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6'>Software downloads</Typography>
                </div>


                     <div className={"results"}>
                     <input type="file" id="uploadFile" style={{display:"none"}} onChange={(e)=>this.uploadFile(e)}/>


                 <MaterialTable
                 title={
                    this.props.loading==true?
                    (    <h6 className="MuiTypography-root MuiTypography-h6"
                    >
                        Working on it ...
                        <CircularProgress size="16px" />
                        </h6>
                    )
                    :
                     'Software downloads' +(this.props.totalSize!=undefined?" ( total space "+this.props.totalSize.toFixed(2)+" MB)":"")
                    }
                 icons={tableIcons}
                 columns={this.buildColumnData()}
                 actions={[
                     {
                       icon: UploadIcon,
                       tooltip: 'Upload file',
                       isFreeAction: true,
                       onClick: (event) => this.uploadFileClicked()
                     }
                   ]}
                 data={this.state.allData}

                 options={{
                     exportButton: true,
                     exportAllData: true,
                     pageSizeOptions: [5,20,50,100],
                     columnsButton:true,
                     grouping:true
                 }}
                 editable={{


                     onRowDelete: (oldData) => {
                         return new Promise((resolve, reject) => {
                           let url = host+'/software-downloads/'+oldData.name;
                           fetch(url,
                               {
                                   method: "DELETE",
                                   headers: {
                                       'Authorization': 'Bearer ' + localStorage.getItem('token')
                                   },
                               }
                               ).then(resp=>{

                                var reload = this.state.reload;
                                var that = this;
                                setTimeout(function(){
                                    that.props.getAllSoftwareDownloads();
                                    that.setState({reload:reload+1});
                                 },500);

                                 resolve();
                               }
                                 ).
                               catch(e=>reject(e));
                           })
                       },
                   }}
                 />

                 </div>


            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        data: state.softwareDownloadsReducer.data,
        totalSize:state.softwareDownloadsReducer.totalSize,
        loading:state.softwareDownloadsReducer.softwareDownloadsLoading,
        uploadStatus:state.softwareDownloadsReducer.uploadStatus
    }),
    dispatch => ({
         getAllSoftwareDownloads: () => dispatch(getAllSoftwareDownloads()),
         createSoftwareDownload: (file) => dispatch(createSoftwareDownload(file)),
         deleteSoftwareDownload: (filename) => dispatch(deleteSoftwareDownload(filename)),
    })
)(SoftwareDownloadsPage);
