import React, {Component, forwardRef} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PropTypes from "prop-types";
import {
    getAllProductLaunchData,
    createProductLaunchEntry,
    updateProductLaunchInformation,
    updateNotificationList,
    deleteProductLaunchEntry
} from "../../redux/actions/productLaunchActions";

class ProductLaunchTable extends Component {

    static propTypes = {
        createProductLaunchEntry: PropTypes.func,
        getAllProductLaunchData: PropTypes.func,
        productLaunchData: PropTypes.array,
        updateNotificationList: PropTypes.func,
        updateProductLaunchInformation: PropTypes.func,
    };

    componentDidMount() {
        this.props.getAllProductLaunchData();
    }

    handleDBUpdate = (updatedProductLaunchData) => {
        this.props.updateProductLaunchInformation(updatedProductLaunchData);
    };

    handleDBDelete = (productLaunchEntry) => {
        this.props.deleteProductLaunchEntry(productLaunchEntry._id);
    };

    handleDBAdd = (newProductLaunchEntry) => {
        this.props.createProductLaunchEntry(newProductLaunchEntry);
    };

    buildColumnDataForRole = () => {
        let columns = [];
        columns.push(
            { title: 'SKU', field: 'sku', editable: 'always' },
            { title: 'ETD', field: 'etd', editable: 'always', type: 'date'},
            { title: 'ETA', field: 'eta', editable: 'always', type: 'date' },
            { title: 'Sold By B2B', field: 'b2b', editable: 'always', type: 'boolean' },
            { title: 'Privacy Rating', field: 'privacyRating', editable: 'always', lookup: { 1: 'Confidential', 2: 'Partial', 3: 'Open' } },
            { title: 'Release Type', field: 'releaseType', editable: 'always', lookup: { 1: 'Reservation', 2: 'Preorder', 3: 'Group Buy', 4: 'Regular Release'} },
            { title: 'Open Date', field: 'activityOpenDate', editable: 'always', type: 'date'},
            { title: 'Close Date', field: 'activityCloseDate', editable: 'always', type: 'date'},
            { title: 'Global Release', field: 'globalReleaseDate', editable: 'always', type: 'date'},
            { title: 'Last Updated', field: 'lastUpdatedTime', editable: 'never', type: 'datetime' },
        );
        return columns;
    };

    renderMaterialTable = () => {
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Notifications: forwardRef((props, ref) => <NotificationsIcon {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <MaterialTable
                title={'Product Launches'}
                icons={tableIcons}
                columns={this.buildColumnDataForRole()}
                data={this.props.productLaunchData}
                options={{
                    exportButton: true,
                    grouping: true,
                    pageSizeOptions: [5,10,15,20,50],
                    columnsButton:true
                }}
                actions={[
                    {
                        icon: NotificationsIcon,
                        tooltip: 'Subscribe to Notifications',
                        onClick: (event, rowData) => {
                            this.props.updateNotificationList(rowData._id);
                        }
                    }
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {{
                                    this.handleDBAdd(newData);
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {{
                                    this.handleDBDelete(oldData);
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {{
                                    this.handleDBUpdate(newData);
                                }
                                resolve()
                            }, 1000)
                        })
                }}
            />
        )
    };

    render() {
        return(
            <div>
                {this.renderMaterialTable()}
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile,
        productLaunchData: state.productLaunchReducer.productLaunchData
    }),
    dispatch => ({
        getAllProductLaunchData: () => dispatch(getAllProductLaunchData()),
        createProductLaunchEntry: (newProductLaunchEntry) => dispatch(createProductLaunchEntry(newProductLaunchEntry)),
        updateProductLaunchInformation: (newProductLaunchEntry) => dispatch(updateProductLaunchInformation(newProductLaunchEntry)),
        updateNotificationList: (_id) => dispatch(updateNotificationList(_id)),
        deleteProductLaunchEntry: (_id) => dispatch(deleteProductLaunchEntry(_id))
    })
)(ProductLaunchTable);