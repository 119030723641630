import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import LinearProgress from "@material-ui/core/LinearProgress";
import UserCard from "../Users/UserCard";
import {CircularProgress} from "@material-ui/core";

class HeaderCard extends Component {

    static propTypes = {
        loadingShopify: PropTypes.bool,
        loadingRSL: PropTypes.bool,
        data: PropTypes.object,
        title: PropTypes.string,
    };

    static defaultProps = {
        data: {}
    };


    renderChips = (status) => {
        if(status !== '' && status !== undefined && status.length > 0) {
            return status.map(statusLabel => {
                if(statusLabel !== '' && statusLabel !== undefined && statusLabel !== null) {
                    return (<Chip className={'chipStyle'} label={statusLabel} color="secondary"/>);
                }
            });
        }
    };

    renderLoadingBar = (type) => {
        if((this.props.loadingShopify) ||
            (this.props.loadingRSL)) {
            return(
                <CircularProgress color="secondary" />
            );
        }
    };

    renderOrderID = () => {
        const {title, data} = this.props;
        let link = '';
        if(title === 'RSL') {
            link = 'https://smartfill.rakutensl.com/Order/OrderDetailPage.aspx?o=' + data.orderID;
        } else if (title === 'SHOPIFY') {
            link = 'https://glorious-pc-gaming-race.myshopify.com/admin/orders/' + data.orderID;
        }

        return(<Typography><span className={'headerCardSubFont'}>{title} ID</span>{' '}<a target='_blank' className={'trackingLink'} href={link}>{data.orderID}{' '}</a></Typography>);

    };

    renderLineItems = () => {
        const {title, data} = this.props;
        if(title === 'RSL') {
            return (
                <div>
                    {data.line_items !== undefined && data.line_items.map(lineItem => {
                        return <Typography>{lineItem}</Typography>
                    })}
                </div>
            );
        }
    };

    render() {
        const {data} = this.props;

        return (
            <div className={'panelContainer'}>
                {this.renderOrderID()}
                {this.renderLoadingBar(data.type)}
                {this.renderChips(data.status)}
                {this.renderLineItems()}
            </div>
        );
    }
};

export default connect(
    state => ({
        loadingShopify: state.verificationReducer.loadingShopify,
        loadingRSL: state.verificationReducer.loadingRSL,
    }),
    dispatch => ({
    })
)(HeaderCard);
