import {DISPLAY_SNACKBAR_MESSAGE} from "../redux/actions/verificationActions";

const host = 'https://registorious.gloriousgamingservices.com';
//const host = 'https://68c039285619.ngrok.io';

function checkRegistoriousAuthentication(userProfile,callback){
    var registoriousToken = localStorage.getItem('registorious-token');
    var token = localStorage.getItem('token');

    if (registoriousToken==undefined || registoriousToken!=token){
        fetch(host+"/users/enroll",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({Id:userProfile._id,Token:token})
        })
        .then(response => {

            localStorage.saveItem('registorious-token',response);
            return callback();
        })
        .catch(function (error) {

        });
    }else{
        return callback();
    }
}



export function getServiceCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE, method,userProfile) {

    // return checkRegistoriousAuthentication(userProfile,()=>{
        return fetch(host+url,{
            method: method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            } else {

                dispatch({
                    type: FAIL_ACTION
                })
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            } else {
                dispatch({
                    type:FAIL_ACTION
                })
            }
        })
        .then(() => {
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            dispatch({
                type: DISPLAY_SNACKBAR_MESSAGE,
                data: 'Error has occurred'
            });
        });
    //});


}

export function postServiceCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE, body, method) {
    console.log('sending post call');

    return fetch(host+url,{
            method: method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: body,
        })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            }
        })
        .then(() => {
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: FAIL_ACTION
            })
        });
}


export function postServiceDataFormCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE, body, method) {
    console.log('sending post call');

    return fetch(url,{
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            // 'Content-Type': 'multipart/form-data',
        },
        body: body,
    })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            }
        })
        .then(() => {
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: FAIL_ACTION
            })
        });
}


function handleStatus(statusCode, dispatch) {
    if(statusCode === 401) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Unauthorized Access.'
        });
        return false
    } else if(statusCode === 400) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Error has occurred. Try again or reach out to admin'
        });
        return false
    }
    else if(statusCode === 404) {
        return false
    } else if(statusCode === 500) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Internal Error has Occurred'
        });
        return false
    }
    return true
}
