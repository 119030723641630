import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {Card} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class DistributorGroupBuyEditor extends Component {

    static propTypes = {
        editGroupBuyDetails: PropTypes.func,
        groupBuyData: PropTypes.object,
    };

    static defaultProps = {
        groupBuyData: {}
    };

    render() {
        const {groupBuyData} = this.props;

        return (
            <div className={'userContainer'}>
                <Card className={'userCard'} >
                    <IconButton color="secondary" className={'editIcon'} aria-label="edit order" onClick={() => this.props.editGroupBuyDetails(groupBuyData)}>
                        <EditIcon />
                    </IconButton>
                    <Typography className={'textSpacing'} variant='h6'>SKU: {groupBuyData.sku}</Typography>
                    <Typography className={'textSpacing'} variant='h6'>URL: {groupBuyData.url}</Typography>
                </Card>
            </div>
        );
    }
}

export default connect(
    state => ({
    }),
    dispatch => ({
    })
)(DistributorGroupBuyEditor);