import React, {Component} from "react";
import PropTypes from "prop-types";
const _ = require('lodash/function');
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {getSerialNumberData, updateSNWithOrderID} from '../../redux/actions/serialNumberActions';
import {CircularProgress} from "@material-ui/core";
import SerialNumberEvents from "./SerialNumberEvents";
import SerialNumberUploadModule from "./SerialNumberUploadModule";
import CreateIcon from '@material-ui/icons/Create';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
class SerialNumberPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serialNumberSearchText: '',
            orderID: '',
            orderEdit: false
        }
    }

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.object,
        serialNumberData: PropTypes.object,
        loadingSerialNumberData: PropTypes.bool,
        userProfile: PropTypes.object,
        updateSNWithOrderID: PropTypes.func
    };

    renderOrderIDTextBox = () => {
        return(
            <div>
                <Typography className={'searchTitle'}></Typography><TextField id="outlined-basic" label="OrderID" onChange={this.updateOrderID}/>
                <Button className={'searchOrderButton'} variant="contained" onClick={this.saveOrderIDToSN} color="secondary">Save</Button>
            </div>
        );
    }

    updateOrderID = (event) => {
        this.setState({
            orderID: event.target.value
        })
    };

    saveOrderIDToSN = () => {
        const {serialNumberData} = this.props;
        this.toggleOrderIDEdit();
        this.props.updateSNWithOrderID(serialNumberData._id, this.state.orderID);
    }

    toggleOrderIDEdit = () => {
        this.setState({
            orderEdit: !this.state.orderEdit
        })
    }

    renderSerialNumberData = () => {
        const {serialNumberData} = this.props;
        const {orderEdit} = this.state;
        return (
            <div>
                <Typography><b>S/N:</b> {serialNumberData.serialNumber}</Typography>
                <Typography><b>Original Shipped Location:</b> {serialNumberData.originShipToLocation}</Typography>
                <Typography><b>Purchase Order Number: </b> {serialNumberData.purchaseOrderNum}</Typography>
                <Typography><b>SKU: </b> {serialNumberData.sku}</Typography>
                <Typography><b>Factory: </b> {serialNumberData.factory}</Typography>
                <Typography><b>Manufactured on: </b> {serialNumberData.manufacturedDate}</Typography>
                <Typography>
                    <b>OrderID: </b>
                    {serialNumberData.orderID}
                    <IconButton color="secondary" aria-label="edit orderID" onClick={this.toggleOrderIDEdit}>
                        <CreateIcon />
                    </IconButton>
                </Typography>
                {orderEdit && this.renderOrderIDTextBox()}
            </div>
        )
    };

    renderSearchBox = () => {
        const {loadingSerialNumberData} = this.props;
        return (
            <div className={'searchButtonBlock'}>
                <Typography className={'searchTitle'}></Typography><TextField autoFocus id="outlined-basic" label="Serial Number" onChange={this.updateSerialNumberSearchText}/>
                <Button className={'searchOrderButton'} variant="contained" onClick={this.grabSerialNumberData} color="primary">Search</Button>
                {loadingSerialNumberData && <CircularProgress color="secondary" />}
            </div>
        )
    };

    updateSerialNumberSearchText = (event) => {
        this.setState({
            serialNumberSearchText: event.target.value
        })
    };

    grabSerialNumberData = () => {
        this.props.getSerialNumberData(this.state.serialNumberSearchText);
    };

    render() {
        const { roles } = this.props;
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h4'>Serial Number Search</Typography>
                </div>
                <div>
                    {this.renderSearchBox()}
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div className={'card-title'}>
                            <Typography variant='h6'>Serial Number Details</Typography>
                        </div>
                        <Card className={'serialNumberDetailsPanel'}>
                            {this.renderSerialNumberData()}
                        </Card>
                        { (roles.includes("admin") || roles.includes("b2b_admin")) && <SerialNumberUploadModule /> }
                    </Grid>
                    <Grid item xs={6}>
                       <SerialNumberEvents />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        serialNumberData: state.serialNumberReducer.serialNumberData,
        loadingSerialNumberData: state.serialNumberReducer.loadingSerialNumberData,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getSerialNumberData: (serialNumber) => dispatch(getSerialNumberData(serialNumber)),
        updateSNWithOrderID: (_id, orderID) => dispatch(updateSNWithOrderID(_id, orderID))
    })
)(SerialNumberPage);