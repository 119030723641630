import {getServiceCallOptions, postServiceCallOptions} from "../../services/api";
import {CREATE_USER_SUCCESS, GET_ALL_USERS_LOADING_COMPLETE} from "./userActions";

export const GET_B2B_ORDERS_LOADING = 'GET_ALL_B2B_ORDERS_LOADING';
export const GET_B2B_ORDERS_SUCCESS = 'GET_ALL_B2B_ORDERS_SUCCESS';
export const GET_B2B_ORDERS_COMPLETE = 'GET_ALL_B2B_ORDERS_COMPLETE';
export const GET_B2B_CUSTOMERS_LOADING = 'GET_B2B_CUSTOMERS_LOADING';
export const GET_B2B_CUSTOMERS_SUCCESS = 'GET_B2B_CUSTOMERS_SUCCESS';
export const GET_B2B_CUSTOMERS_COMPLETE = 'GET_B2B_CUSTOMERS_COMPLETE';
export const CREATE_B2B_CUSTOMER_LOADING = 'CREATE_B2B_CUSTOMER_LOADING';
export const CREATE_B2B_CUSTOMER_SUCCESS = 'CREATE_B2B_CUSTOMER_SUCCESS';
export const CREATE_B2B_CUSTOMER_COMPLETE = 'CREATE_B2B_CUSTOMER_COMPLETE';
export const UPDATE_B2B_CUSTOMER_COMPLETE = 'UPDATE_B2B_CUSTOMER_COMPLETE';
export const UPDATE_B2B_CUSTOMER_SUCCESS = 'UPDATE_B2B_CUSTOMER_SUCCESS';
export const GET_B2B_PRICING_OPTIONS_LOADING = 'GET_B2B_PRICING_OPTIONS_LOADING';
export const GET_B2B_PRICING_OPTIONS_SUCCESS = 'GET_B2B_PRICING_OPTIONS_SUCCESS';
export const GET_B2B_PRICING_OPTIONS_COMPLETE = 'GET_B2B_PRICING_OPTIONS_COMPLETE';
export const UPDATE_B2B_PRODUCT_SUCCESS = 'UPDATE_B2B_PRODUCT_SUCCESS';
export const UPDATE_B2B_PRODUCT_COMPLETE = 'UPDATE_B2B_PRODUCT_COMPLETE';
export const SINGLE_ORDER_DETAILS_LOADING = 'SINGLE_ORDER_DETAILS_LOADING';
export const GET_SINGLE_ORDER_DETAILS_SUCCESS = 'GET_SINGLE_ORDER_DETAILS_SUCCESS';
export const GET_SINGLE_ORDER_DETAILS_LOADING_COMPLETE= 'GET_SINGLE_ORDER_DETAILS_LOADING_COMPLETE';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_COMPLETE = 'UPDATE_ORDER_STATUS_COMPLETE';
export const FAILED_SERVICE = 'FAILED_SERVICE';

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getAllB2BOrders = () => {
    return (dispatch) => {
        dispatch({type: GET_B2B_ORDERS_LOADING});
        let url = host + '/b2b/order/all/';
        console.log('Grabbing all b2b orders from db');
        return getServiceCallOptions(url, GET_B2B_ORDERS_SUCCESS, FAILED_SERVICE, dispatch, GET_B2B_ORDERS_COMPLETE, 'GET');
    }
};

export const updateOrderStatus = (status, id) => {
    return (dispatch) => {
        dispatch({type: SINGLE_ORDER_DETAILS_LOADING});
        let url = host + '/b2b/order/update';
        let data = new URLSearchParams();
        data.append('status', status);
        data.append('id', id);
        return postServiceCallOptions(url, UPDATE_ORDER_STATUS_SUCCESS, FAILED_SERVICE, dispatch, UPDATE_ORDER_STATUS_COMPLETE, data, 'POST');
    }
};

export const searchB2BOrders = (searchText, stage) => {
    return (dispatch) => {
        dispatch({type: GET_B2B_ORDERS_LOADING});
        let url = `${host}/b2b/order/search/${searchText}/${stage}`;
        console.log('Grabbing all b2b orders from db that meet the search criteria');
        return getServiceCallOptions(url, GET_B2B_ORDERS_SUCCESS, FAILED_SERVICE, dispatch, GET_B2B_ORDERS_COMPLETE, 'GET');
    }
};


export const getB2BOrdersForAccount = (email) => {
    return (dispatch) => {
        dispatch({type: GET_B2B_ORDERS_LOADING});
        let url = host + `/b2b/order/email/${email}`;
        console.log('Grabbing b2b orders for specific account');
        return getServiceCallOptions(url, GET_B2B_ORDERS_SUCCESS, FAILED_SERVICE, dispatch, GET_B2B_ORDERS_COMPLETE, 'GET');
    }
};

export const getAllB2BCustomers = () => {
    return (dispatch) => {
        dispatch({type: GET_B2B_CUSTOMERS_LOADING});
        let url = host + '/b2b/account/all';
        console.log('Grabbing all b2b customers from db');
        return getServiceCallOptions(url, GET_B2B_CUSTOMERS_SUCCESS, FAILED_SERVICE, dispatch, GET_B2B_CUSTOMERS_COMPLETE, 'GET');
    }
};

export const createB2BUser = (email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer) => {
    return (dispatch) => {
        let url = host + '/b2b/account/create';
        console.log('Creating b2b user');
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('password', password);
        data.append('name', name);
        data.append('branch', branch);
        data.append('address', address);
        data.append('address2', address2);
        data.append('city', city);
        data.append('stateProvince', stateProvince);
        data.append('postalCode', postalCode);
        data.append('country', country);
        data.append('pricingTier', pricingTier);
        data.append('paymentTerms', paymentTerms);
        data.append('largeCustomer', largeCustomer);
        return postServiceCallOptions(url, CREATE_B2B_CUSTOMER_SUCCESS, FAILED_SERVICE, dispatch, CREATE_B2B_CUSTOMER_COMPLETE, data, 'POST');
    }
};

export const updateB2BUser = (email, name, password, branch, pricingTier, address, address2, city, stateProvince, postalCode, country, paymentTerms, largeCustomer) => {
    return (dispatch) => {
        let url = host + '/b2b/account/update';
        console.log('Updating b2b user');
        let data = new URLSearchParams();
        data.append('email', email);
        data.append('password', password);
        data.append('name', name);
        data.append('branch', branch);
        data.append('address', address);
        data.append('address2', address2);
        data.append('city', city);
        data.append('stateProvince', stateProvince);
        data.append('postalCode', postalCode);
        data.append('country', country);
        data.append('pricingTier', pricingTier);
        data.append('paymentTerms', paymentTerms);
        data.append('largeCustomer', largeCustomer);
        return postServiceCallOptions(url, UPDATE_B2B_CUSTOMER_SUCCESS, FAILED_SERVICE, dispatch, UPDATE_B2B_CUSTOMER_COMPLETE, data, 'POST');
    }
};

export const updateB2BProduct = (sku, moq, imageURL, active) => {
    return (dispatch) => {
        let url = host + '/b2b/product/update';
        console.log('Updating b2b product');
        let data = new URLSearchParams();
        data.append('sku', sku);
        data.append('moq', moq);
        data.append('imageURL', imageURL);
        data.append('active', active);
        return postServiceCallOptions(url, UPDATE_B2B_PRODUCT_SUCCESS, FAILED_SERVICE, dispatch, UPDATE_B2B_PRODUCT_COMPLETE, data, 'POST');
    }
};

export const getPricingOptions = () => {
    return (dispatch) => {
        dispatch({type: GET_B2B_PRICING_OPTIONS_LOADING});
        let url = host + '/b2b/prices';
        console.log('Grabbing all b2b pricing options from db');
        return getServiceCallOptions(url, GET_B2B_PRICING_OPTIONS_SUCCESS, FAILED_SERVICE, dispatch, GET_B2B_PRICING_OPTIONS_COMPLETE, 'GET');
    }
};

export const getSingleOrderData = (referenceNum) => {
    return (dispatch) => {
        dispatch({type: SINGLE_ORDER_DETAILS_LOADING});
        let url = `${host}/b2b/order/reference/${referenceNum}`;
        console.log('Getting Single Order details');
        return getServiceCallOptions(url, GET_SINGLE_ORDER_DETAILS_SUCCESS, FAILED_SERVICE, dispatch, GET_SINGLE_ORDER_DETAILS_LOADING_COMPLETE, 'GET');
    }
};