import React, {Component} from "react";
import {connect} from "react-redux";
import {
    closeSnackBar
} from "../../redux/actions/verificationActions";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';


class GloriousSnackBar extends Component {

    static propTypes = {
        closeSnackBar: PropTypes.func,
        displaySnackBar: PropTypes.bool,
        snackBarMessage: PropTypes.string,
    };

    handleClose = () => {
        this.props.closeSnackBar();
    };

    render() {
        return(
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.props.displaySnackBar}
                autoHideDuration={10000}
                onClose={this.handleClose}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar">
                            <span>{this.props.snackBarMessage}</span>
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );
    }
}


export default connect(
    state => ({
        snackBarMessage: state.snackbarReducer.snackBarMessage,
        displaySnackBar: state.snackbarReducer.displaySnackBar,
    }),
    dispatch => ({
        closeSnackBar: () => dispatch(closeSnackBar()),
    })
)(GloriousSnackBar);