import {getServiceCall, getServiceCallOptions, postServiceCallOptions} from "../../services/api";
import {GET_ALL_USERS_LOADING_COMPLETE, UPDATE_USER_SUCCESS} from "./userActions";

export const GET_SHOPIFY_ORDER_SUCCESS = 'GET_SHOPIFY_ORDER_SUCCESS';
export const GET_CIN_ORDER_SUCCESS = 'GET_CIN_ORDER_SUCCESS';
export const GET_RSL_ORDER_SUCCESS = 'GET_RSL_ORDER_SUCCESS';
export const FAILED_SERVICE = 'FAILED_SERVICE';
export const FAILED_SERVICE_SHOPIFY = 'FAILED_SERVICE_SHOPIFY';
export const FAILED_SERVICE_RSL = 'FAILED_SERVICE_RSL';
export const FAILED_SERVICE_CIN = 'FAILED_SERVICE_CIN';
export const FAILED_SERVICE_TRACKING = 'FAILED_SERVICE_TRACKING';
export const LOADING_COMPLETE_SHOPIFY = 'LOADING_COMPLETE_SHOPIFY';
export const LOADING_SHOPIFY = 'LOADING_SHOPIFY';
export const LOADING_COMPLETE_CIN7 = 'LOADING_COMPLETE_CIN7';
export const LOADING_CIN7 = 'LOADING_CIN7';
export const LOADING_COMPLETE_RSL = 'LOADING_COMPLETE_RSL';
export const LOADING_RSL = 'LOADING_RSL';
export const GET_TRACKING_DATA_SUCCESS = 'GET_TRACKING_DATA_SUCCESS';
export const CANCEL_RSL_ORDER_SUCCESS = 'CANCEL_RSL_ORDER_SUCCESS';
export const CANCEL_SHOPIFY_ORDER_SUCCESS = 'CANCEL_SHOPIFY_ORDER_SUCCESS';
export const DISPLAY_SNACKBAR_MESSAGE = 'DISPLAY_SNACKBAR_MESSAGE';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const ORDER_NUMBER_UPDATE = 'ORDER_NUMBER_UPDATE';
export const ORDER_REFUND = 'ORDER_REFUND';
export const ORDER_REFUND_LOADING = 'ORDER_REFUND_LOADING';
export const ORDER_REFUND_SUCCESSFUL = 'ORDER_REFUND_SUCCESSFUL';
export const ORDER_REFUND_LOADING_COMPLETE = 'ORDER_REFUND_LOADING_COMPLETE';
export const GRAB_CUSTOMER_SEARCH_SUCCESS = 'GRAB_CUSTOMER_SEARCH_SUCCESS';
export const GRAB_CUSTOMER_SEARCH_LOADING_COMPLETE = 'GRAB_CUSTOMER_SEARCH_LOADING_COMPLETE';
export const GRAB_CUSTOMER_SEARCH_LOADING = 'GRAB_CUSTOMER_SEARCH_LOADING';
export const GRAB_CUSTOMER_ORDER_SUCCESS = 'GRAB_CUSTOMER_ORDER_SUCCESS';
export const GRAB_CUSTOMER_ORDER_LOADING_COMPLETE = 'GRAB_CUSTOMER_ORDER_LOADING_COMPLETE';
export const GRAB_CUSTOMER_ORDER_LOADING = 'GRAB_CUSTOMER_ORDER_LOADING';
export const CANCEL_ALL_ORDER_SUCCESS = 'CANCEL_ALL_ORDER_SUCCESS';


const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getShopifyOrder = (orderNumber) => {
    return (dispatch) => {
        dispatch({type: LOADING_SHOPIFY});
        let url = host + '/orders/shopify/' + orderNumber;
        console.log('Grabbing Data for Shopify Order: ' + orderNumber);
        return getServiceCallOptions(url, GET_SHOPIFY_ORDER_SUCCESS, FAILED_SERVICE_SHOPIFY, dispatch, LOADING_COMPLETE_SHOPIFY, 'GET');
    }
};

export const getRSLOrder = (orderNumber) => {
    return (dispatch) => {
        dispatch({type: LOADING_RSL});
        let url = host + '/orders/rsl/' + orderNumber;
        console.log('Grabbing Data for RSL Order: ' + orderNumber);
        return getServiceCallOptions(url, GET_RSL_ORDER_SUCCESS, FAILED_SERVICE_RSL, dispatch, LOADING_COMPLETE_RSL, 'GET');
    }
};

export const getCINOrder = (orderNumber) => {
    return (dispatch) => {
        dispatch({type: LOADING_CIN7});
        let url = host + '/orders/cin/' + orderNumber;
        console.log('Grabbing Data for CIN Order: ' + orderNumber);
        return getServiceCallOptions(url, GET_CIN_ORDER_SUCCESS, FAILED_SERVICE_CIN, dispatch, LOADING_COMPLETE_CIN7, 'GET');
    }
};

export const getTrackingInformation = (orderNumber) => {
    return (dispatch) => {
        // dispatch({type: LOADING_CIN7});
        let url = host + '/orders/rsl/' + orderNumber + '/tracking/';
        console.log('Grabbing Tracking Data for Order: ' + orderNumber);
        return getServiceCallOptions(url, GET_TRACKING_DATA_SUCCESS, FAILED_SERVICE_TRACKING, dispatch, LOADING_COMPLETE_RSL, 'GET');
    }
};

export const getCustomerSearch = (query) => {
    return (dispatch) => {
        dispatch({type: GRAB_CUSTOMER_SEARCH_LOADING});
        let url = host + '/orders/shopify/search/' + query;
        console.log('Grabbing Customer Data for Query: ' + query);
        return getServiceCallOptions(url, GRAB_CUSTOMER_SEARCH_SUCCESS, FAILED_SERVICE, dispatch, GRAB_CUSTOMER_SEARCH_LOADING_COMPLETE, 'GET');
    }
};

export const getCustomerOrders = (customerID) => {
    return (dispatch) => {
        dispatch({type: GRAB_CUSTOMER_ORDER_LOADING});
        let url = host + '/orders/shopify/customer/' + customerID;
        console.log('Grabbing Customers Orders for ID: ' + customerID);
        return getServiceCallOptions(url, GRAB_CUSTOMER_ORDER_SUCCESS, FAILED_SERVICE, dispatch, GRAB_CUSTOMER_ORDER_LOADING_COMPLETE, 'GET');
    }
};

export const cancelOrderFromRSL = (orderID) => {
    return (dispatch) => {
        // dispatch({type: LOADING_CIN7});
        let url = host + '/orders/rsl/cancel/' + orderID;
        console.log('Cancelling RSL Order: ' + orderID);
        return getServiceCallOptions(url, CANCEL_RSL_ORDER_SUCCESS, FAILED_SERVICE, dispatch, LOADING_COMPLETE_RSL, 'POST');
    }
};

export const cancelOrderFromShopify = (orderID) => {
    return (dispatch) => {
        // dispatch({type: LOADING_CIN7});
        let url = host + '/orders/shopify/cancel/' + orderID;
        console.log('Cancelling Shopify Order: ' + orderID);
        return getServiceCallOptions(url, CANCEL_SHOPIFY_ORDER_SUCCESS, FAILED_SERVICE, dispatch, LOADING_COMPLETE_RSL, 'POST');
    }
};

export const cancelOrderFromAllSystems = (rslOrderID, shopifyOrderID) => {
    return (dispatch) => {
        let url = host + '/orders/cancel';
        let data = new URLSearchParams();
        data.append('rslOrderID', rslOrderID);
        data.append('shopifyOrderID', shopifyOrderID);
        return postServiceCallOptions(url, CANCEL_ALL_ORDER_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_USERS_LOADING_COMPLETE, data, 'POST');
    }
};

export const orderRefundFromShopify = (orderID, refundAmount) => {
    return (dispatch) => {
        dispatch({type: ORDER_REFUND_LOADING});
        let url = host + '/orders/shopify/refund/' + orderID + '/' + refundAmount;
        console.log('Cancelling Shopify Order: ' + orderID + ' Refund amount:' + refundAmount);
        return getServiceCallOptions(url, ORDER_REFUND_SUCCESSFUL, FAILED_SERVICE, dispatch, ORDER_REFUND_LOADING_COMPLETE, 'POST');
    }
};

export const closeSnackBar = () => {
   return (dispatch) => {
       dispatch({
            type: CLOSE_SNACKBAR
       })
   }
};

export const updateOrderNumberText = (orderNumber) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_NUMBER_UPDATE,
            data: orderNumber
        })
    }
};