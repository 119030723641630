
import {getServiceCallOptions, postServiceCallOptionsAsJson } from "../../services/api";


export const GET_LOYALTY_POINTS_LOADING  = 'GET_LOYALTY_POINTS_LOADING';
export const GET_LOYALTY_POINTS_SUCCESS  = 'GET_LOYALTY_POINTS_SUCCESS';
export const GET_LOYALTY_POINTS_COMPLETE  = 'GET_LOYALTY_POINTS_COMPLETE';
export const GET_LOYALTY_POINTS_FAILED  = 'GET_LOYALTY_POINTS_FAILED';

export const CREATE_LOYALTY_POINTS_LOADING  = 'CREATE_LOYALTY_POINTS_LOADING';
export const CREATE_LOYALTY_POINTS_SUCCESS  = 'CREATE_LOYALTY_POINTS_SUCCESS';
export const CREATE_LOYALTY_POINTS_COMPLETE  = 'CREATE_LOYALTY_POINTS_COMPLETE';
export const CREATE_LOYALTY_POINTS_FAILED  = 'CREATE_LOYALTY_POINTS_FAILED';

export const UPDATE_LOYALTY_POINTS_LOADING  = 'UPDATE_LOYALTY_POINTS_LOADING';
export const UPDATE_LOYALTY_POINTS_SUCCESS  = 'UPDATE_LOYALTY_POINTS_SUCCESS';
export const UPDATE_LOYALTY_POINTS_COMPLETE  = 'UPDATE_LOYALTY_POINTS_COMPLETE';
export const UPDATE_LOYALTY_POINTS_FAILED  = 'UPDATE_LOYALTY_POINTS_FAILED';

export const LOAD_LOYALTY_CUSTOMER_POINTS_LOADING  = 'LOAD_LOYALTY_CUSTOMER_POINTS_LOADING';
export const LOAD_LOYALTY_CUSTOMER_POINTS_SUCCESS  = 'LOAD_LOYALTY_CUSTOMER_POINTS_SUCCESS';
export const LOAD_LOYALTY_CUSTOMER_POINTS_COMPLETE  = 'LOAD_LOYALTY_CUSTOMER_POINTS_COMPLETE';
export const LOAD_LOYALTY_CUSTOMER_POINTS_FAILED  = 'LOAD_LOYALTY_CUSTOMER_POINTS_FAILED';

export const UPDATE_LOYALTY_CUSTOMER_POINTS_LOADING  = 'UPDATE_LOYALTY_CUSTOMER_POINTS_LOADING';
export const UPDATE_LOYALTY_CUSTOMER_POINTS_SUCCESS  = 'UPDATE_LOYALTY_CUSTOMER_POINTS_SUCCESS';
export const UPDATE_LOYALTY_CUSTOMER_POINTS_COMPLETE  = 'UPDATE_LOYALTY_CUSTOMER_POINTS_COMPLETE';
export const UPDATE_LOYALTY_CUSTOMER_POINTS_FAILED  = 'UPDATE_LOYALTY_CUSTOMER_POINTS_FAILED';

export const DELETE_LOYALTY_POINTS_LOADING  = 'DELETE_LOYALTY_POINTS_LOADING';
export const DELETE_LOYALTY_POINTS_SUCCESS  = 'DELETE_LOYALTY_POINTS_SUCCESS';
export const DELETE_LOYALTY_POINTS_COMPLETE  = 'DELETE_LOYALTY_POINTS_COMPLETE';
export const DELETE_LOYALTY_POINTS_FAILED  = 'DELETE_LOYALTY_POINTS_FAILED';

const host = 'https://api.gpcgr.com';

// const host = 'https://tfsnnfk8-3000.euw.devtunnels.ms';

export const getAllPoints = () => {
    return (dispatch) => {
        dispatch({type: GET_LOYALTY_POINTS_LOADING});
        let url = host+`/loyalty/products/list`;
        return getServiceCallOptions(url, GET_LOYALTY_POINTS_SUCCESS, GET_LOYALTY_POINTS_FAILED, dispatch, GET_LOYALTY_POINTS_COMPLETE, 'GET');
    }
};

export const createLoyaltyProduct = (product) => {
    return (dispatch) => {
        dispatch({type: CREATE_LOYALTY_POINTS_LOADING});

        let url = host+`/loyalty/products/create`;
        return postServiceCallOptionsAsJson(url, CREATE_LOYALTY_POINTS_SUCCESS, CREATE_LOYALTY_POINTS_FAILED, dispatch, CREATE_LOYALTY_POINTS_COMPLETE,product,"POST");
    }
};

export const deleteLoyaltyProduct = (product) => {
    return (dispatch) => {
        dispatch({type: DELETE_LOYALTY_POINTS_LOADING});
        let url = host+`/loyalty/products/delete/`+product._id;
        return getServiceCallOptions(url, DELETE_LOYALTY_POINTS_SUCCESS, DELETE_LOYALTY_POINTS_FAILED, dispatch, DELETE_LOYALTY_POINTS_COMPLETE,"DELETE");
    }
};

export const updateLoyaltyProduct = (product) => {
    return (dispatch) => {
        dispatch({type: UPDATE_LOYALTY_POINTS_LOADING});
        let url = host+`/loyalty/products/update`;
        return postServiceCallOptionsAsJson(url, UPDATE_LOYALTY_POINTS_SUCCESS, UPDATE_LOYALTY_POINTS_FAILED, dispatch, UPDATE_LOYALTY_POINTS_COMPLETE,product,"PUT");
    }
};

export const updateLoyaltyCustomerPoints = (customer) => {
    return (dispatch) => {
        dispatch({type: UPDATE_LOYALTY_CUSTOMER_POINTS_LOADING});
        let url = host+`/loyalty/customer/update-points`;
        return postServiceCallOptionsAsJson(url, UPDATE_LOYALTY_CUSTOMER_POINTS_SUCCESS, UPDATE_LOYALTY_CUSTOMER_POINTS_FAILED, dispatch, UPDATE_LOYALTY_CUSTOMER_POINTS_COMPLETE,customer,"POST");
    }
};
export const loadLoyaltyCustomerPoints = (customer) => {
    return (dispatch) => {
        dispatch({type: LOAD_LOYALTY_CUSTOMER_POINTS_LOADING});
        let url = host+`/loyalty/customer/email/${customer}`;
        return getServiceCallOptions(url, LOAD_LOYALTY_CUSTOMER_POINTS_SUCCESS, LOAD_LOYALTY_CUSTOMER_POINTS_FAILED, dispatch, LOAD_LOYALTY_CUSTOMER_POINTS_COMPLETE,"GET");
    }
};

