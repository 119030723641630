import {getServiceCallOptions, postServiceCallOptions} from "../../services/api";

export const GET_INVENTORY_REPORT_SUCCESS = 'GET_INVENTORY_REPORT_SUCCESS';
export const FAILED_SERVICE = 'FAILED_SERVICE';
export const GET_INVENTORY_REPORT_COMPLETE = 'GET_INVENTORY_REPORT_COMPLETE';
export const GET_INVENTORY_REPORT_LOADING = 'GET_INVENTORY_REPORT_LOADING';
export const UPDATE_NOTES_FOR_BRANCH = 'UPDATE_NOTES_FOR_BRANCH';
export const BRANCH_NOTES_UPDATED = 'BRANCH_NOTES_UPDATED';
export const GET_ETD_SKU_REPORT_SUCCESS = 'GET_ETD_SKU_REPORT_SUCCESS';
export const GET_ETD_SKU_REPORT_COMPLETE = 'GET_ETD_SKU_REPORT_COMPLETE';
export const GET_ETD_SKU_REPORT_LOADING = 'GET_ETD_SKU_REPORT_LOADING';

const _ = require('lodash/function');

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getAllInventory = () => {
    return (dispatch) => {
        dispatch({type: GET_INVENTORY_REPORT_LOADING});
        let url = host + '/inventory/all/';
        console.log('Grabbing all inventory information from cin7');
        return getServiceCallOptions(url, GET_INVENTORY_REPORT_SUCCESS, FAILED_SERVICE, dispatch, GET_INVENTORY_REPORT_COMPLETE, 'GET');
    }
};

export const getRecentInventory = () => {
    return (dispatch) => {
        dispatch({type: GET_INVENTORY_REPORT_LOADING});
        let url = host + '/inventory/recent/';
        console.log('Grabbing all inventory information from Glorious DB');
        return getServiceCallOptions(url, GET_INVENTORY_REPORT_SUCCESS, FAILED_SERVICE, dispatch, GET_INVENTORY_REPORT_COMPLETE, 'GET');
    }
};

export const updateBranchNotes = (sku, branchName, notes) => {

    return (dispatch) => {
        let url = host + '/inventory/notes/update';
        let data = new URLSearchParams();
        data.append('sku', sku);
        data.append('branchName', branchName);
        data.append('notes', notes);

        return postServiceCallOptions(url, UPDATE_NOTES_FOR_BRANCH, FAILED_SERVICE, dispatch, BRANCH_NOTES_UPDATED, data, 'POST');
    }
};

export const getEtdSkuReport = (sku, qty, branchId) => {
    return (dispatch) => {
        dispatch({type: GET_ETD_SKU_REPORT_LOADING});
        let url = host + `/inventory/etd/${sku}/${qty}/${branchId}`;
        return getServiceCallOptions(url, GET_ETD_SKU_REPORT_SUCCESS, FAILED_SERVICE, dispatch, GET_ETD_SKU_REPORT_COMPLETE, 'GET');
    }
};