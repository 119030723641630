import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { createUser, getAllUsers, updateUser, resetAllPasswords } from "../../redux/actions/userActions";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import userReducer from "../../redux/reducers/userReducer";
import UserCard from "./UserCard";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            email: '',
            name: '',
            role: '',
            create: true,
            showOnlyLocked:false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getAllUsers();
    }

    static propTypes = {
        authorized: PropTypes.bool,
        getAllUsers: PropTypes.func,
        users: PropTypes.array
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        const {email, name, role, create} = this.state;
        let user_roles = [role];
        event.preventDefault();
        if(create) {
            this.props.createUser(email, name, user_roles);
        } else {
            this.props.updateUser(email, name, user_roles, this.props.roles.includes("admin"));
        }
        this.closeCreateUserDialog();
    }

    renderUserCards = () => {
        if (this.state.showOnlyLocked){
            return this.props.users.filter(u=>u.lockAccount).map(user => {
                return (<UserCard userData={user} editUserDetails={this.editUserDetails} />);
            });
        }else{
            return this.props.users.map(user => {
                return (<UserCard userData={user} editUserDetails={this.editUserDetails} />);
            });
        }

    };

    editUserDetails = (user) => {
        this.setState({
            email: user.email,
            name: user.name,
            role: user.roles[0],
            create: false,
        });
        this.openCreateUserDialog();
    };

    openCreateUserDialog = () => {
        this.setState({
            open: true
        })
    };

    closeCreateUserDialog = () => {
        this.setState({
            open: false,
            email: '',
            name: '',
            role: '',
            create: true,
        })
    };

    renderUserCreateDialog = () => {
        const {roles} = this.props;
        const {create, open, email, password, name, role} = this.state;
        return(
            <form onSubmit={this.handleSubmit}>
                <Dialog open={open} onClose={this.closeCreateUserDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{create ? 'Create' : 'Update'} Glorious Nexus User</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter in the username, password, and role access then click Create. Once the user is created we will email them their new information.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="name"
                            fullWidth
                            value={name}
                            onChange={this.handleChange}
                        />
                        {/*<TextField*/}
                        {/*    margin="dense"*/}
                        {/*    id="password"*/}
                        {/*    name="password"*/}
                        {/*    label={create ? 'Password' : 'New Password'}*/}
                        {/*    type="password"*/}
                        {/*    fullWidth*/}
                        {/*    value={password}*/}
                        {/*    onChange={this.handleChange}*/}
                        {/*/>*/}
                        <FormControl className={'formControlSelect'}>
                            <InputLabel>Role</InputLabel>
                            <Select
                                id="role-select"
                                name="role"
                                value={role}
                                onChange={this.handleChange}
                            >
                                { roles.includes("admin") && <MenuItem value={'admin'}>Admin</MenuItem> }
                                { (roles.includes("cs_admin") || roles.includes("admin"))  && <MenuItem value={'cs_admin'}>CS Admin</MenuItem> }
                                { (roles.includes("logistics_admin") || roles.includes("admin"))  && <MenuItem value={'logistics_admin'}>Logistics Admin</MenuItem> }
                                { (roles.includes("b2b_admin") || roles.includes("admin"))  && <MenuItem value={'b2b_admin'}>B2B Admin</MenuItem> }
                                <MenuItem value={'tier1'}>Tier 1</MenuItem>
                                <MenuItem value={'tier2'}>Tier 2</MenuItem>
                                <MenuItem value={'tier3'}>Tier 3</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeCreateUserDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            {create ? 'Create' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        )
    };

    resetAllPasswords = () => {
        if (confirm("Are you sure you want to reset ALL THE PASSWORDS?")){
            this.props.resetAllPasswords();
        }else{
            console.log('reset ALL PASSWORDS canceled');
        }
    };

    toggleShowOnlyLockedUsers=()=>{
        const showLocked = this.state.showOnlyLocked;
        this.setState({
            showOnlyLocked: !showLocked
        });
    }

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        const {roles} = this.props;
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h5'>Nexus Users</Typography>
                </div>
                <div className={'userButtonBar'}>
                    <Tooltip title="Create new user">
                        <IconButton color="secondary" aria-label="add user" onClick={this.openCreateUserDialog}>
                            <PersonAddIcon />
                        </IconButton>
                    </Tooltip>

                    {this.state.showOnlyLocked &&
                    <Tooltip title="Show all users">
                        <IconButton color="secondary" aria-label="show all users" onClick={this.toggleShowOnlyLockedUsers}>
                            <UnlockIcon />
                        </IconButton>
                    </Tooltip>
                    }
                    {!this.state.showOnlyLocked &&
                    <Tooltip title="Show only locked users">
                        <IconButton color="secondary" aria-label="show only locked users" onClick={this.toggleShowOnlyLockedUsers}>
                            <LockIcon />
                        </IconButton>
                    </Tooltip>
                    }

                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderUserCards()}
                    </Grid>
                    <Grid item xs={6}>
                        {roles.includes("admin") &&
                            <Card>
                                <Typography variant='h6'>Security Information</Typography>
                                <Button variant="contained" color="primary"  onClick={this.resetAllPasswords}>Reset Passwords</Button>
                            </Card>
                        }
                    </Grid>
                </Grid>
                {this.renderUserCreateDialog()}
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        users: state.userReducer.users,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        getAllUsers: () => dispatch(getAllUsers()),
        createUser: (email, name, role) => dispatch(createUser(email, name, role)),
        updateUser: (email, name, role, admin) => dispatch(updateUser(email, name, role, admin)),
        resetAllPasswords: () => dispatch(resetAllPasswords())
    })
)(Users);
