import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import ShippingIcon from "@material-ui/icons/LocalShipping";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

class B2BAdminHome extends Component {

    static propTypes = {
        authorized: PropTypes.bool,
        roles: PropTypes.array
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }
        return (
            <div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            {(this.props.roles.includes('admin') || this.props.roles.includes('b2b_admin')) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/b2b/orders/all'>
                                    <IconButton color="secondary"  aria-label="Order Details Page">
                                        <ReceiptIcon />
                                    </IconButton>
                                    <Typography>B2B Orders</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                        <Grid item xs={4}>
                            {(this.props.roles.includes('admin') || this.props.roles.includes('b2b_admin')) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/b2b/customers'>
                                    <IconButton color="secondary"  aria-label="user page">
                                        <PersonIcon />
                                    </IconButton>
                                    <Typography>B2B Customer Accounts</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                        <Grid item xs={4}>
                            {(this.props.roles.includes('admin') || this.props.roles.includes('b2b_admin')) &&
                            <Paper className={'homeButtonPanel'}>
                                <Link to='/b2b/products'>
                                    <IconButton color="secondary"  aria-label="Inventory Status">
                                        <ShippingIcon />
                                    </IconButton>
                                    <Typography>Products</Typography>
                                </Link>
                            </Paper> }
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
    })
)(B2BAdminHome);