import React, {Component} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import OrderManifestTable from "./OrderManifestTable";


class OrderManifestHome extends Component {

    render() {
        return(
            <div>
                <OrderManifestTable />
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({

    })
)(OrderManifestHome);