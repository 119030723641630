import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import { getAllInventory, getRecentInventory, updateBranchNotes } from '../../redux/actions/inventoryActions';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
const _ = require('lodash/function');
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

class Inventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: false
        }
    }

    static propTypes = {
        getAllInventory: PropTypes.func,
        getRecentInventory: PropTypes.func,
        loadingInventory: PropTypes.bool,
        stockData: PropTypes.array,
        updateBranchNotes: PropTypes.func,
        authorized: PropTypes.bool,
        roles: PropTypes.array
    };

    componentDidMount() {
        this.props.getRecentInventory();
    }

    buildColumnDataForRole = () => {
        let columns = [];
        if(!this.props.roles.includes('tier1') && !this.props.roles.includes('tier2')) {
        columns.push(
            { title: 'SKU', field: 'sku', editable: 'never' },
            { title: 'Description', field: 'productName', editable: 'never'},
            { title: 'Type', field: 'productType', editable: 'never' },
            { title: 'Raku USA (Avail)', field: 'Rakuten - USA, USA (OH)', type: 'numeric', editable: 'never',options: { filter: false, sort: true,} },
            { title: 'Raku USA (PROD)', field: 'Rakuten - USA, USA (PROD)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            { title: 'Notes', field: 'Rakuten - Salt Lake City, UT - Notes', options: { filter: false}, editable: 'onUpdate'},
            // { title: 'Raku CA (Avail)', field: 'Rakuten - Anaheim, CA (OH)', type: 'numeric' , options: { filter: false, sort: true,} , editable: 'never'},
            // { title: 'Raku CA (PROD)', field: 'Rakuten - Anaheim, CA (PROD)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            // { title: 'Notes', field: 'Rakuten - Anaheim, CA - Notes', options: {filter: false}},
            // { title: 'Raku IL (Avail)', field: 'Rakuten - McCook, IL (OH)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            // { title: 'Raku IL (PROD)', field: 'Rakuten - McCook, IL (PROD)', type: 'numeric' , options: { filter: false, sort: true,} , editable: 'never'},
            // { title: 'Notes', field: 'Rakuten - McCook, IL - Notes', options: {filter: false}},
            { title: 'Norman HK (Avail)', field: 'Norman - Hong Kong, HK (OH)', type: 'numeric' , options: { filter: false, sort: true,} , editable: 'never'},
            { title: 'Norman HK (PROD)', field: 'Norman - Hong Kong, HK (PROD)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            { title: 'Notes', field: 'Norman - Hong Kong, HK - Notes', options: {filter: false}},
            { title: 'Factory CH (Avail)', field: 'Factory - China, CH (OH)', type: 'numeric', options: { filter: false, sort: true,} , editable: 'never'},
            { title: 'Factory CH (PROD)', field: 'Factory - China, CH (PROD)', type: 'numeric' , options: { filter: false, sort: true,} , editable: 'never'},
            { title: 'Notes', field: 'Factory - China, CH - Notes', options: {filter: false}},
            { title: 'Amaz FBA (Avail)', field: 'Amazon FBA (OH)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            { title: 'Amaz FBA (PROD)', field: 'Amazon FBA (PROD)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            { title: 'Notes', field: 'Amazon FBA - Notes', options: {filter: false}},
            );
        }
        if(!this.props.roles.includes('admin')) {
            columns.push(
                { title: 'SKU', field: 'sku', editable: 'never' },
                { title: 'Description', field: 'productName', editable: 'never'},
                { title: 'Type', field: 'productType', editable: 'never' },
                { title: 'Raku SLC (Avail)', field: 'Rakuten - USA, USA (OH)', type: 'numeric', editable: 'never',options: { filter: false, sort: true,} },
                { title: 'Raku SLC (PROD)', field: 'Rakuten - USA, USA (PROD)', type: 'numeric' , options: { filter: false, sort: true,}, editable: 'never' },
            );
        }
        return columns;
    };


    handleDBUpdate = (newData, oldData) => {
        let columnName = '';
        let notes = '';
        for(const [key, value] of Object.entries(newData)) {
            if(oldData[key] !== value) {
                columnName = key;
                notes = value;
                break;
            }
        }
        this.props.updateBranchNotes(newData.sku, columnName, notes);
    };

    renderMaterialTable = () => {
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        return (
            <MaterialTable
                title={'Inventory Report Table'}
                icons={tableIcons}
                columns={this.buildColumnDataForRole()}
                data={this.props.stockData}
                options={{
                    exportButton: true,
                    grouping: true,
                    pageSizeOptions: [5,10,15,20,50,250,500,1000],
                    columnsButton:true
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.props.stockData;
                                    const index = oldData.tableData.id;

                                    // Figure out data that changed and then pass to server
                                    this.handleDBUpdate(newData, oldData);

                                    data[index] = newData;
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        })
                }}
            />
        )

    };

    renderLoadingBar = () => {
        if(this.props.loadingInventory) {
            return(
                <LinearProgress className={'progress'} color="secondary" />
            );
        }
    };

    render() {
        if (!this.props.authorized) {
            this.props.history.push('/');
        }
        if(this.props.userProfile.passwordReset) {
            this.props.history.push('/users/profile');
        }

        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6' >Glorious Inventory Status</Typography>
                </div>
                {this.renderLoadingBar()}
                {this.renderMaterialTable()}
            </div>
        );
    }

}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        roles: state.userReducer.roles,
        users: state.userReducer.users,
        userProfile: state.userReducer.userProfile,
        stockData: state.inventoryReducer.stockData,
        loadingInventory: state.inventoryReducer.loadingInventory
    }),
    dispatch => ({
        getAllInventory: () => dispatch(getAllInventory()),
        getRecentInventory: () => dispatch(getRecentInventory()),
        updateBranchNotes: (sku, branchName, notes) => dispatch(updateBranchNotes(sku, branchName, notes))
    })
)(Inventory);