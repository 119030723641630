import {DISPLAY_SNACKBAR_MESSAGE} from "../redux/actions/verificationActions";



export function getServiceCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE, method) {

    return fetch(url,{
            method: method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            } else {
                dispatch({
                    type: FAIL_ACTION
                })
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            } else {
                dispatch({
                    type:FAIL_ACTION
                })
            }
        })
        .then(() => {
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            dispatch({
                type: DISPLAY_SNACKBAR_MESSAGE,
                data: 'Error has occurred'
            });
        });
}

export function postFileServiceCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE, file) {
    console.log('sending post call');
    let formData = new FormData();

    formData.append("file", file,file.name);

    return fetch(url,{
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                //"Content-Type": "multipart/form-data",
            },
            body: formData,
        })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            }
        })
        .then(() => {
            console.log('fetch level this should show after upload completed');
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: FAIL_ACTION
            })
        });
}

export function deleteFileServiceCallOptions(url, SUCCESS_ACTION, FAIL_ACTION, dispatch, LOADING_COMPLETE) {
    console.log('sending post call');

    return fetch(url+"/"+fileName,{
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if(handleStatus(response.status, dispatch)) {
                return response.json();
            }
        })
        .then(data => {
            if(data !== undefined && data !== null) {
                dispatch({
                    type: SUCCESS_ACTION,
                    data: data
                })
            }
        })
        .then(() => {
            dispatch({
                type: LOADING_COMPLETE
            })
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: FAIL_ACTION
            })
        });
}


function handleStatus(statusCode, dispatch) {
    if(statusCode === 401) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Unauthorized Access.'
        });
        return false
    } else if(statusCode === 400) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Error has occurred. Try again or reach out to admin'
        });
        return false
    }
    else if(statusCode === 404) {
        return false
    } else if(statusCode === 500) {
        dispatch({
            type: DISPLAY_SNACKBAR_MESSAGE,
            data: 'Internal Error has Occurred'
        });
        return false
    }
    return true
}
