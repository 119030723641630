import {
    GET_ALL_ORDER_MANIFEST_DATA_SUCCESS
} from "../actions/orderManifestActions";

const initialState = {
    orderManifestData: [],
    loadingOrderManifestData: false,
};

export default function orderManifestReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ORDER_MANIFEST_DATA_SUCCESS:
            return {
                ...state,
                orderManifestData: action.data,
                loadingOrderManifestData: false
            };
        default:
            return state
    }
}