import {
    CREATE_USER_SUCCESS,
    DELETE_SUCCESS, LOGIN_LOCKOUT, UNLOCK_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    VERIFY_2FA_QRCODE
} from "../actions/userActions";
import {
    CANCEL_RSL_ORDER_SUCCESS,
    CANCEL_SHOPIFY_ORDER_SUCCESS,
    CLOSE_SNACKBAR,
    DISPLAY_SNACKBAR_MESSAGE,
    ORDER_REFUND_SUCCESSFUL,
    CANCEL_ALL_ORDER_SUCCESS
} from "../actions/verificationActions";
import {
    CREATE_B2B_CUSTOMER_SUCCESS,
    UPDATE_B2B_CUSTOMER_SUCCESS
} from "../actions/businessAdminActions";
import {
    ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS,
    UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS,
    FAILED_SERIAL_SERVICE, SEND_SN_TO_DB_SUCCESS
} from "../actions/serialNumberActions";
import {PRODUCT_LAUNCH_NOTIFICATION_UPDATE} from "../actions/productLaunchActions";


const initialState = {
    snackBarMessage: '',
    displaySnackBar: false,
};

export default function snackbarReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.success,
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.success,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.success,
            };
        case CANCEL_RSL_ORDER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: 'RSL Order Cancelled'
            };
        case ORDER_REFUND_SUCCESSFUL:
            return {
                ...state,
                snackBarMessage: action.data.success,
                displaySnackBar: true
            };
        case CANCEL_SHOPIFY_ORDER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: 'Shopify Order Cancelled'
            };
        case CANCEL_ALL_ORDER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.success
            };
        case DISPLAY_SNACKBAR_MESSAGE:
            return {
                ...state,
                snackBarMessage: action.data,
                displaySnackBar: true
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                snackBarMessage: '',
                displaySnackBar: false
            };
        case CREATE_B2B_CUSTOMER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: 'Successfully created B2B Customer'
            };
        case UPDATE_B2B_CUSTOMER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: 'Successfully updated B2B Customer'
            };
        case VERIFY_2FA_QRCODE:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            };
        case LOGIN_LOCKOUT:
            return{
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.success
            };
        case UNLOCK_USER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            };
        case ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            };
        case UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS: {
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            }
        }
        case PRODUCT_LAUNCH_NOTIFICATION_UPDATE:{
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            }
        }
        case FAILED_SERIAL_SERVICE:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: 'unable to find serial number'
            }
        case SEND_SN_TO_DB_SUCCESS:
            return {
                ...state,
                displaySnackBar: true,
                snackBarMessage: action.data.message
            }
        default:
            return state
    }
};