import {
    GET_ALL_DISTRIBUTORS_LOADING,
    GET_ALL_DISTRIBUTORS_SUCCESS, UPDATE_DISTRIBUTOR_GROUPBUY_SUCCESS,
} from "../actions/distributorActions";

const initialState = {
    distributors: [],
    loadingDistributors: false,
};

export default function distributorReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_DISTRIBUTORS_LOADING:
            return {
                ...state,
                loadingDistributors: true,
            };
        case GET_ALL_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                distributors: action.data,
                loadingDistributors: false,
            };
        case UPDATE_DISTRIBUTOR_GROUPBUY_SUCCESS:
            return {
                ...state,
                distributors: action.data,
                loadingDistributors: false,
            };
        default:
            return state
    }
}