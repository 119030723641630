import React, {Component} from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import SNEventDialog from "./SNEventDialog";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import {addNewEventToSN, updateEventForSN} from "../../redux/actions/serialNumberActions";

class SerialNumberEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventDialogOpen: false,
            create: true,
            eventDate: new Date(),
            eventInfo: '',
            eventType: 'RMA',
            freshDeskTicketNum: '',
            eventID: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        serialNumberData: PropTypes.object,
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleEventDialog = () => {
        this.setState({
            eventDialogOpen: !this.state.eventDialogOpen
        })
    };

    createEventToggle = () => {
        this.setState({
            create: true,
            eventDialogOpen: !this.state.eventDialogOpen,
            eventDate: new Date(),
            eventInfo: '',
            eventType: 'RMA',
            freshDeskTicketNum: '',
            eventID: ''
        });
    }

    editEventData = (eventData) => {
        this.setState({
            create: false,
            eventDialogOpen: !this.state.eventDialogOpen,
            eventDate: eventData.eventDate,
            eventInfo: eventData.eventInfo,
            eventType: eventData.eventType,
            freshDeskTicketNum: eventData.freshDeskTicketNum,
            eventID: eventData._id
        })
    }

    submitEventForSN = () => {
        const {eventDate, eventInfo, eventType, freshDeskTicketNum, create, eventID} = this.state;
        const {serialNumberData} = this.props;
        if(create){
            this.props.addNewEventToSN(serialNumberData._id, eventInfo, eventDate, eventType, freshDeskTicketNum);
        } else {
            this.props.updateEventForSN(serialNumberData._id, eventInfo, eventDate, eventType, freshDeskTicketNum, eventID);
        }
        this.toggleEventDialog();
    };

    renderEvents = () => {
        return this.props.serialNumberData.events.map(event => {
            return (
                <div>
                    <Card className={'snEventCard'}>
                        <IconButton color="secondary" className={'editIcon'} aria-label="edit order event" onClick={() => this.editEventData(event)}>
                            <CreateIcon />
                        </IconButton>
                        <Typography><b>Date:</b> {event.eventDate}</Typography>
                        <Typography><b>Type:</b> {event.eventType}</Typography>
                        <Typography><b>Ticket:</b> <a target='_blank' className={'trackingLink'} href={`https://pcgamingrace.freshdesk.com/a/tickets/${event.freshDeskTicketNum}`}>{event.freshDeskTicketNum}</a></Typography>
                        <Typography><b>Event: </b> {event.eventInfo}</Typography>
                    </Card>
                </div>
            )
        })
    };

    render() {
        const { eventDialogOpen, create, eventDate, eventType, freshDeskTicketNum, eventInfo } = this.state;
        return (
            <div>
                <div className={'card-title'}>
                    <Typography variant='h6'>Serial Number Events</Typography>
                    <Button className={'searchOrderButton'} variant="contained" onClick={this.createEventToggle} color="primary">create event</Button>
                </div>
                {this.renderEvents()}
                <SNEventDialog
                    toggleEventDialog={this.toggleEventDialog}
                    handleChange={this.handleChange}
                    handleSubmit={this.submitEventForSN}
                    open={eventDialogOpen}
                    create={create}
                    eventDate={eventDate}
                    eventType={eventType}
                    freshDeskTicketNum={freshDeskTicketNum}
                    eventInfo={eventInfo}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        roles: state.userReducer.roles,
        serialNumberData: state.serialNumberReducer.serialNumberData,
        loadingSerialNumberData: state.serialNumberReducer.loadingSerialNumberData,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        addNewEventToSN: (serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum) => dispatch(addNewEventToSN(serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum)),
        updateEventForSN: (serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum, eventID) => dispatch(updateEventForSN(serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum, eventID))
    })
)(SerialNumberEvents);