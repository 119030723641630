import {getServiceCallOptions, postServiceCallOptions, postServiceDataFormCallOptions} from "../../services/api";

export const GET_SERIAL_NUMBER_LOADING = 'GET_ALL_SERIAL_NUMBER_LOADING';
export const GET_SERIAL_NUMBER_SUCCESS = 'GET_ALL_SERIAL_NUMBER_SUCCESS';
export const GET_SERIAL_NUMBER_COMPLETE = 'GET_SERIAL_NUMBER_COMPLETE';
export const ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS = 'ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS';
export const ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS_COMPLETE = 'ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS_COMPLETE';
export const UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS = 'UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS';
export const UPDATE_ORDERID_FOR_SERIAL_NUMBER_COMPLETE = 'UPDATE_ORDERID_FOR_SERIAL_NUMBER_COMPLETE';
export const SEND_SN_TO_DB_SUCCESS = 'SEND_SN_TO_DB_SUCCESS';
export const SEND_SN_TO_DB_COMPLETE = 'SEND_SN_TO_DB_COMPLETE';
export const FAILED_SERVICE = 'GET_SERIAL_NUMBER_SUCCESS';
export const FAILED_SERIAL_SERVICE = 'FAILED_SERIAL_SERVICE';

const host = 'https://api.gpcgr.com/serial';
// const host = 'http://localhost:3005';

export const getSerialNumberData = (serialNumber) => {
    return (dispatch) => {
        dispatch({type: GET_SERIAL_NUMBER_LOADING});
        let url = `${host}/sn/${serialNumber}`;
        return getServiceCallOptions(url, GET_SERIAL_NUMBER_SUCCESS, FAILED_SERIAL_SERVICE, dispatch, GET_SERIAL_NUMBER_COMPLETE, 'GET');
    }
};


export const updateSNWithOrderID = (serialNumberID, orderID) => {
    return (dispatch) => {
        let url = `${host}/sn/update/orderID`;
        let data = new URLSearchParams();
        data.append('_id', serialNumberID);
        data.append('orderID', orderID);

        return postServiceCallOptions(url, UPDATE_ORDERID_FOR_SERIAL_NUMBER_SUCCESS, FAILED_SERVICE, dispatch, UPDATE_ORDERID_FOR_SERIAL_NUMBER_COMPLETE, data, 'POST');
    }
};

export const sendSNToDB = (file) => {
    return (dispatch) => {
        let url = `${host}/sn/upload`;
        const data = new FormData();
        data.append("file", file);
        return postServiceDataFormCallOptions(url, SEND_SN_TO_DB_SUCCESS, FAILED_SERVICE, dispatch, SEND_SN_TO_DB_COMPLETE, data, 'POST');
    }
}



export const addNewEventToSN = (serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum) => {
    return (dispatch) => {
        let url = `${host}/sn/event/add`;
        let data = new URLSearchParams();
        data.append('_id', serialNumberID);
        data.append('eventInfo', eventInfo);
        data.append('eventDate', eventDate);
        data.append('eventType', eventType);
        data.append('freshDeskTicketNum', freshDeskTicketNum);

        return postServiceCallOptions(url, ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS, FAILED_SERVICE, dispatch, ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS_COMPLETE, data, 'POST');
    }
};

export const updateEventForSN = (serialNumberID, eventInfo, eventDate, eventType, freshDeskTicketNum, eventID) => {
    return (dispatch) => {
        let url = `${host}/sn/event/update`;
        let data = new URLSearchParams();
        data.append('_id', serialNumberID);
        data.append('eventID', eventID);
        data.append('eventInfo', eventInfo);
        data.append('eventDate', eventDate);
        data.append('eventType', eventType);
        data.append('freshDeskTicketNum', freshDeskTicketNum);

        return postServiceCallOptions(url, ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS, FAILED_SERVICE, dispatch, ADD_EVENT_FOR_SERIAL_NUMBER_SUCCESS_COMPLETE, data, 'POST');
    }
};