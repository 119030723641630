import {getServiceCallOptions, postServiceCallOptions} from "../../services/api";

export const GET_ALL_DISTRIBUTORS_SUCCESS = 'GET_ALL_DISTRIBUTORS_SUCCESS';
export const GET_ALL_DISTRIBUTORS_LOADING = 'GET_ALL_DISTRIBUTORS_LOADING';
export const GET_ALL_DISTRIBUTORS_COMPLETE = 'GET_ALL_DISTRIBUTORS_COMPLETE';
export const UPDATE_DISTRIBUTOR_INFO = 'UPDATE_DISTRIBUTOR_INFO';
export const DISTRIBUTOR_INFO_UPDATED = 'UPDATE_DISTRIBUTOR_INFO';
export const CREATE_DISTRIBUTOR_ENTRY = 'CREATE_DISTRIBUTOR_ENTRY';
export const DISTRIBUTOR_ENTRY_ADDED = 'DISTRIBUTOR_ENTRY_ADDED';
export const DELETE_DISTRIBUTOR_ENTRY = 'DELETE_DISTRIBUTOR_ENTRY';
export const DISTRIBUTOR_ENTRY_DELETE = 'DISTRIBUTOR_ENTRY_DELETE';
export const FAILED_SERVICE = 'FAILED_SERVICE';
export const UPDATE_DISTRIBUTOR_GROUPBUY_SUCCESS = 'UPDATE_DISTRIBUTOR_GROUPBUY_SUCCESS';
export const DISTRIBUTOR_GROUPBUY_UPDATED = 'DISTRIBUTOR_GROUPBUY_UPDATED';

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getAllDistributors = () => {
    return (dispatch) => {
        dispatch({type: GET_ALL_DISTRIBUTORS_LOADING});
        let url = host + '/distributor/all/';
        console.log('Grabbing all distributor information from db');
        return getServiceCallOptions(url, GET_ALL_DISTRIBUTORS_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_DISTRIBUTORS_COMPLETE, 'GET');
    }
};

export const updateDistributorInformation = (country, name, website, _id) => {
    return (dispatch) => {
        let url = host + '/distributor/update';
        let data = new URLSearchParams();
        data.append('country', country);
        data.append('name', name);
        data.append('website', website);
        data.append('_id', _id);
        return postServiceCallOptions(url, UPDATE_DISTRIBUTOR_INFO, FAILED_SERVICE, dispatch, DISTRIBUTOR_INFO_UPDATED, data, 'POST');
    }
};

export const updateDistributorGroupBuyEntry = (distributorID, groupBuyID, sku, website) => {
    return (dispatch) => {
        dispatch({type: GET_ALL_DISTRIBUTORS_LOADING});
        let url = host + '/distributor/groupbuy/update';
        let data = new URLSearchParams();
        data.append('distributorID', distributorID);
        data.append('groupBuyID', groupBuyID);
        data.append('url', website);
        data.append('sku', sku);
        return postServiceCallOptions(url, UPDATE_DISTRIBUTOR_GROUPBUY_SUCCESS, FAILED_SERVICE, dispatch, DISTRIBUTOR_GROUPBUY_UPDATED, data, 'POST');
    }
};


export const createDistributorEntry = (country, name, website) => {
    return (dispatch) => {
        let url = host + '/distributor/create';
        let data = new URLSearchParams();
        data.append('country', country);
        data.append('name', name);
        data.append('website', website);
        return postServiceCallOptions(url, CREATE_DISTRIBUTOR_ENTRY, FAILED_SERVICE, dispatch, DISTRIBUTOR_ENTRY_ADDED, data, 'POST');
    }
};

export const deleteDistributorEntry = (_id) => {
    return (dispatch) => {
        let url = host + '/distributor/delete';
        let data = new URLSearchParams();
        data.append('_id', _id);
        return postServiceCallOptions(url, DELETE_DISTRIBUTOR_ENTRY, FAILED_SERVICE, dispatch, DISTRIBUTOR_ENTRY_DELETE, data, 'POST');
    }
};