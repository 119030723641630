import React, { Component } from 'react';
import { connect } from 'react-redux';
import {loginUser, getUserIP, verify2FALogin, displayLoginMessage, lockoutUser} from "../../redux/actions/userActions";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Layout from "./Layout";
import Typography from "@material-ui/core/Typography";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            twoFactorToken: '',
            loginAttempt: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getUserIP();
    }

    static propTypes = {
        authorized: PropTypes.bool,
        loginUser: PropTypes.func,
        ip: PropTypes.string,
        verify2FALogin: PropTypes.func,
    };

    static defaultProps = {
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loginAttempt: ++this.state.loginAttempt
        });
        if(this.state.loginAttempt <= 3) {
            this.props.loginUser(this.state.username, this.state.password, this.props.ip);
        } else {
            this.props.lockoutUser(this.state.username);
            this.props.displayLoginMessage('You have been locked out, please contact a system administrator');
        }
        console.log(this.state.loginAttempt);
    }

    verify2FALogin = () => {
        this.props.verify2FALogin(this.state.twoFactorToken);
    };

    render2FA = () => {
        const {userProfile} = this.props;
        const debugMode = true;
        if(userProfile !== undefined && userProfile.factorAuthEnabled) {
            return (
                <div>
                    <TextField
                        margin="dense"
                        id="twoFactorToken"
                        name="twoFactorToken"
                        label='2FA Key Code'
                        type='number'
                        value={this.state.twoFactorToken}
                        onChange={this.handleChange}
                    />
                    <Button variant="contained" color="primary" className={'verifyButton'}  onClick={this.verify2FALogin}>Verify & Login</Button>
                </div>
            )
        } else {

        }
    };

    render() {
        if (this.props.authorized) {
            this.props.history.push('/home');
        }
        return (
            <div>
                <div className={'title'}>
                    <Typography variant='h6' >Glorious Nexus</Typography>
                </div>
                <Layout>
                    <form onSubmit={this.handleSubmit}>
                        <div className={'loginField'}><TextField id="outlined-basic" label="Username" name='username' variant="outlined" value={this.state.username} onChange={this.handleChange} /></div>
                        <div className={'loginField'}><TextField id="outlined-basic" type='password' label="Password" name='password' variant="outlined" value={this.state.password} onChange={this.handleChange} /></div>
                        <Button variant="contained" color="primary" type='submit'>Login</Button>
                    </form>
                    {this.render2FA()}
                </Layout>
            </div>
        );
    }
}

export default connect(
    state => ({
        authorized: state.userReducer.authorized,
        ip: state.userReducer.ip,
        userProfile: state.userReducer.userProfile
    }),
    dispatch => ({
        loginUser: (email, password, ip) => dispatch(loginUser(email, password, ip)),
        getUserIP: () => dispatch(getUserIP()),
        verify2FALogin: (token) => dispatch(verify2FALogin(token)),
        displayLoginMessage: (message) => dispatch(displayLoginMessage(message)),
        lockoutUser: (email) => dispatch(lockoutUser(email))
    })
)(Login);