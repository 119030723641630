import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Card, TableHead} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ReceiptIcon from '@material-ui/icons/Receipt';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {updateOrderStatus} from "../../redux/actions/businessAdminActions";
import FormHelperText from "@material-ui/core/FormHelperText";

class B2BOrderDetailsPanel extends Component {

    static propTypes = {
        orderData: PropTypes.object,
        updateOrderStatus: PropTypes.func,
        viewAccountOrders: PropTypes.func,
    };

    static defaultProps = {
        orderData: {}
    };

    renderLineItems = (line_items) => {
        if(line_items !== undefined && line_items !== null) {
            return line_items.map((line_item, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell>{line_item.sku}</TableCell>
                        <TableCell>{line_item.qty}</TableCell>
                        <TableCell>{line_item.unitPrice}</TableCell>
                        <TableCell>{line_item.subtotal}</TableCell>
                    </TableRow>
                )
            })
        }
    };

    renderAddress = () => {
        const {orderData} = this.props;
        return (
            <div>
                {orderData.address + ' ' + orderData.address2} <br />
                {orderData.city + ', ' + orderData.stateProvince} <br/>
                {orderData.country} <br />
                {orderData.postalCode}
            </div>
        )
    };

    handleStatusUpdate = (event) => {
        const status = event.target.value;
        this.props.updateOrderStatus(status, this.props.orderData._id);
    };


    render() {
        const {orderData} = this.props;

        return (
            <div className={'userContainer'}>
                <Card className={'userCard'}>
                    <Typography className={'textSpacing'} variant='h3'>Order#
                        <a target='_blank' href={'https://go.cin7.com/Cloud/ShoppingCartAdmin/Orders/OrderDetails.aspx?idOrder=' + orderData.orderID + '&idWebSite=17063&idCustomerAppsLink=754242'}>
                            {orderData.orderReference}
                        </a>
                    </Typography>
                    <Typography className={'textSpacing'} variant='h6'>Email: {orderData.email}</Typography>
                    <Typography className={'textSpacing'} variant='h6'>Order Date: {orderData.date}</Typography>
                    <FormControl className={'formControlSelect'}>
                        {/*<InputLabel>Status</InputLabel>*/}
                        <FormHelperText>Status</FormHelperText>
                        <Select
                            id="role-select"
                            name="stage"
                            onChange={this.handleStatusUpdate}
                            value={orderData.status}
                        >
                            <MenuItem value={'New'}>New</MenuItem>
                            <MenuItem value={'Processing'}>Processing</MenuItem>
                            <MenuItem value={'Dispatched'}>Dispatched</MenuItem>
                            <MenuItem value={'Fully Paid'}>Fully Paid</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography><b>Address:</b> {this.renderAddress()}</Typography>
                    <br />
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Unit Wholesale Price</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.renderLineItems(orderData.lineItems)}
                        </TableBody>
                    </Table>
                    <IconButton color="secondary"  aria-label="Order Details Page" onClick={() => this.props.viewAccountOrders(orderData.email)}>
                        <ReceiptIcon />
                    </IconButton>
                </Card>
            </div>
        );
    }
}

export default withRouter(connect(
    state => ({
    }),
    dispatch => ({
        updateOrderStatus: (status, id) => dispatch(updateOrderStatus(status, id))
    })
)(B2BOrderDetailsPanel));