import {
    GET_ALL_PRODUCT_LAUNCHES_SUCCESS,
    GET_ALL_PRODUCT_LAUNCHES_LOADING,
    CREATE_PRODUCT_LAUNCH_ENTRY,
    UPDATE_PRODUCT_LAUNCH_INFO, PRODUCT_LAUNCH_NOTIFICATION_UPDATE, DELETE_PRODUCT_LAUNCH_ENTRY
} from "../actions/productLaunchActions";

const initialState = {
    productLaunchData: [],
    loadingProductLaunches: false,
};

export default function productLaunchReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PRODUCT_LAUNCHES_LOADING:
            return {
                ...state,
                loadingProductLaunches: true,
            };
        case GET_ALL_PRODUCT_LAUNCHES_SUCCESS:
            return {
                ...state,
                productLaunchData: action.data,
                loadingProductLaunches: false,
            };
        case CREATE_PRODUCT_LAUNCH_ENTRY:
            return {
                ...state,
                productLaunchData: action.data,
                loadingProductLaunches: false
            };
        case UPDATE_PRODUCT_LAUNCH_INFO:
            return {
                ...state,
                productLaunchData: action.data,
                loadingProductLaunches: false
            };
        case PRODUCT_LAUNCH_NOTIFICATION_UPDATE:
            return {
                ...state,
                productLaunchData: action.data.productLaunches,
                loadingProductLaunches: false
            };
        case DELETE_PRODUCT_LAUNCH_ENTRY:
            return {
                ...state,
                productLaunchData: action.data,
                loadingProductLaunches: false
            };
        default:
            return state
    }
}