
import {
    GET_SHOPIFY_ORDER_SUCCESS,
    GET_CIN_ORDER_SUCCESS,
    GET_RSL_ORDER_SUCCESS,
    LOADING_COMPLETE_SHOPIFY,
    LOADING_SHOPIFY,
    LOADING_COMPLETE_RSL,
    LOADING_RSL,
    LOADING_COMPLETE_CIN7,
    LOADING_CIN7,
    GET_TRACKING_DATA_SUCCESS,
    FAILED_SERVICE_CIN,
    FAILED_SERVICE_RSL,
    FAILED_SERVICE_SHOPIFY,
    FAILED_SERVICE_TRACKING,
    ORDER_NUMBER_UPDATE,
    ORDER_REFUND_LOADING,
    ORDER_REFUND_SUCCESSFUL,
    CANCEL_SHOPIFY_ORDER_SUCCESS,
    GRAB_CUSTOMER_SEARCH_SUCCESS,
    GRAB_CUSTOMER_SEARCH_LOADING,
    GRAB_CUSTOMER_ORDER_SUCCESS,
    GRAB_CUSTOMER_ORDER_LOADING,
    CANCEL_ALL_ORDER_SUCCESS
} from "../actions/verificationActions";
import {CREATE_USER_SUCCESS, DELETE_SUCCESS} from "../actions/userActions";


const initialState = {
    orderNumberTextField: '',
    shopifyOrder: {
        line_items: [],
        address: {},
        shipping_lines: []
    },
    cinOrder: {},
    rslOrder: [{
        orderID: '',
        status: ''
    }],
    loadingShopify: false,
    loadingRSL: false,
    loadingCIN: false,
    trackingData: [],
    orderRefundLoading: false,
    updateOrderDetails: false,
    customerSearchResults: [],
    customersOrders: [],
    loadingCustomerSearch: false
};

export default function verificationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SHOPIFY_ORDER_SUCCESS:
            return {
                ...state,
                shopifyOrder: action.data,
                updateOrderDetails: false
            };
        case GET_CIN_ORDER_SUCCESS:
            return {
                ...state,
                cinOrder: action.data,
                updateOrderDetails: false
            };
        case GET_RSL_ORDER_SUCCESS:
            return {
                ...state,
                rslOrder: action.data,
                updateOrderDetails: false
            };
        case LOADING_COMPLETE_SHOPIFY:
            return {
                ...state,
                loadingShopify: false
            };
        case LOADING_SHOPIFY:
            return {
              ...state,
                loadingShopify: true
            };
        case LOADING_COMPLETE_RSL:
            return {
                ...state,
                loadingRSL: false
            };
        case LOADING_RSL:
            return {
                ...state,
                loadingRSL: true
            };
        case LOADING_COMPLETE_CIN7:
            return {
                ...state,
                loadingCIN: false
            };
        case LOADING_CIN7:
            return {
                ...state,
                loadingCIN: true
            };
        case GET_TRACKING_DATA_SUCCESS:
            return {
                ...state,
                trackingData: action.data
            };
        case FAILED_SERVICE_SHOPIFY:
            return {
                ...state,
                loadingShopify: false,
                shopifyOrder: {
                    line_items: [],
                    address: {},
                    shipping_lines: [],
                    orderID: 'Not Found'
                }
            };
        case FAILED_SERVICE_RSL:
            return {
                ...state,
                loadingRSL: false,
                rslOrder: {
                    orderID: 'Not Found',
                    status: ''
                }
            };
        case FAILED_SERVICE_CIN:
            return {
                ...state,
                cinOrder: {orderID: 'Not Found'}
            };
        case FAILED_SERVICE_TRACKING:
            return {
                ...state,
                loadingShopify:false,
                trackingData: {}
            };
        case ORDER_NUMBER_UPDATE:
            return {
                ...state,
                orderNumberTextField: action.data
            };
        case ORDER_REFUND_LOADING:
            return {
                ...state,
                orderRefundLoading: true
            };
        case ORDER_REFUND_SUCCESSFUL:
            return {
                ...state,
                orderRefundLoading:false,
                updateOrderDetails:true
            };
        case CANCEL_SHOPIFY_ORDER_SUCCESS:
            return {
                ...state,
                updateOrderDetails: true,
            };
        case GRAB_CUSTOMER_SEARCH_LOADING:
            return {
                ...state,
                loadingCustomerSearch: true
            };
        case GRAB_CUSTOMER_SEARCH_SUCCESS:
            return {
                ...state,
                customerSearchResults: action.data,
                loadingCustomerSearch: false
            };
        case GRAB_CUSTOMER_ORDER_SUCCESS:
            return {
                ...state,
                customersOrders: action.data,
                loadingCustomerSearch: false
            };
        case GRAB_CUSTOMER_ORDER_LOADING:
            return {
                ...state,
                loadingCustomerSearch: true
            };
        default:
            return state
    }
}
