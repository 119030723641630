import {getServiceCallOptions} from "../../services/api";

export const GET_ALL_ORDER_MANIFEST_DATA_LOADING = 'GET_ALL_ORDER_MANIFEST_DATA_LOADING';
export const GET_ALL_ORDER_MANIFEST_DATA_SUCCESS = 'GET_ALL_ORDER_MANIFEST_DATA_SUCCESS';
export const GET_ALL_ORDER_MANIFEST_DATA_COMPLETE = 'GET_ALL_ORDER_MANIFEST_DATA_COMPLETE';
export const FAILED_SERVICE= 'FAILED_SERVICE';

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getAllOrderManifestData = () => {
    return (dispatch) => {
        dispatch({type: GET_ALL_ORDER_MANIFEST_DATA_LOADING});
        let url = host + '/inventory/po/manifest';
        return getServiceCallOptions(url, GET_ALL_ORDER_MANIFEST_DATA_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_ORDER_MANIFEST_DATA_COMPLETE, 'GET');
    }
};