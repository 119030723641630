import {getServiceCallOptions, postServiceCallOptions} from "../../services/api";

export const GET_ALL_PRODUCT_LAUNCHES_SUCCESS = 'GET_ALL_PRODUCT_LAUNCHES_SUCCESS';
export const GET_ALL_PRODUCT_LAUNCHES_LOADING = 'GET_ALL_PRODUCT_LAUNCHES_LOADING';
export const GET_ALL_PRODUCT_LAUNCHES_COMPLETE = 'GET_ALL_PRODUCT_LAUNCHES_COMPLETE';
export const UPDATE_PRODUCT_LAUNCH_INFO = 'UPDATE_PRODUCT_LAUNCH_INFO';
export const UPDATE_PRODUCT_LAUNCH_INFO_COMPLETED = 'UPDATE_PRODUCT_LAUNCH_INFO_COMPLETED';
export const CREATE_PRODUCT_LAUNCH_ENTRY = 'CREATE_PRODUCT_LAUNCH_ENTRY';
export const PRODUCT_LAUNCH_ENTRY_ADDED = 'PRODUCT_LAUNCH_ENTRY_ADDED';
export const DELETE_PRODUCT_LAUNCH_ENTRY = 'DELETE_PRODUCT_LAUNCH_ENTRY';
export const PRODUCT_LAUNCH_ENTRY_DELETE = 'PRODUCT_LAUNCH_ENTRY_DELETE';
export const PRODUCT_LAUNCH_NOTIFICATION_UPDATE = 'PRODUCT_LAUNCH_NOTIFICATION_UPDATE';
export const FAILED_SERVICE = 'FAILED_SERVICE';

const host = 'https://api.gpcgr.com';
// const host = 'http://localhost:3000';

export const getAllProductLaunchData = () => {
    return (dispatch) => {
        dispatch({type: GET_ALL_PRODUCT_LAUNCHES_LOADING});
        let url = host + '/productlaunch/all';
        console.log('Grabbing all product launch information from db');
        return getServiceCallOptions(url, GET_ALL_PRODUCT_LAUNCHES_SUCCESS, FAILED_SERVICE, dispatch, GET_ALL_PRODUCT_LAUNCHES_COMPLETE, 'GET');
    }
};

export const updateProductLaunchInformation = (updatedProductLaunchData) => {
    return (dispatch) => {
        let url = host + '/productlaunch/update';
        let data = new URLSearchParams();
        data.append('_id', updatedProductLaunchData._id);
        data.append('sku', updatedProductLaunchData.sku);
        data.append('etd', (updatedProductLaunchData.etd !== undefined && updatedProductLaunchData.etd !== null)  ? updatedProductLaunchData.etd : '');
        data.append('eta', (updatedProductLaunchData.eta !== undefined && updatedProductLaunchData.eta !== null) ? updatedProductLaunchData.eta : '');
        data.append('b2b', updatedProductLaunchData.b2b !== undefined ? updatedProductLaunchData.b2b : false);
        data.append('releaseType', (updatedProductLaunchData.releaseType !== undefined && updatedProductLaunchData.releaseType !== null) ? updatedProductLaunchData.releaseType : '');
        data.append('privacyRating', (updatedProductLaunchData.privacyRating !== undefined && updatedProductLaunchData.privacyRating !== null) ? updatedProductLaunchData.privacyRating : '');
        data.append('activityOpenDate', (updatedProductLaunchData.activityOpenDate !== undefined && updatedProductLaunchData.activityOpenDate !== null) ? updatedProductLaunchData.activityOpenDate : '');
        data.append('activityCloseDate', (updatedProductLaunchData.activityCloseDate !== undefined && updatedProductLaunchData.activityCloseDate !== null) ? updatedProductLaunchData.activityCloseDate : '');
        data.append('globalReleaseDate', (updatedProductLaunchData.globalReleaseDate !== undefined && updatedProductLaunchData.globalReleaseDate !== null) ? updatedProductLaunchData.globalReleaseDate : '');
        return postServiceCallOptions(url, UPDATE_PRODUCT_LAUNCH_INFO, FAILED_SERVICE, dispatch, UPDATE_PRODUCT_LAUNCH_INFO_COMPLETED, data, 'POST');
    }
};


export const createProductLaunchEntry = (newProductLaunchEntry) => {
    return (dispatch) => {
        let url = host + '/productlaunch/create';
        let data = new URLSearchParams();
        data.append('sku', newProductLaunchEntry.sku);
        data.append('etd', newProductLaunchEntry.etd !== undefined ? updatedProductLaunchData.etd : '');
        data.append('eta', newProductLaunchEntry.eta !== undefined ? updatedProductLaunchData.eta : '');
        data.append('b2b', newProductLaunchEntry.b2b !== undefined ? updatedProductLaunchData.b2b : false);
        data.append('releaseType', newProductLaunchEntry.releaseType !== undefined ? updatedProductLaunchData.releaseType : '');
        data.append('privacyRating', newProductLaunchEntry.privacyRating !== undefined ? updatedProductLaunchData.privacyRating : '');
        data.append('activityOpenDate', newProductLaunchEntry.activityOpenDate !== undefined ? updatedProductLaunchData.activityOpenDate : '');
        data.append('activityCloseDate', newProductLaunchEntry.activityCloseDate !== undefined ? updatedProductLaunchData.activityCloseDate : '');
        data.append('globalReleaseDate', newProductLaunchEntry.globalReleaseDate !== undefined ? updatedProductLaunchData.globalReleaseDate : '');
        return postServiceCallOptions(url, CREATE_PRODUCT_LAUNCH_ENTRY, FAILED_SERVICE, dispatch, PRODUCT_LAUNCH_ENTRY_ADDED, data, 'POST');
    }
};

export const updateNotificationList = (_id) => {
    return (dispatch) => {
        let url = host + '/productlaunch/notification';
        let data = new URLSearchParams();
        data.append('_id', _id);
        return postServiceCallOptions(url, PRODUCT_LAUNCH_NOTIFICATION_UPDATE, FAILED_SERVICE, dispatch, PRODUCT_LAUNCH_ENTRY_ADDED, data, 'POST');
    }
};

export const deleteProductLaunchEntry = (_id) => {
    return (dispatch) => {
        let url = host + '/productlaunch/delete';
        let data = new URLSearchParams();
        data.append('_id', _id);
        return postServiceCallOptions(url, DELETE_PRODUCT_LAUNCH_ENTRY, FAILED_SERVICE, dispatch, PRODUCT_LAUNCH_ENTRY_DELETE, data, 'POST');
    }
};